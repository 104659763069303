import React from 'react';
import { ErrorMessage } from 'formik';
import Select, { components } from "react-select";
import CarIcon from '../../assets/logos/CarIcon.png';
import ShipmentBox from '../../assets/logos/ShipmentBox.png';
import { AppEnum } from '../../common/AppEnum';

const { Option } = components;

const OptionsWithIcon = props => (
    <Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ marginBlock: 'auto' }}>{props.label}</span>
            {
                props.value === AppEnum.ServiceTypeId.Ride &&
                < img src={CarIcon}
                    alt={props.value}
                    style={{ width: '27px', height: '27 px' }}
                />
            }
            {
                props.value === AppEnum.ServiceTypeId.Delivery &&
                < img src={ShipmentBox}
                    alt={props.value}
                />
            }
            {
                props.value === AppEnum.ServiceTypeId.FoodDelivery &&
                <i className="fas fa-utensils" style={{ color: 'gray', fontSize: '18px' }}></i>
            }

        </div>
    </Option>
);
const FormikMultiSelect = (
    {
        field: { ...fields },
        form: { touched, errors, setFieldValue },
        ...props
    }) => (
    <React.Fragment>
        <Select {...props} {...fields} isMulti
            className={'custom-multi-select' + (touched[fields.name] && errors[fields.name] ? ' is-invalid' : '')}
            style={{ backgroundImage: 'none', }}
            isSearchable={false}
            components={{ Option: OptionsWithIcon }}
            value={props.value}
            onChange={(event) => {
                setFieldValue(fields.name, event);
                props.onChange(event, fields.name);
            }}>
            {/* {props.options.map((item, index) => <option key={index} value={item?.value} >{item.label}</option>)} */}
        </Select>
        <ErrorMessage name={[fields.name]} component="div" className="invalid-feedback" />
    </React.Fragment>
);

export default FormikMultiSelect;