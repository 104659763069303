import React, { useEffect, useRef, useState } from "react";
import states from './VerifyOTPState';
import { toast } from "react-toastify";
import * as  loginService from '../../service/Login.Service';
import VerifyOtp from "./VerifyOtp";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage, removeToken, setLocalStorage } from "../../common/CommonMethods";
import { AppEnum } from "../../common/AppEnum";
import * as signupService from '../../service/document/SignUpService';
import * as commonService from '../../service/CommonService';
import { PrivateLayout } from "../../common/PrivateLayout";

const VerifyOtpContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState(states);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        if (!location.state)
            navigate('/login');

        if (location.state?.otpDetail)
            state.mobileNumber = location.state?.otpDetail?.mobileNo;

        if (isComponentMounted.current)
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });

        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // Sets the otp in state
    const onChangeOtpHandler = (otp) => {
        setState((prevState) => { return { ...prevState, mobileOtp: otp } });
    }

    // It resends the otp to the mobile number and sets the new otpUId
    const onResendDriverOTP = () => {
        commonService.sendOTP(location.state.otpDetail).then(res => {
            if (res) {
                if (location.state.personalInfo)
                    location.state.personalInfo.otpuId = res.uId;
                if (location.state.otpDetail)
                    location.state.otpDetail.otpuId = res.uId;

                toast.success(state.labelList.filter(i => i.key === 'Validation.OtpSentSuccessfully')[0]?.value);
            }
        })
    }

    // Sets the otp state to empty string
    const onReSendOTP = () => {
        setState((prevState) => { return { ...prevState, mobileOtp: '', } });
        onResendDriverOTP();
    }

    // It validates the loginPartnerType, if it is undefined then it completes the first registration step else
    // It proceeds for login 
    const onVerifyOTP = () => {
        if (location.state.loginPartnerType === undefined) {
            var token = getLocalStorage('token');
            if (token)
                removeToken();

            let driverDetail = location.state.personalInfo;
            let languageCode = localStorage.getItem('languageCode');
            driverDetail.languageCode = languageCode;
            driverDetail.mobileOTP = state.mobileOtp;
            driverDetail.emailOTP = '';
            delete driverDetail.confirmPassword;
            delete driverDetail.isActive;
            delete driverDetail.stateUId;

            signupService.registerDriver(location.state.personalInfo?.otpuId, driverDetail).then(
                res => {
                    if (res?.accessToken) {
                        signupService.updateIsNewRegister(res.uId).then((response) => { })
                        setLocalStorage('refresh_token', res.refreshToken);
                        setLocalStorage('token', res.accessToken);
                        navigate('/driver/service-type');
                    }
                })
        }
        if (location.state.loginPartnerType === AppEnum.LoginCD.driver)
            Login();
    }

    // It creates a form data object then calls the login API, If token is received in response
    // then it redirects to the respective page
    const Login = () => {
        let formData = new FormData();
        let languageCode = getLocalStorage('languageCode');
        formData.append('client_secret', AppEnum.FormDataMobileCD.client_secret);
        formData.append('grant_type', AppEnum.FormDataMobileCD.grant_type);
        formData.append('client_id', AppEnum.FormDataMobileCD.client_id);
        formData.append('otp_uid', location.state.otpDetail.otpuId);
        formData.append('sms_otp', state.mobileOtp);
        formData.append('languagecode', languageCode);

        loginService.login(formData)
            .then(res => {
                if (res?.access_token) {
                    setLocalStorage('refresh_token', res.refresh_token);
                    setLocalStorage('token', res.access_token);
                    // navigate('/driver/service-type');
                    PrivateLayout();
                }
            })
    }

    return (
        <React.Fragment>
            <VerifyOtp
                onVerifyOTP={onVerifyOTP}
                onChangeOtpHandler={onChangeOtpHandler}
                onReSendOTP={onReSendOTP}
                state={state}
            />
        </React.Fragment>
    )
}

export default VerifyOtpContainer;