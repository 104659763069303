import React from "react";
import { Formik, Field, Form } from 'formik';
import UploadIcon from '../../../assets/logos/UploadIcon.png'
import * as commonMethods from '../../../common/CommonMethods';
import Modal from 'react-bootstrap/Modal';
import FormikInput from "../../../shared-component/formik/FormikInput";
import { AppEnum } from "../../../common/AppEnum";
import { UploadDocumentJsx } from "../../driver-documents/DriverDocuments";

const UploadFile = (props) => {

    const { labelList, formValidationSchemaSsn, curpDetail, chosenDocumentConfig, isChosenDocumentUploaded,
    } = props.state;

    return (
        <div>
            <Formik
                initialValues={chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber ?
                    curpDetail : ''}
                enableReinitialize='true'
                validationSchema={chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber ?
                    formValidationSchemaSsn : ''}
                onSubmit={fields =>  props.saveChosenDocument(fields) }
            >
                {(formik) => (
                    <React.Fragment >
                        <div className={'dashboard-container'}>
                            <Form noValidate>
                                <div>
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber &&
                                        <div className='curp-div'>
                                            <h2 className='tos-title'>{chosenDocumentConfig.title}</h2>
                                            <label>{labelList.filter(i => i.key === 'VehicleInfo.CurpDesc')[0]?.value}</label>
                                            <a href='https://www.gob.mx/curp/' target='_blank'>{labelList.filter(i => i.key === 'VehicleInfo.CheckHere')[0]?.value}</a>
                                            <div className="form-group">
                                                <div className="curp-input-div">
                                                    <Field name="curp" id="curp" type="text" maxLength='18' disabled={(chosenDocumentConfig.identityStatusCD === AppEnum.IdentityStatusCD.Rejected || !chosenDocumentConfig.titleValue) ? false : true}
                                                        placeholder={labelList.filter(i => i.key === 'PersonalDocument.EnterSSNNumber')[0]?.value} component={FormikInput} />
                                                </div>
                                            </div>
                                            {
                                                (chosenDocumentConfig.identityStatusCD === AppEnum.IdentityStatusCD.Rejected && chosenDocumentConfig.remark) &&
                                                <label className='rejected-remark-doc'>{labelList.filter(i => i.key === 'UserDetail.Remark')[0]?.value} : {chosenDocumentConfig.remark}</label>
                                            }
                                            {
                                                (chosenDocumentConfig.identityStatusCD === AppEnum.IdentityStatusCD.Rejected || !chosenDocumentConfig.titleValue) &&
                                                <div className={(formik.isValid && formik.dirty) ? 'submit-btn-div submit-btn-doc' : 'submit-btn-div disable-btn submit-btn-doc'} >
                                                    <button type='submit' >{labelList.filter(i => i.key === 'VehicleInfo.Confirm')[0]?.value}</button>
                                                </div>
                                            }
                                            <div className='return-btn'>
                                                <button type='button' onClick={props.goToBackPage}>{labelList.filter(i => i.key === 'VehicleInfo.Return')[0]?.value}</button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.IdentityDocument &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.IdentificationDesc')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.DocumentMustBeOriginal')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ImageLegible')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ImageWeigh')[0]?.value,
                                            false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.ProfilePicture &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProfilePictureDesc')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProfilePicture1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProfilePicture2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProfilePicture3')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProfilePicture4')[0]?.value,
                                            props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.TaxDocument &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProofOfTaxDesc')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax3')[0]?.value,
                                            false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.Toxilogical &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.Analysis')[0]?.value,
                                            labelList.filter(i => i.key === 'Phase2.ToxilogicalLabelYouMust')[0]?.value,
                                            labelList.filter(i => i.key === 'Phase2.ToxilogicalLabelTestMust')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint3')[0]?.value,
                                            false, false, false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.Icet &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'Phase2.UploadIcet')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint3')[0]?.value,
                                            false, false, false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.MobilityInstitute &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'Phase2.MobilitySubLabel')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint3')[0]?.value,
                                            false, false, false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.ProofOfAddress &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.AddressProofDescription')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.AddressProof1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.AddressProof2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.AddressProof3')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.AddressProof4')[0]?.value,
                                            props, labelList
                                        )
                                    }
                                    {
                                        (chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.DL || chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.DlLite) &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.DriverLicenseDesc')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.CirculationCard1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.DrivingLicensePoint2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.AddressProof4')[0]?.value,
                                            false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.BirthCertificate &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.BirthCertificateDescription')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint3')[0]?.value,
                                            false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.VehicleImage &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.CarVehicleImageDescription')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.VehicleImagePoint1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.VehicleImagePoint2')[0]?.value,
                                            false, false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.VehicleLicense &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.LicensePlateDesc')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.LicensePlate1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.LicensePlate2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.LicensePlate3')[0]?.value,
                                            false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.VehicleInsurance &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.VehicleInsuranceDesc')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.VehicleInsurancePoint1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.VehicleInsurancePoint2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.VehicleInsurancePoint3')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.VehicleInsurance4')[0]?.value,
                                            props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.CirculationCard &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.CirculationCardDesc')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.CirculationCard1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.CirculationCard2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.CirculationCard3')[0]?.value,
                                            false, props, labelList
                                        )
                                    }
                                    {
                                        chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.TaxInformation &&
                                        UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProofOfTaxDesc')[0]?.value,
                                            false, false,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax1')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax2')[0]?.value,
                                            labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax3')[0]?.value,
                                            false, props, labelList
                                        )
                                    }
                                </div>
                            </Form>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default UploadFile; 