import React, { useEffect, useState } from 'react';
import menuState from './MenuState';
import { useLocation } from 'react-router-dom';
import DriverMenu from './DriverMenu';
import { useNavigate } from "react-router-dom";

const MenuContainer = () => {

    // It is used to get current pathname
    let location = useLocation();
    const navigate = useNavigate()
    const [state, setState] = useState(menuState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: masterApiData } })
        window.scrollTo(0, 0);
        if (location.pathname === "/active-driver/menu/profile")
            setState((prevState) => { return { ...prevState, isProfileSectionSelected: true } })
        if (location.pathname === "/active-driver/menu/document")
            setState((prevState) => { return { ...prevState, isDocumentSectionSelected: true } })
        if (location.pathname === "/active-driver/menu/my-rides")
            setState((prevState) => { return { ...prevState, isMyRidesSectionSelected: true } })
        if (location.pathname === "/active-driver/menu/my-shipments")
            setState((prevState) => { return { ...prevState, isShipmentSectionSelected: true } })
    }, [])

    // selects profile section
    const onClickProfileHandler = () => {
        setState((prevState) => {
            return {
                ...prevState, isProfileSectionSelected: true,
                isDocumentSectionSelected: false,
                isMyRidesSectionSelected: false,
                isShipmentSectionSelected: false,
            }
        })
        navigate('/active-driver/menu/profile');
    }

    // selects document section
    const onClickDocumentHandler = () => {
        setState((prevState) => {
            return {
                ...prevState, isDocumentSectionSelected: true,
                isMyRidesSectionSelected: false,
                isShipmentSectionSelected: false,
                isProfileSectionSelected: false
            }
        })
        navigate('/active-driver/menu/document');
    }

    // selects my rides section
    const onClickMyRidesHandler = () => {
        setState((prevState) => {
            return {
                ...prevState, isMyRidesSectionSelected: true,
                isShipmentSectionSelected: false,
                isDocumentSectionSelected: false,
                isProfileSectionSelected: false
            }
        })
        navigate('/active-driver/menu/my-rides');
    }

    // selects shipment section
    const onClickShipmentHandler = () => {
        setState((prevState) => {
            return {
                ...prevState, isShipmentSectionSelected: true,
                isMyRidesSectionSelected: false,
                isDocumentSectionSelected: false,
                isProfileSectionSelected: false
            }
        })
        navigate('/active-driver/menu/my-shipments');
    }

    return (
        <React.Fragment>
            <DriverMenu
                state={state}
                onClickProfileHandler={onClickProfileHandler}
                onClickDocumentHandler={onClickDocumentHandler}
                onClickMyRidesHandler={onClickMyRidesHandler}
                onClickShipmentHandler={onClickShipmentHandler}
            />
        </React.Fragment>
    )
}

export default MenuContainer;