// States
const menuState = (function states(props) {
    return {
        labelList: [],
        isProfileSectionSelected: false,
        isDocumentSectionSelected: false,
        isMyRidesSectionSelected: false,
        isShipmentSectionSelected: false
    }
})()

export default menuState;