import React from 'react'

export const SelectField = props => {
    let isSelected = false;
    return (
        <div>
            <select
                className={props.className}
                onBlur={props.onBlur}
                onChange={(event) => {
                    props.handleChange(event);
                }}
            >
                {props.option.map((item, index) => {
                    isSelected = item.value === props.value ? true : false
                    return (
                        <option key={index} value={item.value} selected={isSelected}>{item.label}</option>
                    )
                })}
            </select>
        </div>
    )
}

export default SelectField;