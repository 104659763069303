import React, { useEffect, useState } from 'react';
import documentState from './DocumentState';
import * as commonMethods from '../../../common/CommonMethods';
import * as documentService from '../../../service/document/Document.Service';
import * as activeAccountService from '../../../service/ActiveAccountService';
import { toast } from 'react-toastify';
import DocumentComponent from './DocumentComponent';
import { AppEnum } from '../../../common/AppEnum';

const DocumentContainer = () => {

    const [state, setState] = useState(documentState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: masterApiData } })
        getTaxInfoDetails();
        getAllDocuments();
        return () => {
            setState({});
        };
    }, [])

    // It fetches all the tax info datails in order to render it on UI
    const getTaxInfoDetails = () => {
        documentService.getTaxInfoDetails().then((result) => {
            if (result) {
                let taxRegimeList = result.taxConfigurations.filter(i => i.code === AppEnum.TaxInfoCode.TaxRegime);
                taxRegimeList.map((item) => {
                    item.value = item.uId;
                    item.label = item.description;
                });
                let taxConfigurations = result.taxConfigurations.filter(i => i.code !== AppEnum.TaxInfoCode.TaxRegime);
                taxConfigurations.forEach(function (item, index) {
                    if (item.code === AppEnum.TaxInfoCode.taxDoc) {
                        taxConfigurations.splice(index, 1);
                        taxConfigurations.unshift(item);
                    }
                });
                let prevSelectedRegime = taxRegimeList.filter(i => i.tableUIdValue !== null)[0];
                prevSelectedRegime = prevSelectedRegime === undefined ? "" : prevSelectedRegime.uId;
                let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
                taxRegimeList = [{ value: '', label: masterApiData.filter(i => i.key === 'UserDetail.PleaseSelectLabel')[0]?.value }].concat(taxRegimeList);

                setState((prevState) => {
                    return {
                        ...prevState, taxInfo: taxConfigurations, taxRegimeList: taxRegimeList, mobilityConfigurations: result.moblityConfigurations,
                        prevSelectedRegime: prevSelectedRegime
                    }
                })
            }
        })
    }

    // Expands the tax info section
    const expandTaxInfoSection = () => {
        setState((prevState) => { return { ...prevState, isTaxInfoSelected: !state.isTaxInfoSelected } })
    }

    // Expands the mobility section
    const expandMobilitySection = () => {
        setState((prevState) => { return { ...prevState, isMobilitySelected: !state.isMobilitySelected } })
    }

    // Expands the vehicle document section
    const expandVehicleSection = () => {
        setState((prevState) => { return { ...prevState, isVehicleDocSelected: !state.isVehicleDocSelected } })
    }

    // Expands the personal document section
    const expandPersonalSection = () => {
        setState((prevState) => { return { ...prevState, isPersonalDocSelected: !state.isPersonalDocSelected } })
    }

    // shows and hides the entered password
    const showHidePassword = (code) => {
        if (code === AppEnum.TaxInfoCode.CsdPassword) {
            setState((prevState) => { return { ...prevState, showCsdPassword: !state.showCsdPassword } })
        }
        if (code === AppEnum.TaxInfoCode.FielPassword) {
            setState((prevState) => { return { ...prevState, showFielPassword: !state.showFielPassword } })
        }
    }

    // It saves the choosen tax regime from dropdown
    const onChangeTaxRegimeHandler = (event) => {
        if (event.target.value) {
            let formData = new FormData();
            let regime = state.taxRegimeList.filter(i => i.value === event.target.value)[0];
            let oldRegime = state.taxRegimeList.filter(i => i.tableUIdValue !== null && i.value)[0];
            if (oldRegime)
                if (regime?.tableUIdValue === oldRegime?.tableUIdValue)
                    return;

            regime.tableUIdValue = oldRegime ? oldRegime.tableUIdValue : null;
            regime.titleValue = regime.title;
            regime.isSelected = true;

            const jsonModel = JSON.stringify(regime);
            formData.set('AttachmentConfiguration', jsonModel);

            documentService.uploadTaxDocument(formData).then((result) => {
                if (result) {
                    getTaxInfoDetails();
                    setState((prevState) => { return { ...prevState, isTaxRegimeAvailable: true, prevSelectedRegime: regime.uId } });
                    toast.success(state.labelList.filter(i => i.key === 'Validation.RegimeIsSaved')[0]?.value);
                }
            })
        }
        else
            setState((prevState) => { return { ...prevState, isTaxRegimeAvailable: false } });
    }

    // when user enters password and leaves the textbox then it saves the password
    const onBlurPasswordHandler = async (item, passwordType) => {
        let formData = new FormData();
        let isPasswordDifferent = false;
        if (passwordType === AppEnum.TaxInfoCode.CsdPassword && item.titleValue !== state.csdCertPassword) {
            item.titleValue = state.csdCertPassword;
            isPasswordDifferent = true;
        }
        if (passwordType === AppEnum.TaxInfoCode.FielPassword && item.titleValue !== state.fielCertPassword) {
            item.titleValue = state.fielCertPassword;
            isPasswordDifferent = true;
        }

        if (item.titleValue?.length > 0 && isPasswordDifferent) {
            item.isSelected = true;
            const jsonModel = JSON.stringify(item);
            formData.set('AttachmentConfiguration', jsonModel);

            documentService.uploadTaxDocument(formData).then((result) => {
                if (result) {
                    getTaxInfoDetails();
                    toast.success(state.labelList.filter(i => i.key === 'Validation.PasswordIsSaved')[0]?.value);
                }
            })
        }
    }

    // password handler i.e. Sets the password state
    const onChangePasswordHandler = (event, passwordType) => {
        let password = event.target.value;
        if (passwordType === AppEnum.TaxInfoCode.CsdPassword) {
            setState((prevState) => { return { ...prevState, csdCertPassword: password } });
            if (password.length === 0)
                setState((prevState) => { return { ...prevState, isCsdPasswordAvailable: true } });
            else
                setState((prevState) => { return { ...prevState, isCsdPasswordAvailable: false } });
        }

        if (passwordType === AppEnum.TaxInfoCode.FielPassword) {
            setState((prevState) => { return { ...prevState, fielCertPassword: password } });
            if (password.length === 0)
                setState((prevState) => { return { ...prevState, isFielPasswordAvailable: true } });
            else
                setState((prevState) => { return { ...prevState, isFielPasswordAvailable: false } });
        }
    }

    // It uploads all the documents which are in tax info section
    const uploadTaxInfoAttachment = (event, item, index) => {
        const fileName = 'file';
        const file = event.target.files[0];
        let allowedFileSize = Number(state.labelList.filter(i => i.key === 'Validation.AllowedFileSize')[0]?.value) * 1000000;
        if (file !== undefined) {
            if (file.size <= allowedFileSize) {
                let formData = new FormData();
                if (item.code === AppEnum.TaxInfoCode.taxDoc) {
                    if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
                        formData.append(fileName, file);
                        item.fileName = file.name;
                        item.isSelected = true;

                        const jsonModel = JSON.stringify(item);
                        formData.set('AttachmentConfiguration', jsonModel);
                        documentService.saveTaxAndMobilityDocuments(formData).then((result) => {
                            if (result) {
                                getTaxInfoDetails();
                                toast.success(state.labelList.filter(i => i.key === 'Validation.DocumentUploadedSuccessfully')[0]?.value);
                            }
                        })
                    }
                    else
                        toast.error(state.labelList.filter(i => i.key === 'UserDetail.SupportedFormatLabel')[0]?.value);
                }
                else {
                    formData.append(fileName, file);
                    item.fileName = file.name;
                    item.isSelected = true;

                    const jsonModel = JSON.stringify(item);
                    formData.set('AttachmentConfiguration', jsonModel);

                    documentService.uploadTaxDocument(formData).then((result) => {
                        if (result) {
                            getTaxInfoDetails();
                            toast.success(state.labelList.filter(i => i.key === 'Validation.DocumentUploadedSuccessfully')[0]?.value);
                        }
                    })
                }
            }
            else
                toast.error(state.labelList.filter(i => i.key === 'Validation.AllowedFileSizeLabel')[0]?.value);
        }
    }

    // it fetches all the documents uploaded by the driver
    const getAllDocuments = () => {
        documentService.getAllDocuments()
            .then((response) => {
                if (response) {
                    setVehicleDocuments(response?.vehicleDocuments);
                    setPersonalDocumentByVehicleType(response.personalDocuments);
                }
            })
    }

    // it sets the vehicle documents according to their vehicle UId
    const setVehicleDocuments = (vehicleDocs) => {
        let allVehicleDocuments = [];

        let totalVehicleUIds = [];
        vehicleDocs.forEach(doc => {
            if (!totalVehicleUIds?.includes(doc.userVehicleUId))
                totalVehicleUIds.push(doc.userVehicleUId);
        });

        totalVehicleUIds.forEach((uId) => {
            let vehicle = vehicleDocs?.filter(doc => doc.userVehicleUId === uId);
            let data = {
                title: vehicle[0]?.vehicleLable,
                docs: vehicle.filter(doc => doc.attachmentUId)
            }
            allVehicleDocuments.push(data);
        })
        setState((prevState) => { return { ...prevState, allVehicleDocuments: allVehicleDocuments } });
    }

    // it sets the personal document type according to the vehicle types for DL document
    const setPersonalDocumentByVehicleType = async (personalDocuments) => {

        let vehicle = JSON.parse(commonMethods.getLocalStorage('vehicleType'));

        // if (vehicle.isBicycle) {
        //     personalDocuments = personalDocuments.filter(i => (i.code === AppEnum.PersonalDocumentCode.IdentityDocument
        //         || i.code === AppEnum.PersonalDocumentCode.ProfilePicture || i.code === AppEnum.PersonalDocumentCode.TaxDocument));
        // }
        // else {
        //     personalDocuments = personalDocuments.filter(i => i.attachmentTypeCD === AppEnum.AttachmentTypeCD.ProfilePic
        //         || i.attachmentTypeCD === AppEnum.AttachmentTypeCD.Personal);
        // }

        personalDocuments = personalDocuments.filter(i => i.isAttachment ? (i.attachmentUId
            && (i.attachmentTypeCD === AppEnum.AttachmentTypeCD.Personal || i.code === AppEnum.PersonalDocumentCode.ProfilePicture
                || i.code === AppEnum.PersonalDocumentCode.TaxDocument)) : i.titleValue);

        if (vehicle.isTwoWheelerSelected && !vehicle.isFourWheelerSelected)
            personalDocuments = personalDocuments.filter(i => i.code !== AppEnum.PersonalDocumentCode.DL);
        if (!vehicle.isTwoWheelerSelected && vehicle.isFourWheelerSelected)
            personalDocuments = personalDocuments.filter(i => i.code !== AppEnum.PersonalDocumentCode.DlLite);

        setState((prevState) => {
            return { ...prevState, personalDocumentList: personalDocuments, vehicleType: vehicle, }
        });
    }

    // opens the chosen document
    const openPersonalDocument = (document) => {
        if (document.code === AppEnum.PersonalDocumentCode.SsnNumber) {
            if (document.titleValue) {
                let curpDetail = state.curpDetail;
                curpDetail.curp = document.titleValue;
                setState((prevState) => { return { ...prevState, curpDetail: curpDetail } });
            }
        }
        setState((prevState) => {
            return {
                ...prevState, chosenDocumentConfig: document, isDocumentChosen: true,
                isVehicleDocumentChosen: false, isMobilityDocumentChosen: false,
            }
        });
    }

    // opens the chosen vehicle document
    const openVehicleDocument = (document) => {
        setState((prevState) => {
            return {
                ...prevState, chosenDocumentConfig: document, isVehicleDocumentChosen: true,
                isMobilityDocumentChosen: false, isDocumentChosen: false
            }
        });
    }

    // opens the chosen vehicle document
    const openMobilityDocument = (document) => {
        setState((prevState) => {
            return {
                ...prevState, chosenDocumentConfig: document, isMobilityDocumentChosen: true,
                isDocumentChosen: false, isVehicleDocumentChosen: false
            }
        });
    }

    // Opens the document list page
    const goToBackPage = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isDocumentChosen: false,
                isVehicleDocumentChosen: false,
                isMobilityDocumentChosen: false,
                isChosenDocumentUploaded: false,
                documentFormData: null,
                chosenDocumentConfig: {}
            }
        });
    }

    // uploads the current chosen attachment
    const uploadChosenDocument = (event) => {
        const file = event.target.files[0];
        let allowedFileSize = Number(state.labelList.filter(i => i.key === 'Validation.AllowedFileSize')[0].value) * 1000000;
        if (file !== undefined) {
            if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
                if (file.size <= allowedFileSize) {
                    setChosenDocument(file);
                }
                else {
                    commonMethods.compressUploadedFile(file, Number(state.labelList.filter(i => i.key === 'Validation.CompressedFileSize')[0]?.value))
                        .then((blobFile) => {
                            if (blobFile) {
                                var newFile = new File([blobFile], blobFile.name, { type: blobFile.type });
                                setChosenDocument(newFile);
                            }
                        })
                }
            }
            else
                toast.error(state.labelList.filter(i => i.key === 'VehicleInfo.SupportedFormatLabel')[0]?.value);
        }
    }

    // sets the chosen document to state
    const setChosenDocument = (file) => {
        var input = document.getElementById(state.chosenDocumentConfig.code);
        var fReader = new FileReader();
        fReader.readAsDataURL(input.files[0]);
        fReader.onloadend = (event) => {
            var img = document.getElementById(state.chosenDocumentConfig.code + 'image');
            //setting image to preview
            img.src = event.target.result;
        }
        setState((prevState) => {
            return {
                ...prevState,
                documentFormData: file,
                isChosenDocumentUploaded: true
            }
        });
    }

    // send the chosen document with all config to the API
    const saveChosenDocument = (document) => {
        if (state.documentFormData || document?.curp) {
            let formData = new FormData();
            let documentConfig = state.chosenDocumentConfig;

            if (state.chosenDocumentConfig.code !== AppEnum.PersonalDocumentCode.SsnNumber) {
                documentConfig.name = state.documentFormData.name;
                documentConfig.fileName = state.documentFormData.name;
                documentConfig.size = state.documentFormData.size;
                documentConfig.type = state.documentFormData.type;
            }
            if (documentConfig.attachmentTypeCD === AppEnum.AttachmentTypeCD.Personal) {
                if (state.chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber) {
                    documentConfig.titleValue = document.curp;
                    documentConfig.isSelected = true
                }
                else {
                    documentConfig.isSelected = true
                    formData.append(state.documentFormData.name, state.documentFormData);
                }
                formData.append('AttachmentConfigurationDTO', JSON.stringify(documentConfig))
                documentService.savePersonalDocument(formData).then((result) => {
                    if (result) {
                        responseHandler(false)
                    }
                })
            }
            else if (documentConfig.attachmentTypeCD === AppEnum.AttachmentTypeCD.phase2Documents) {
                saveTaxAndMobilityDocuments(formData, documentConfig)
            }
            else if (documentConfig.attachmentTypeCD === AppEnum.AttachmentTypeCD.ProfilePic) {
                saveProfilePicture(formData)
            }
            else {
                formData.append(state.documentFormData.name, state.documentFormData);
                let jsonPayload = {
                    vehicleUId: documentConfig.userVehicleUId,
                    vehicleMobilityApps: [],
                    attachmentConfigurations: [documentConfig]
                }
                formData.append('UserVehicleDTO', JSON.stringify(jsonPayload));
                documentService.uploadVehicleAttachment(formData).then((result) => {
                    if (result)
                        responseHandler(false);
                })
            }
        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Validation.PleaseSelectFile')[0]?.value);
    }

    // handles the success response
    const responseHandler = (isTaxInfo) => {
        let toaster = state.labelList.filter(i => i.key === 'Validation.DocumentUploadedSuccessfully')[0]?.value;
        toast.success(toaster);
        setState((prevState) => {
            return {
                ...prevState, isDocumentChosen: false, isChosenDocumentUploaded: false, documentFormData: null
                , isVehicleDocumentChosen: false
            }
        });
        isTaxInfo ? getTaxInfoDetails() : getAllDocuments();
    }

    // it saves the tax and mobility documents
    const saveTaxAndMobilityDocuments = (formData, documentConfig) => {
        formData.append(state.documentFormData.name, state.documentFormData);
        documentConfig.fileName = state.documentFormData.name;
        documentConfig.isSelected = true;

        const jsonModel = JSON.stringify(documentConfig);
        formData.append('AttachmentConfiguration', jsonModel);

        documentService.saveTaxAndMobilityDocuments(formData).then((result) => {
            if (result)
                responseHandler(true);
        })
    }

    // it saves the profile picture
    const saveProfilePicture = (formData) => {
        formData.append(state.documentFormData.name, state.documentFormData);

        activeAccountService.uploadProfileImage(formData)
            .then((response) => {
                if (response)
                    responseHandler(false);
            })
    }

    return (
        <React.Fragment>
            <DocumentComponent
                showHidePassword={showHidePassword}
                expandTaxInfoSection={expandTaxInfoSection}
                expandMobilitySection={expandMobilitySection}
                expandVehicleSection={expandVehicleSection}
                expandPersonalSection={expandPersonalSection}
                state={state}
                uploadChosenDocument={uploadChosenDocument}
                onChangeTaxRegimeHandler={onChangeTaxRegimeHandler}
                onBlurPasswordHandler={onBlurPasswordHandler}
                onChangePasswordHandler={onChangePasswordHandler}
                uploadTaxInfoAttachment={uploadTaxInfoAttachment}
                goToBackPage={goToBackPage}
                openPersonalDocument={openPersonalDocument}
                openVehicleDocument={openVehicleDocument}
                saveChosenDocument={saveChosenDocument}
                openMobilityDocument={openMobilityDocument}
            />
        </React.Fragment>
    )
}

export default DocumentContainer;