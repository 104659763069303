import React, { useEffect, useState } from 'react';
import VooyHeader from '../../assets/logos/VooyHeader.png';
import * as loginService from '../../service/Login.Service';
import * as commonMethods from '../../common/CommonMethods';
import { getLocalStorage } from '../../common/CommonMethods';
import './HeaderModule.css'
import { useNavigate } from 'react-router-dom';

const AuthHeader = () => {

    const [partnerLanguageApiLabels, setpPrtnerLanguageApiLabels] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        let languageApiLabels = JSON.parse(getLocalStorage('languageApiLabels'));
        setpPrtnerLanguageApiLabels(languageApiLabels);
    }, [])

    // Removes the variables and routes to the login page
    const logout = () => {
        localStorage.removeItem('sessionId');
        let token = localStorage.getItem('token');
        if (token) {
            let logoutConfig = {
                authToken: token,
                fcmToken: '',
                userUId: commonMethods.getUserUIdFromToken()
            }
            loginService.logout(logoutConfig).then((response) => {
                if (response)
                    routeToLogin();
                else
                    routeToLogin();
            })
                .catch((error) => {
                    if (error)
                        routeToLogin();
                })
        }
        else
            routeToLogin();

        commonMethods.removeAsyncStorage();
    }

    // route to the login page
    const routeToLogin = () => {
        commonMethods.removeToken();
        navigate('/login');
    }

    return (
        <React.Fragment>
            <div className="header-div">
                <img className="header-logo" src={VooyHeader} alt="VooyRideLogo" />
                <div className="header-btn-div">
                    <button type="button" onClick={() => logout()}>{partnerLanguageApiLabels.filter(i => i.key === "Phase3.LogoutButton")[0]?.value}</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AuthHeader;