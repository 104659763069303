import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as loginService from '../../service/Login.Service';
import { AppEnum } from "../../common/AppEnum";
import { getLocalStorage } from "../../common/CommonMethods";
import forgotPassState, { formValidationSchema } from './ForgotPassState';
import ForgotPassword from "./ForgotPassword";

// it creates form data for getting oypUId
export function getFormData(userName) {
    let formData = new FormData();
    let languageCode = getLocalStorage('languageCode');

    formData.append('username', userName);
    formData.append('secureActionTypeCD', AppEnum.SecureActionTypeCD.forgotPassword);
    formData.append('linkTableCD', AppEnum.LinkTableCD.driver);
    formData.append('languageCode', languageCode);

    return formData;
}

const ForgotPassContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const [state, setState] = useState(forgotPassState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        let validationSchema = formValidationSchema(masterApiData);
        setState((prevState) => { return { ...prevState, formValidationSchema: validationSchema } });

        if (isComponentMounted.current)
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // Retrieves the otpUId and routes to the confirm password page for changing the password
    const onResetPassword = (userDetails) => {

        let formData = getFormData(userDetails.userName);

        loginService.forgotPassword(formData).then(res => {
            if (res) {
                navigate('/reset-password',
                    { state: { otpuId: res.uId, userName: userDetails.userName } }
                )
            }
        })
    }

    // Routes to the login page 
    const onLogin = () => {
        navigate('/login')
    }

    return (
        <React.Fragment>
            <ForgotPassword
                onResetPassword={onResetPassword}
                onLogin={onLogin}
                state={state}
            />
        </React.Fragment>
    )
}

export default ForgotPassContainer;