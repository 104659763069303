import { logisticAxios } from "../../core/LogisticApiInterceptor";

// saves personal information
export async function registerDriver(otpUId, driverDetails) {
    const response = await logisticAxios.post(`User/new-driver/${otpUId}`, driverDetails);
    return response?.data;
}

// sets the boolean value isNewRegister to false
export async function updateIsNewRegister(uId) {
    const response = await logisticAxios.put(`User/update-new-register?uId=${uId}`);
    return response?.data;
}

// saves vehicle information
export async function saveVehicleInfo(vehicleInfo) {
    const response = await logisticAxios.post(`UserVehicle`, vehicleInfo);
    return response?.data;
}

// updates personal data
export async function updatePersonalInfo(personalInfo) {
    const response = await logisticAxios.put(`User/update-driver`, personalInfo);
    return response?.data;
}

// sends the registration email to the user
export async function sendThankYouEmail() {
    const response = await logisticAxios.post(`User/send-thank-you-email`);
    return response.data;
}

// fetches state list by countryUId
export async function getStateDDO(countryCode) {
    return await logisticAxios.get(`State/getDDOByCountryCode?countryCode=` + countryCode)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.uId;
                item.label = item.name;
            })
            return response?.data
        })
}

// fetches city list by stateUId
export async function getCityDDO(stateUid) {
    return await logisticAxios.get(`City/getDDO?stateUId=` + stateUid)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.uId;
                item.label = item.name
            })
            return response?.data
        })
}