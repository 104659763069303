import './TwoWheelerHome.css';
import React, { useEffect, useState } from 'react';
import VooyLogo from '../../../assets/exam-images/VooyLogo.png';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage } from '../../../common/CommonMethods';

// States
const TwoWheelerHomeState = (function states() {
    return {
        labelList: []
    }
})()

const TwoWheelerHome =() => {

    const [state, setState] = useState(TwoWheelerHomeState);
    const navigate = useNavigate();

    useEffect(() => {
        let result = JSON.parse(getLocalStorage('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: result } });
        window.scrollTo(0, 0);
    }, [])

    //It routes to the course/training page
   const startCourse = () => {
        navigate( '/driver/training');
    }

    const { labelList } = state;

    return (
        <React.Fragment>
            <div className="home-sub-header-div">
                <div className="sub-header-labels">
                    <label>{labelList.filter(i => i.key === "Phase3.HomeSubHeaderLabel")[0]?.value}</label>
                    <img src={VooyLogo} alt="Vooy" />
                    <label>{labelList.filter(i => i.key === "Phase3.PartnerLabel")[0]?.value}</label>
                </div>
            </div>
            <div className="mission-main-div">
                <div className="about-mission-div">
                    <label>{labelList.filter(i => i.key === "Phase3.AboutUsLable2")[0]?.value}</label>
                    <p>{labelList.filter(i => i.key === "Phase3.AboutUsInfo1")[0]?.value}</p>
                    <p>{labelList.filter(i => i.key === "Phase3.AboutUsInfo2")[0]?.value}
                        {labelList.filter(i => i.key === "Phase3.AboutUsInfo3")[0]?.value}
                    </p>
                </div>
                <div className="mission-vision-objective-div">
                    <div className="about-mission-div">
                        <label>{labelList.filter(i => i.key === "Phase3.MissionTitle2")[0]?.value}</label>
                        <p>{labelList.filter(i => i.key === "Phase3.MissionText")[0]?.value}</p>
                    </div>
                    <div className="about-mission-div">
                        <label>{labelList.filter(i => i.key === "Phase3.VisionLabel")[0]?.value}</label>
                        <p>{labelList.filter(i => i.key === "Phase3.VisionText")[0]?.value}</p>
                    </div>
                    <div className="about-mission-div">
                        <label>{labelList.filter(i => i.key === "Phase3.ObjectiveLable")[0]?.value}</label>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ObjectiveInfo1")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ObjectiveInfo2")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ObjectiveInfo3")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ObjectiveInfo4")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ObjectiveInfo5")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ObjectiveInfo6")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ObjectiveInfo7")[0]?.value}</p>
                    </div>
                </div>
            </div>
            <div className="footer-div">
                <div>
                    <label>{labelList.filter(i => i.key === "Phase3.DeliveryService")[0]?.value}&nbsp;
                        <span className="service-label-color">{labelList.filter(i => i.key === "Phase3.DeliveryService2")[0]?.value}</span>
                    </label>
                    <p>{labelList.filter(i => i.key === "Phase3.RequestPackageText")[0]?.value}</p>
                    <hr />
                    <p>{labelList.filter(i => i.key === "Phase3.TrackLocationText")[0]?.value}</p>
                    <hr />
                    <p>{labelList.filter(i => i.key === "Phase3.CheckStateText")[0]?.value}</p>
                </div>
            </div>
            <div className="start-button-div">
                <button onClick={() => startCourse()}>{labelList.filter(i => i.key === "Phase3.StartCourseButton")[0]?.value}</button>
            </div>
            <div className="footer-line-div">
                <hr />
            </div>
        </React.Fragment>
    )
}

export default TwoWheelerHome;