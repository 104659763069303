import React, { useEffect, useRef, useState } from "react";
import documentState, { formValidationSchemaSsn } from './DriverDocumentsState';
import * as documentService from '../../service/document/Document.Service';
import { toast } from "react-toastify";
import * as commonMethods from '../../common/CommonMethods';
import { getLocalStorage } from "../../common/CommonMethods";
import DriverDocuments from "./DriverDocuments";
import { AppEnum } from "../../common/AppEnum";
import { useNavigate } from "react-router-dom";
import * as activeAccountService from '../../service/ActiveAccountService';
import * as signupService from '../../service/document/SignUpService';

const VehicleDocumentContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const [state, setState] = useState(documentState);

    // if back button is pressed then it will prevent it from going to previous page
    window.onpopstate = () => {
        if (state.isDocumentChosen)
            navigate("/driver/documents");
    }

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        let validationSchema = formValidationSchemaSsn(masterApiData);
        setState((prevState) => { return { ...prevState, formValidationSchemaSsn: validationSchema } });

        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
            getDocumentJsxList();
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // it fetches all the document attachment configuration
    const getDocumentJsxList = () => {

        documentService.getAllDocumentJsx().then((response) => {
            if (response) {
                let vehicle = commonMethods.checkVehicleType(response.userVehicles);

                let isRideService = JSON.parse(localStorage.getItem('typeOfService'))?.includes(AppEnum.ServiceTypeId.Ride);

                // setting categoryUId for fetching training list according to vehicleType
                if (isRideService && response.fourWheelerExamStatus !== AppEnum.SubmissionStatusCD.Passed)
                    commonMethods.setLocalStorage('trainingCategoryUId', AppEnum.TrainingCategoryUId.CAR_VAN);

                let ssnNumber;
                if (isRideService) {
                    ssnNumber = response.personalDocuments.filter(document => document.code === AppEnum.PersonalDocumentCode.SsnNumber)[0];
                    if (ssnNumber) {
                        let curpDetail = state.curpDetail;
                        curpDetail.curp = ssnNumber.titleValue;
                        setState((prevState) => { return { ...prevState, curpDetail: curpDetail } });
                    }
                }

                let isPersonalDocUploaded = (response.personalDocuments.filter(document => document.isAttachment && !document.attachmentUId)?.length
                    || (isRideService ? !ssnNumber?.titleValue : false)) ? false : true;

                let isVehicleDocUploaded = response.userVehicles[0]?.vehicleDocuments.filter(document => !document.attachmentUId)?.length ? false : true;

                setState((prevState) => {
                    return {
                        ...prevState, personalDocumentList: response.personalDocuments, vehicleDocumentList: response.userVehicles[0]?.vehicleDocuments,
                        isExamCompleted: response.isFourWheelerExamCompleted, examStatus: response.fourWheelerExamStatus, vehicleType: vehicle,
                        attemptLeft: response.fourWheelerRemainingAttempts, isRideService: isRideService,
                        isPersonalDocUploaded: isPersonalDocUploaded, isVehicleDocUploaded: isVehicleDocUploaded
                    }
                });
            }
        })
    }

    // opens the chosen document
    const openPersonalDocument = (document) => {
        setState((prevState) => {
            return { ...prevState, chosenDocumentConfig: document, isDocumentChosen: true }
        });
    }

    // send the chosen document with all config to the API
    const saveChosenDocument = (document) => {
        if (state.documentFormData || document?.curp) {
            let formData = new FormData();
            let documentConfig = state.chosenDocumentConfig;

            if (state.chosenDocumentConfig.code !== AppEnum.PersonalDocumentCode.SsnNumber) {
                documentConfig.name = state.documentFormData.name;
                documentConfig.fileName = state.documentFormData.name;
                documentConfig.size = state.documentFormData.size;
                documentConfig.type = state.documentFormData.type;
            }
            if (documentConfig.attachmentTypeCD === AppEnum.AttachmentTypeCD.Personal) {
                if (state.chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber) {
                    documentConfig.titleValue = document.curp;
                    documentConfig.isSelected = true
                }
                else {
                    documentConfig.isSelected = true
                    formData.append(state.documentFormData.name, state.documentFormData);
                }
                formData.append('AttachmentConfigurationDTO', JSON.stringify(documentConfig))
                documentService.savePersonalDocument(formData).then((result) => {
                    if (result) {
                        responseHandler()
                    }
                })
            }
            else if (documentConfig.attachmentTypeCD === AppEnum.AttachmentTypeCD.phase2Documents) {
                saveTaxAndMobilityDocuments(formData, documentConfig)
            }
            else if (documentConfig.attachmentTypeCD === AppEnum.AttachmentTypeCD.ProfilePic) {
                saveProfilePicture(formData)
            }
            else {
                formData.append(state.documentFormData.name, state.documentFormData);
                let jsonPayload = {
                    vehicleUId: documentConfig.tableUIdValue,
                    vehicleMobilityApps: [],
                    attachmentConfigurations: [documentConfig]
                }
                formData.append('UserVehicleDTO', JSON.stringify(jsonPayload));
                documentService.uploadVehicleAttachment(formData).then((result) => {
                    if (result)
                        responseHandler();
                })
            }
        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Validation.PleaseSelectFile')[0]?.value);
    }

    // it saves the tax and mobility documents
    const saveTaxAndMobilityDocuments = (formData, documentConfig) => {
        formData.append(state.documentFormData.name, state.documentFormData);
        documentConfig.fileName = state.documentFormData.name;
        documentConfig.isSelected = true;

        const jsonModel = JSON.stringify(documentConfig);
        formData.append('AttachmentConfiguration', jsonModel);

        documentService.saveTaxAndMobilityDocuments(formData).then((result) => {
            if (result)
                responseHandler();
        })
    }

    // it saves the profile picture
    const saveProfilePicture = (formData) => {
        formData.append(state.documentFormData.name, state.documentFormData);

        activeAccountService.uploadProfileImage(formData)
            .then((response) => {
                if (response)
                    responseHandler();
            })
    }

    // handles the success response
    const responseHandler = () => {
        getDocumentJsxList();
        let toaster = state.labelList.filter(i => i.key === 'Validation.DocumentUploadedSuccessfully')[0]?.value;
        toast.success(toaster);
        setState((prevState) => {
            return { ...prevState, isDocumentChosen: false, isChosenDocumentUploaded: false, documentFormData: null }
        });
    }

    // uploads the current chosen attachment
    const uploadChosenDocument = (event) => {
        const file = event.target.files[0];
        let allowedFileSize = Number(state.labelList.filter(i => i.key === 'Validation.AllowedFileSize')[0].value) * 1000000;
        if (file !== undefined) {
            if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
                if (file.size <= allowedFileSize) {
                    setChosenDocument(file);
                }
                else {
                    commonMethods.compressUploadedFile(file, Number(state.labelList.filter(i => i.key === 'Validation.CompressedFileSize')[0]?.value))
                        .then((blobFile) => {
                            if (blobFile) {
                                var newFile = new File([blobFile], blobFile.name, { type: blobFile.type });
                                setChosenDocument(newFile);
                            }
                        })
                }
            }
            else
                toast.error(state.labelList.filter(i => i.key === 'VehicleInfo.SupportedFormatLabel')[0]?.value);
        }
    }

    // sets the chosen document to state
    const setChosenDocument = (file) => {
        var input = document.getElementById(state.chosenDocumentConfig.code);
        var fReader = new FileReader();
        fReader.readAsDataURL(input.files[0]);
        fReader.onloadend = (event) => {
            var img = document.getElementById(state.chosenDocumentConfig.code + 'image');
            //setting image to preview
            img.src = event.target.result;
        }
        setState((prevState) => { return { ...prevState, documentFormData: file, isChosenDocumentUploaded: true } });
    }

    // logouts the session
    const logout = () => {
        setState((prevState) => { return { ...prevState, sureToLogout: true } });
    }

    // prevents logout
    const preventLogout = () => {
        setState((prevState) => { return { ...prevState, sureToLogout: false } });
    }

    // Opens the document list page
    const goToBackPage = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isDocumentChosen: false,
                isChosenDocumentUploaded: false,
                documentFormData: null,
                chosenDocumentConfig: {}
            }
        });
    }

    // opens modal to confirm for logout
    const confirmLogout = () => {
        setState((prevState) => { return { ...prevState, sureToLogout: false } });
        resetAllStates();
        commonMethods.removeToken();
        commonMethods.removeAsyncStorage();
        navigate('/login')
    }

    // after final submission it sets the states to their initial values
    const resetAllStates = () => {
        let curpDetail = state.curpDetail;
        curpDetail.curp = '';
        setState((prevState) => { return { ...prevState, curpDetail: curpDetail } });
    }

    let isFinishButtonActive = state.vehicleType.isFourWheelerSelected ? (state.isPersonalDocUploaded && state.isVehicleDocUploaded && state.isExamCompleted)
        : (state.isPersonalDocUploaded && state.isVehicleDocUploaded);

    // when all the documents are uploaded then it routes to the home page and sends the thank you email
    const onGoingNext = () => {
        if (isFinishButtonActive) {
            resetAllStates();
            commonMethods.removeAsyncStorage();
            signupService.sendThankYouEmail().then((response) => {
                if (response) {
                    navigate('/driver/thank-you-for-signing-up');
                }
            })
        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Validation.UploadAllFiles')[0]?.value);
    }

    // it routes to the exam page
    const goToExamScreen = () => {
        if (state.attemptLeft > 0) {
            (state.vehicleType.isTwoWheelerSelected && !state.vehicleType.isFourWheelerSelected) ? navigate('/driver/exam/home')
                : navigate('/driver/home');
        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Validation.AttemptsAreExhausted')[0]?.value);
    }

    return (
        <React.Fragment>
            <DriverDocuments
                state={state}
                openPersonalDocument={openPersonalDocument}
                saveChosenDocument={saveChosenDocument}
                uploadChosenDocument={uploadChosenDocument}
                logout={logout}
                onGoingNext={onGoingNext}
                goToBackPage={goToBackPage}
                confirmLogout={confirmLogout}
                preventLogout={preventLogout}
                goToExamScreen={goToExamScreen}
                isFinishButtonActive={isFinishButtonActive}
            />
        </React.Fragment>
    )
}

export default VehicleDocumentContainer;