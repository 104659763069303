import React, { Fragment } from 'react';
import './DocumentComponent.css';
import Slide from "@material-ui/core/Slide";
import Card from '@material-ui/core/Card';
import * as commonMethods from "../../../common/CommonMethods";
import UploadIcon from '../../../assets/logos/UploadIcon.png'
import SelectField from '../../../shared-component/select-field/SelectField';
import { AppEnum } from '../../../common/AppEnum';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button } from "react-bootstrap";
import UploadFile from './UploadFile';

// it returns the status jsx of any document
const documentStatusJsx = (item, labelList, remainingExpiryDays) => {

    return <div className={`${((item.attachmentUId && !item.isApproved && item.remark) || (item.isApproved && remainingExpiryDays < 0))

        || (item.code === AppEnum.PersonalDocumentCode.SsnNumber && (item.identityStatusCD === AppEnum.IdentityStatusCD.Rejected ? true : false))

        ? 'rejected-div' : (item.isApproved && remainingExpiryDays < 14) ? 'expiring-soon-div' : item.isApproved ? 'approved-active-div' :

            (item.attachmentUId && !item.isApproved) ? 'waiting-div' : 'pending-to-go'}`}>
        <span>
            {
                (item.attachmentUId && !item.isApproved && item.remark) ? labelList.filter(i => i.key === 'VehicleInfo.Rejected')[0]?.value :

                    (item.isApproved && remainingExpiryDays < 0) ?
                        labelList.filter(i => i.key === 'VehicleInfo.Expired')[0].value :

                        (item.isApproved && remainingExpiryDays < 14) ?
                            labelList.filter(i => i.key === 'VehicleInfo.ExpiringSoon')[0].value :

                            item.isApproved ? labelList.filter(i => i.key === 'VehicleInfo.Approved')[0]?.value :

                                (item.attachmentUId && !item.isApproved) ? labelList.filter(i => i.key === 'VehicleInfo.ApprovalPending')[0]?.value
                                    : labelList.filter(i => i.key === 'VehicleInfo.Pending')[0]?.value
            }
        </span>
    </div>
}

const DocumentComponent = (props) => {

    const { labelList, personalDocumentList, isMobilityDocumentChosen, taxInfo,
        allVehicleDocuments, isDocumentChosen, isVehicleDocumentChosen, vehicleType } = props.state;

    return (
        <Fragment>
            <div className='document-main-div'>
                <Card className='M-ui-card'>
                    <Slide direction="right" timeout={500} in={true} mountOnEnter unmountOnExit>
                        <div>
                            <label className='profile-label'>{labelList.filter(i => i.key === 'Menu.Documents')[0]?.value}</label>
                            <hr className='document-hr' />
                            <div className={props.state.isPersonalDocSelected ? 'doc-m-btm document-section' : 'document-section'} onClick={props.expandPersonalSection}>
                                <label>{labelList.filter(i => i.key === "Documents.PersonalDocs")[0]?.value}
                                    <i className={props.state.isPersonalDocSelected ? "arrow-icon-down fas fa-chevron-right" : "arrow-icon-right fas fa-chevron-right"}></i></label>
                            </div>
                            {/* personal document UI */}
                            {
                                props.state.isPersonalDocSelected &&
                                <div>
                                    {
                                        isDocumentChosen ?
                                            <UploadFile
                                                uploadChosenDocument={props.uploadChosenDocument}
                                                saveChosenDocument={props.saveChosenDocument}
                                                goToBackPage={props.goToBackPage}
                                                state={props.state}
                                            />
                                            :
                                            <Card className='M-ui-card'>
                                                <Slide direction="down" timeout={500} in={true} mountOnEnter unmountOnExit>
                                                    <div>
                                                        {
                                                            personalDocumentList?.map((document, index) => {

                                                                let isCurpNumber = document.code === AppEnum.PersonalDocumentCode.SsnNumber ? true : false;
                                                                let remainingExpiryDays = commonMethods.remainingExpiryDays(new Date(document.expiryDateTime));

                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <div className={`${((document.attachmentUId && !document.isApproved && document.remark) || (isCurpNumber
                                                                            && (document.identityStatusCD === AppEnum.IdentityStatusCD.Rejected ? true : false)) ||
                                                                            (document.isApproved && (!isCurpNumber && (remainingExpiryDays < 0)))) ? 'rejected'

                                                                            : (document.isApproved && (!isCurpNumber && (remainingExpiryDays < 14))) ? 'expiring-soon-tab'

                                                                                : document.isApproved ? 'approved' :

                                                                                    ((document.attachmentUId && !document.isApproved) || (isCurpNumber && document.titleValue && !document.isApproved))
                                                                                        ? 'waiting' : ''}  ${'doc-tab'}`}
                                                                            onClick={() => props.openPersonalDocument(document, index)}
                                                                        >
                                                                            <label>{document?.title}
                                                                                <i className="arrow-icon-right fas fa-chevron-right chevron-right"></i></label>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Slide>
                                            </Card>
                                    }
                                </div>

                            }
                            <div className={(props.state.isVehicleDocSelected && !isVehicleDocumentChosen) ? 'doc-vehicle-btm document-section' : 'document-section'} onClick={props.expandVehicleSection}>
                                <label>{labelList.filter(i => i.key === "Documents.VehicleDocumentation")[0]?.value}
                                    <i className={props.state.isVehicleDocSelected ? "arrow-icon-down fas fa-chevron-right" : "arrow-icon-right fas fa-chevron-right"}></i></label>
                            </div>
                            {/* vehicle document UI */}
                            {
                                props.state.isVehicleDocSelected &&
                                <div>
                                    {
                                        isVehicleDocumentChosen ?
                                            <UploadFile
                                                uploadChosenDocument={props.uploadChosenDocument}
                                                saveChosenDocument={props.saveChosenDocument}
                                                goToBackPage={props.goToBackPage}
                                                state={props.state}
                                            />
                                            :
                                            <Card className='M-ui-card'>
                                                <Slide direction="down" timeout={500} in={true} mountOnEnter unmountOnExit>
                                                    <div>
                                                        {
                                                            allVehicleDocuments?.map((vehicle, index) => {

                                                                return (
                                                                    <React.Fragment key={`vehicle${index}`}>
                                                                        <label className='vehicle-label'>{vehicle?.title}</label>
                                                                        {
                                                                            vehicle?.docs?.map((document, index) => {

                                                                                let remainingExpiryDays = commonMethods.remainingExpiryDays(new Date(document.expiryDateTime));
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        <div className={`${((document.attachmentUId && !document.isApproved && document.remark) ||
                                                                                            (document.isApproved && (remainingExpiryDays < 0))) ? 'rejected'

                                                                                            : (document.isApproved && (remainingExpiryDays < 14)) ? 'expiring-soon-tab'

                                                                                                : document.isApproved ? 'approved'
                                                                                                    : (document.attachmentUId && !document.isApproved) ? 'waiting' : ''} ${'doc-tab'}`}

                                                                                            onClick={() => props.openVehicleDocument(document, index)}
                                                                                        >
                                                                                            <label>{document.title}<i className="arrow-icon-right fas fa-chevron-right chevron-right"></i></label>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )

                                                                            })
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Slide>
                                            </Card>
                                    }
                                </div>

                            }
                            {(vehicleType?.isFourWheelerSelected) &&
                                <div className='document-section' onClick={props.expandTaxInfoSection}>
                                    <label>{labelList.filter(i => i.key === "Phase2.TaxInfoTitle")[0]?.value}
                                        <i className={props.state.isTaxInfoSelected ? "arrow-icon-down fas fa-chevron-right" : "arrow-icon-right fas fa-chevron-right"}></i></label>
                                </div>
                            }
                            {/* tax info UI */}
                            {
                                props.state.isTaxInfoSelected &&
                                <Card className='M-ui-card'>
                                    <Slide direction="down" timeout={500} in={true} mountOnEnter unmountOnExit>
                                        <div>
                                            <div className='proof-of-tax-div'>
                                                {
                                                    taxInfo?.map((item, index) => {

                                                        const imagUrl = item.apiUrl + item.attachmentUrl;
                                                        const imagurl = imagUrl ? imagUrl : item.logoUrl;
                                                        let remainingExpiryDays = commonMethods.remainingExpiryDays(new Date(item.expiryDateTime), new Date());

                                                        return (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    item.isAttachment ?
                                                                        <div className="main-attachment-div">
                                                                            <div className="upload-div-width tax-doc-div">
                                                                                {
                                                                                    item.code === AppEnum.TaxInfoCode.CsdKeyTitle &&
                                                                                    <label className='doc-label'>{labelList.filter(i => i.key === 'Phase2.CsdCertificate')[0]?.value}</label>
                                                                                }
                                                                                {
                                                                                    item.code === AppEnum.TaxInfoCode.FielKeyTitle &&
                                                                                    <label className='doc-label'>{labelList.filter(i => i.key === 'Phase2.AdvancedFiel')[0]?.value}</label>
                                                                                }
                                                                                <div className={item.code !== AppEnum.TaxInfoCode.taxDoc ? "upload-main-active-div" : "upload-proof-of-tax"}>
                                                                                    {item.fileName &&
                                                                                        <div className={item.code === AppEnum.TaxInfoCode.taxDoc ? 'proof-tax-doc-active uploaded_doc_image_preview' : 'uploaded_doc_image_preview'}>
                                                                                            {
                                                                                                (item.contentType === "image/png" || item.contentType === "image/jpeg" || item.contentType === "image/jpg" || item.contentType === null) ?
                                                                                                    <div className='tax-upload' onClick={() => { commonMethods.showUploadedAttachment('image' + index) }}   >
                                                                                                        {
                                                                                                            item.attachmentUId &&
                                                                                                            <img src={imagurl} id={'image' + index} alt='Document' height="115" width="180" />
                                                                                                        }
                                                                                                        {documentStatusJsx(item, labelList, remainingExpiryDays)}
                                                                                                    </div>
                                                                                                    : <i className="fas fa-file file-icon"></i>
                                                                                            }
                                                                                            <span className='text-span'>
                                                                                                {item.fileName ? commonMethods.trimText(item.fileName, 19) : ''}
                                                                                            </span>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        item.code === AppEnum.TaxInfoCode.taxDoc &&
                                                                                        <div className='margin-b-upload'>
                                                                                            <label className='upload-tax-label'>{item.title}</label>
                                                                                            <label className="proof-tax-label">{item.description}.</label>
                                                                                            <li className='list'>{labelList.filter(i => i.key === "Phase2.MakeSureThat")[0]?.value}</li>
                                                                                            <li className='list'>{labelList.filter(i => i.key === "Phase2.SeeHow")[0]?.value}
                                                                                                &nbsp;<a href={AppEnum.AnchorLinks.SatGovt} target="_blank" className="sat-link">
                                                                                                    {labelList.filter(i => i.key === "Phase2.Here")[0]?.value}</a>
                                                                                            </li>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.isApproved === false || item.attachmentUId === null || remainingExpiryDays < 14) ?
                                                                                            <div className={item.code === AppEnum.TaxInfoCode.taxDoc ? 'upload-tooltip-div240' : 'upload-tooltip-div'}>
                                                                                                <div className="choose-doc-file-div doc-tax choose-doc-tax-div">
                                                                                                    {
                                                                                                        <div>
                                                                                                            <div className={item.fileName === null ? "upload-doc-div" : "uploaded-doc-div"}>
                                                                                                                <div className={item.code !== AppEnum.TaxInfoCode.taxDoc && "tooltip-active-div"}>
                                                                                                                    {
                                                                                                                        item.code !== AppEnum.TaxInfoCode.taxDoc && <label htmlFor={'file' + index} className="upload-title-lable tax-label">{item.title}
                                                                                                                            {(item.code === AppEnum.TaxInfoCode.CsdCertificate || item.code === AppEnum.TaxInfoCode.Fiel) && <label className="extension-label">(.cer)</label>}
                                                                                                                            {(item.code === AppEnum.TaxInfoCode.CsdKeyTitle || item.code === AppEnum.TaxInfoCode.FielKeyTitle) && <label className="extension-label">(.key)</label>}
                                                                                                                        </label>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        item.code !== AppEnum.TaxInfoCode.taxDoc &&
                                                                                                                        <div className='tooltip-icon-tax pass-tooltip-div'>
                                                                                                                            <OverlayTrigger
                                                                                                                                key={item.code}
                                                                                                                                id={item.code}
                                                                                                                                placement='top'
                                                                                                                                overlay={
                                                                                                                                    <Tooltip className="strong-password">
                                                                                                                                        {item.description}
                                                                                                                                    </Tooltip>
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Button variant="light" type="button" onClick={() => { }}>
                                                                                                                                    <i className="fas fa-info-circle pass-tooltip-div" data-tip data-for={item.code} ></i>
                                                                                                                                </Button>
                                                                                                                            </OverlayTrigger>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {
                                                                                                                    <label htmlFor={'file' + index} className="upload-image-active-account tax-label" >
                                                                                                                        {labelList.filter(i => i.key === "Phase2.Upload")[0]?.value}<img src={UploadIcon} alt='upload' />
                                                                                                                    </label>
                                                                                                                }
                                                                                                                <input type="file" id={'file' + index} key={item.keyInputValue} accept={item.code === AppEnum.TaxInfoCode.taxDoc ? "image/png, image/jpeg, image/jpg" :
                                                                                                                    (item.code === AppEnum.TaxInfoCode.CsdCertificate || item.code === AppEnum.TaxInfoCode.Fiel) ? ".cer; capture=camera" : ".key; capture=camera"}
                                                                                                                    onChange={(event) => props.uploadTaxInfoAttachment(event, item, index)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="expiry-date">
                                                                                                                {item.expiryDateTime ? <label>{labelList.filter(i => i.key === 'VehicleInfo.Expiry')[0]?.value}</label> : ""}
                                                                                                                {item.expiryDateTime ? <label className="red">{" "}&nbsp;
                                                                                                                    {moment.utc(item.expiryDateTime).local().format('DD/MM/YYYY')}</label> : ""}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="expiry-date">
                                                                                                {item.expiryDateTime ? <label>{labelList.filter(i => i.key === 'VehicleInfo.Expiry')[0]?.value}</label> : ""}
                                                                                                {item.expiryDateTime ? <label className="red">{" "}&nbsp;
                                                                                                    {moment.utc(item.expiryDateTime).local().format('DD/MM/YYYY')}</label> : ""}
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                item.code === AppEnum.TaxInfoCode.taxDoc &&
                                                                                <div className="tax-regime-div">
                                                                                    <div className='upload-tax-div'>
                                                                                        <label className='upload-regime'>{labelList.filter(i => i.key === "Phase2.TaxRegime")[0]?.value}</label>
                                                                                        <div className='tooltip-icon-tax pass-tooltip-div'>
                                                                                            <OverlayTrigger
                                                                                                key={item.code}
                                                                                                id={item.code}
                                                                                                placement='top'
                                                                                                overlay={
                                                                                                    <Tooltip className="strong-password">
                                                                                                        {labelList.filter(i => i.key === "Validation.RegimeDesc")[0]?.value}
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <Button variant="light" type="button" onClick={() => { }}>
                                                                                                    <i className="fas fa-info-circle" data-tip data-for={item.code} ></i>
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </div>
                                                                                    <SelectField
                                                                                        id="taxRegime"
                                                                                        name="taxRegime"
                                                                                        value={props.state.prevSelectedRegime}
                                                                                        option={props.state.taxRegimeList}
                                                                                        className="form-control"
                                                                                        handleChange={(event) => props.onChangeTaxRegimeHandler(event)}
                                                                                    />
                                                                                    <hr />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div className="password-div-hr">
                                                                            <div className="csd-password-div">
                                                                                <div className='upload-tax-div'>
                                                                                    <label>{item.title}</label>
                                                                                    <div className='tooltip-icon-tax pass-tooltip-div'>
                                                                                        <OverlayTrigger
                                                                                            key={item.code}
                                                                                            id={item.code}
                                                                                            placement='top'
                                                                                            overlay={
                                                                                                <Tooltip className="strong-password">
                                                                                                    {item.description}
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <Button variant="light" type="button" onClick={() => { }}>
                                                                                                <i className="fas fa-info-circle pass-tooltip-div" data-tip data-for={item.code} ></i>
                                                                                            </Button>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="password-input-div">
                                                                                    <input type={item.code === AppEnum.TaxInfoCode.CsdPassword ? (props.state.showCsdPassword ? "text" : "password")
                                                                                        : (props.state.showFielPassword ? "text" : "password")} className="form-control" id={item.code} defaultValue={item.titleValue ? item.titleValue : null}
                                                                                        onChange={(event) => props.onChangePasswordHandler(event, item.code)}
                                                                                        onBlur={() => props.onBlurPasswordHandler(item, item.code)}
                                                                                    />
                                                                                    <i className={item.code === AppEnum.TaxInfoCode.CsdPassword ? (props.state.showCsdPassword ? "fa fa-eye" : "fa fa-eye-slash")
                                                                                        : (props.state.showFielPassword ? "fa fa-eye" : "fa fa-eye-slash")} onClick={() => props.showHidePassword(item.code)}></i>
                                                                                </div>
                                                                                {
                                                                                    (props.state.isCsdPasswordAvailable || props.state.isFielPasswordAvailable) &&
                                                                                    <div className="red align-remark">
                                                                                        <span className="remark_span_adjust">{labelList.filter(i => i.key === "Validation.BillingFieldsAreRequired")[0]?.value}</span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {(index === taxInfo.length - 1) ? <div className='document-section'></div> : <hr />}
                                                                        </div>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Slide>
                                </Card>
                            }
                            {
                                (vehicleType?.isFourWheelerSelected) &&
                                <div className='document-section' onClick={props.expandMobilitySection}>
                                    <label>{labelList.filter(i => i.key === "Phase2.MobilityTitle")[0]?.value}
                                        <i className={props.state.isMobilitySelected ? "arrow-icon-down fas fa-chevron-right" : "arrow-icon-right fas fa-chevron-right"}></i></label>
                                </div>
                            }
                            {/* mobility documents UI */}
                            {
                                props.state.isMobilitySelected &&
                                <div>
                                    {
                                        isMobilityDocumentChosen ?
                                            <UploadFile
                                                uploadChosenDocument={props.uploadChosenDocument}
                                                saveChosenDocument={props.saveChosenDocument}
                                                goToBackPage={props.goToBackPage}
                                                state={props.state}
                                            />
                                            :
                                            <Card className='M-ui-card'>
                                                <Slide direction="down" timeout={500} in={true} mountOnEnter unmountOnExit>
                                                    <div>
                                                        {
                                                            props.state.mobilityConfigurations?.map((document, index) => {

                                                                let remainingExpiryDays = commonMethods.remainingExpiryDays(new Date(document.expiryDateTime));
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <div className={`${((document.attachmentUId && !document.isApproved && document.remark) ||
                                                                            (document.isApproved && (remainingExpiryDays < 0))) ? 'rejected'

                                                                            : (document.isApproved && (remainingExpiryDays < 14)) ? 'expiring-soon-tab'

                                                                                : document.isApproved ? 'approved'
                                                                                    : (document.attachmentUId && !document.isApproved) ? 'waiting' : ''} ${'doc-tab'}`}

                                                                            onClick={() => props.openMobilityDocument(document, index)}
                                                                        >
                                                                            <label>{document.title}<i className="arrow-icon-right fas fa-chevron-right chevron-right"></i></label>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Slide>
                                            </Card>
                                    }
                                </div>
                            }
                            <div className='doc-container-m'></div>
                        </div>
                    </Slide >
                </Card >
            </div>
        </Fragment >
    )
}

export default DocumentComponent;