
// States
const states = (function states() {
    return {
        labelList: [],
        mobileNumber: '',
        otp: '',
        mobileOtp: '',
        userPwd: '',
        isMobileOtpError: false,
        languageCode: '',
    }
})()

export default states;