import React, { Fragment } from 'react';
import './MyShipments.css';
import Slide from "@material-ui/core/Slide";
import Card from '@material-ui/core/Card';
import moment from 'moment';
import { Virtuoso } from 'react-virtuoso';
import { AppEnum } from '../../../common/AppEnum';

const MyShipments = (props) => {

    const { labelList, shipmentList } = props.state;

    return (
        <Fragment>
            <div className='ride-main-div'>
                <Card className='M-ui-card'>
                    <Slide direction="right" timeout={500} in={true} mountOnEnter unmountOnExit>
                        <div>
                            <div>
                                <label className='profile-label'>{labelList.filter(i => i.key === 'Menu.MyShipments')[0]?.value}</label>
                                <hr className='ride-shipment-hr' />
                            </div>
                            <div className='shipment-div'>
                                <table>
                                    {
                                        shipmentList?.length !== 0 &&
                                        <tr className='menu-column-row scrollbar-width'>
                                            <td>{labelList.filter(i => i.key === 'Menu.Date')[0]?.value}</td>
                                            <td>{labelList.filter(i => i.key === 'Menu.Distance')[0]?.value}</td>
                                            <td>{labelList.filter(i => i.key === 'Menu.Duration')[0]?.value}</td>
                                            <td>{labelList.filter(i => i.key === 'Menu.cost')[0]?.value}</td>
                                            <td>{labelList.filter(i => i.key === 'Menu.Status')[0]?.value}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <Virtuoso
                                            style={{ height: '40vh' }}
                                            data={shipmentList}
                                            endReached={props.fetchNextShipmentList}
                                            itemContent={(index, shipment) => {
                                                return (
                                                    <tr key={index} id={index} className={index % 2 ? 'menu-column-row' : 'menu-column-row-odd'}>
                                                        <td>{moment.utc(shipment.orderDate).local().format('DD/MM/YYYY')}</td>
                                                        <td>{Math.round((shipment.arrivalDistanceInKm + Number.EPSILON) * 100) / 100} km</td>
                                                        <td>{Math.round((shipment.arrivalTimeInMin + Number.EPSILON) * 100) / 100} min</td>
                                                        <td>{shipment.currencyCode === AppEnum.CurrencyCode.Indian ? <i className="fas fa-rupee-sign"></i>
                                                            : <i className="fas fa-dollar-sign doller-icon"></i>} {Math.round((shipment.totalAmount + Number.EPSILON) * 100) / 100}</td>
                                                        <td>{shipment.orderStatus}</td>
                                                    </tr>
                                                )
                                            }}
                                            components={{
                                                Footer: () => {
                                                    return (
                                                        <div>
                                                            {
                                                                shipmentList?.length === 0 &&
                                                                <div>
                                                                    <span className='no-records'>{labelList.filter(i => i.key === 'Validation.NoRecords')[0]?.value}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            }}
                                        />
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </Slide >
                </Card >
            </div>
        </Fragment>
    )
}

export default MyShipments;