import React from "react";
import './VerifyOtpModule.css';
import OtpField from 'react-otp-field';

const VerifyOtp = (props) => {

    const { labelList, mobileNumber, mobileOtp } = props.state;

    return (
        <React.Fragment>
            <div className='login-main-div'>
                <div className='otp-container'>
                    <h2 className="verify-otp-label">{labelList.filter(i => i.key === 'VerifyOTP.VerifyOTP')[0]?.value}</h2>
                    <div>
                        <div className="otp-lbl-div">
                            <label>{labelList.filter(i => i.key === 'VerifyOTP.EnterReceivedOtpLabel')[0]?.value}</label>
                            <label>&nbsp;{mobileNumber}</label>
                        </div>
                        <div className="form-group">
                            <div className="otp-input-div">
                                <OtpField
                                    value={mobileOtp}
                                    onChange={props.onChangeOtpHandler}
                                    numInputs={6}
                                    onChangeRegex={/^([0-9]{0,})$/}
                                    autoFocus
                                    type='tel'
                                    isTypeNumber={true}
                                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                />
                                <div className="resend-div">
                                    <label> {labelList.filter(i => i.key === 'VerifyOTP.DidNotReceiveOtp')[0]?.value}&nbsp;</label>
                                    <label className="resend-label" onClick={props.onReSendOTP}> {labelList.filter(i => i.key === 'VerifyOTP.Resend')[0]?.value}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={mobileOtp.length === 6 ? "verify-button-div" : "verify-button-div disable-btn"}>
                        <button onClick={props.onVerifyOTP}>{labelList.filter(i => i.key === 'VerifyOTP.SendButton')[0]?.value}</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default VerifyOtp;