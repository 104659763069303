import React from "react";
import './DriverHome.css';
import ThankYouBanner from '../../assets/banners/ThankYouBanner.png';

const DriverHome = (props) => {

    const { labelList } = props.state;

    return (
        <React.Fragment>
            <div className='home-main-div'>
                <div className='login-container'>
                    <h2 className='thank-you-title'>{labelList.filter(i => i.key === 'VehicleInfo.ThankYou')[0]?.value}</h2>
                    <div>
                        <div className='thank-you'>
                            <img src={ThankYouBanner} alt='CheckHome' />
                        </div>
                        <div className='home-button-div'>
                            <button type='submit' onClick={props.onGoingNext}>{labelList.filter(i => i.key === 'Phase2.OkButton')[0]?.value}</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DriverHome;