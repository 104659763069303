import React, { useEffect, useRef, useState } from "react";
import * as loginService from '../../service/Login.Service';
import forgotPassState, { resetPassFormValidationSchema } from './ForgotPassState';
import { toast } from "react-toastify";
import * as commonMethods from "../../common/CommonMethods";
import { useLocation, useNavigate } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import { getFormData } from "./ForgotPassContainer";
import { AppEnum } from "../../common/AppEnum";

const ResetPassContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState(forgotPassState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        let validationSchema = resetPassFormValidationSchema(masterApiData);
        setState((prevState) => { return { ...prevState, resetPassFormValidationSchema: validationSchema } });

        if (isComponentMounted.current)
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });

        if (!location.state?.otpuId)
            navigate('/forgot-password')

        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // Resends the otp to the registered mobile number and sets the new otp UId
    const onReSendOTP = () => {
        let formData = getFormData(location.state.userName);
        loginService.resendOTP(formData).then(res => {
            if (res) {

                location.state.otpuId = res.uId;
                setState((prevState) => {
                    return { ...prevState, isOtpResent: true }
                });
                toast.success(state.labelList.filter(i => i.key === 'Validation.OtpSentSuccessfully')[0]?.value)
            }
        })
    }

    // It checks the strength of the password and displays it
    const onchangePasswordHandler = (password) => {
        if (password.length >= 6) {
            let passwordStrength = commonMethods.checkPasswordStrength(password);
            setState((prevState) => {
                return {
                    ...prevState, passwordStrength: passwordStrength,
                    isPasswordStrength: true
                }
            });
        }
        else
            setState((prevState) => { return { ...prevState, passwordStrength: {}, isPasswordStrength: false } });
    }

    // Sets the form data and changes the password then routes to login
    const onSubmit = (userDetails) => {
        let formData = new FormData();

        formData.append('otp_uid', location.state.otpuId);
        formData.append('sms_otp', "");
        formData.append('email_otp', userDetails.otp);
        formData.append('new_password', userDetails.password);
        formData.append('secureActionTypeCD', AppEnum.SecureActionTypeCD.forgotPassword);

        loginService.changePassword(formData).then((res) => {
            if (res) {
                navigate('/login');
                toast.success(state.labelList.filter(i => i.key === 'Validation.PasswordUpdatedSuccessFully')[0]?.value);
            };
        })
    }

    return (
        <React.Fragment>
            <ResetPassword
                onSubmit={onSubmit}
                onchangePasswordHandler={onchangePasswordHandler}
                onReSendOTP={onReSendOTP}
                state={state}
            />
        </React.Fragment>
    )
}

export default ResetPassContainer;