import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthHeader from '../../shared-component/auth-header/AuthHeader';
import MenuContainer from '../active-account-flow/driver-menu/MenuContainer';

const ActiveAccountDashboard = () => {

    return (
        <React.Fragment>
            <AuthHeader />
            <div className='main-menu-container'>
                <div className='layout-container'>
                    <div className='menu-container'>
                        <MenuContainer />
                    </div>
                    <Outlet />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ActiveAccountDashboard;