import React, { useEffect, useState, Fragment } from 'react';
import myRidesState from './MyRidesState';
import * as activeAccountService from '../../../service/ActiveAccountService';
import MyRides from './MyRides';
import { AppEnum } from '../../../common/AppEnum';

const MyRidesContainer = () => {

    const [state, setState] = useState(myRidesState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        let pageSize = masterApiData.filter(i => i.key === 'Validation.RidePageSize')[0]?.value
        setState((prevState) => { return { ...prevState, labelList: masterApiData, pageSize: Number(pageSize) } })
        getPrevRideList(state.startPageNo, Number(pageSize));
        return () => {
            setState({});
        };
    }, [])

    // it fetches the paginated ride list 
    const getPrevRideList = (startPageNo, pageSize) => {
        activeAccountService.getRideList(AppEnum.SortOrder.NewToOld, startPageNo, pageSize).then((response) => {
            if (response) {
                setState((prevState) => {
                    return {
                        ...prevState, rideList: [...state.rideList, ...response.data],
                        startPageNo: Number(startPageNo)
                    }
                });
            }
        })
    }

    // it fetches the further rides when partner reaches to the bottom of the page
    const fetchNextRideList = () => {
        let currentPageNo = state.startPageNo;
        currentPageNo = currentPageNo + 1;
        getPrevRideList(currentPageNo, state.pageSize);
    }

    return (
        <Fragment>
            <MyRides
                state={state}
                fetchNextRideList={fetchNextRideList}
            />
        </Fragment>
    )
}

export default MyRidesContainer;