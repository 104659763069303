import React, { useEffect, useState } from 'react';
import { getLocalStorage } from '../../../common/CommonMethods';
import './Exam.css';
import { useNavigate, useLocation } from "react-router-dom";

const ThankYouForExam = () => {

    const [labelList, setLabelList] = useState([]);
    const [isExamCompleted, setIsExamCompleted] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let result = JSON.parse(getLocalStorage('languageApiLabels'));
        setLabelList(result);
        setIsExamCompleted(true);
    }, [])

    // if back button is pressed then it will prevent it from going to previous page
    window.onpopstate = () => {
        if (isExamCompleted)
            examCompleted();
    }

    // Removes the exam related variables and routes to the login page
    const examCompleted = () => {
        navigate('/driver/documents');
    }

    return (
        <React.Fragment>
            <div className="thank-you-body-div">
                <div className="thank-you-div">
                    <label>{labelList.filter(i => i.key === "Phase3.TrainingExamTitle")[0]?.value}</label>
                    <div className="thank-you-banner">
                        {
                            location.state?.isAutoSubmitted ?
                                <label className="exam-submitted-label">{labelList.filter(i => i.key === "Phase3.AutoSubmitted")[0]?.value}</label>
                                : <label className="exam-submitted-label">{labelList.filter(i => i.key === "Phase3.ExamIsSubmitted")[0]?.value}</label>
                        }
                        <label className="congratulations-label">{labelList.filter(i => i.key === "Phase3.CongratulationsText")[0]?.value}</label>
                    </div>
                    <div className="clever-button-div">
                        <button onClick={() => examCompleted()}>{labelList.filter(i => i.key === "Phase3.CleverButton")[0]?.value}</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ThankYouForExam;