import React from "react";
import { Formik, Field, Form } from 'formik';
import './ForgotPasswordModule.css';
import Red from '../../assets/images/Red.png'
import Yellow from '../../assets/images/Yellow.png'
import Green from '../../assets/images/Green.png'
import * as commonMethods from '../../common/CommonMethods';
import FormikInput from "../../shared-component/formik/FormikInput";
import Tooltip from 'react-bootstrap/Tooltip';
import { Button } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const ResetPassword = (props) => {

    const { resetPassDetail, resetPassFormValidationSchema, labelList, isPasswordStrength, passwordStrength } = props.state;

    return (
        <div className='login-main-div'>
            <Formik
                initialValues={resetPassDetail}
                validationSchema={resetPassFormValidationSchema}
                onSubmit={props.onSubmit} >
                {(formik) => (
                    <React.Fragment>
                        <div className="login-container reset-container">
                            <h2 className="pass-title">{labelList.filter(i => i.key === 'ForgotPassword.ChangePassword')[0]?.value}</h2>
                            <Form noValidate>
                                <div className={isPasswordStrength ? "password-div margin-b-pass m-btm" : "password-div m-btm"}>
                                    <Field
                                        name="password"
                                        id="password"
                                        type="password"
                                        placeholder={labelList.filter(i => i.key === 'ForgotPassword.NewPassword')[0]?.value}
                                        onChange={(event) => props.onchangePasswordHandler(event)}
                                        component={FormikInput} />
                                    <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'password')}></i>
                                </div>
                                {
                                    isPasswordStrength &&
                                    <span className={passwordStrength.className}>
                                        {
                                            passwordStrength.strength === "Excellent" ?
                                                <div className='pass-strength-bar'>
                                                    <div className='pass-tooltip-div'>
                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthExcellent')[0].value}

                                                        <OverlayTrigger
                                                            key='excellentPassword'
                                                            id='excellentPassword'
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip className="strong-password">
                                                                    {labelList.filter(i => i.key === 'Validation.PasswordStrengthExcellentToolTip')[0].value}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button variant="light" type="button" onClick={() => { }}>
                                                                <i className="fas fa-info-circle pass-tooltip-div i-strong" data-tip data-for='excellentPassword' ></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <img src={Green} alt="Excellent Password" width="100%" />
                                                </div>
                                                :
                                                passwordStrength.strength === "Medium" ?
                                                    <div className='pass-strength-bar'>
                                                        <div className='pass-tooltip-div'>
                                                            {labelList.filter(i => i.key === 'Validation.PasswordStrengthMedium')[0].value}

                                                            <OverlayTrigger
                                                                key='mediumPassword'
                                                                id='mediumPassword'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip className="medium-password">
                                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthMediumToolTip')[0].value}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Button variant="light" type="button">
                                                                    <i className="fas fa-info-circle pass-tooltip-div i-medium" data-tip data-for='mediumPassword' ></i>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <img src={Yellow} alt="Medium Password" width="100%" />
                                                    </div>
                                                    :
                                                    <div className='pass-strength-bar'>
                                                        <div className='pass-tooltip-div'>
                                                            {labelList.filter(i => i.key === 'Validation.PasswordStrengthLow')[0].value}

                                                            <OverlayTrigger
                                                                key='lowPassword'
                                                                id='lowPassword'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip className="low-password">
                                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthLowToolTip')[0].value}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Button variant="light" type="button" >
                                                                    <i className="fas fa-info-circle pass-tooltip-div i-low" data-tip data-for='lowPassword' ></i>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <img src={Red} alt="Low Password" width="100%" />
                                                    </div>
                                        }
                                    </span>
                                }
                                <div className="input-sub-div">
                                    <div className="password-div m-btm">
                                        <Field
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            type="password"
                                            placeholder={labelList.filter(i => i.key === 'ForgotPassword.ConfirmPasswordLabel')[0]?.value}
                                            component={FormikInput} />
                                        <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'confirmPassword')}></i>
                                    </div>
                                </div>
                                <div className="input-sub-div">
                                    <Field
                                        name="otp"
                                        id="otp"
                                        type="text"
                                        maxLength="6"
                                        placeholder={labelList.filter(i => i.key === 'ForgotPassword.EnterOTPLabel')[0]?.value}
                                        component={FormikInput}
                                    />
                                </div>
                                <div className={(formik.isValid && formik.dirty) ? 'login-button-div margin-b-btn' : 'login-button-div disable-btn margin-b-btn'}>
                                    <button type="submit" className='change-pass-btn'>
                                        {labelList.filter(i => i.key === 'ForgotPassword.ChangePassword')[0]?.value}
                                    </button>
                                </div>
                                <div className="resend-otp-div">
                                    <label onClick={props.onReSendOTP}> {labelList.filter(i => i.key === 'VerifyOTP.DidNotReceiveOtp')[0]?.value}&nbsp;</label>
                                    <label className="resend-label" onClick={props.onReSendOTP}> {labelList.filter(i => i.key === 'VerifyOTP.Resend')[0]?.value}</label>
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>)}
            </Formik>
        </div>
    );
}

export default ResetPassword;