import React from 'react';
import './HeaderModule.css';
import VooyHeader from '../../assets/logos/VooyHeader.png';

const Header = () => {

    return (
        <React.Fragment>
            <div className='header-partner-div'>
                <img src={VooyHeader} alt='HeaderLogo'/>
            </div>
        </React.Fragment>
    )
}

export default Header;