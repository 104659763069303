import { logisticAxios } from "../core/LogisticApiInterceptor";
import { tokenAxios } from "../core/TokenAPIInterceptor";


// uploads profile picture
export async function uploadProfileImage(profileImageConfig) {
    const response = await logisticAxios.put(`User/upload-profile-pic`, profileImageConfig);
    return response?.data;
}

// saves partner profile new info
export async function savePartnerProfileInfo(partnerConfig) {
    const response = await logisticAxios.put(`User`, partnerConfig);
    return response?.data;
}

// fetches ride list
export async function getRideList(sortOrder, startPageNo, pageSize) {
    const response = await logisticAxios.get(`Ride/list-for-partner-profile?Filter.SortOrder=${sortOrder}&StartPageNo=${startPageNo}&PageSize=${pageSize}`);
    return response?.data;
}

// fetches shipment list
export async function getShipmentList(sortOrder, startPageNo, pageSize) {
    const response = await logisticAxios.get(`Shipment/list-for-partner-profile?Filter.SortOrder=${sortOrder}&StartPageNo=${startPageNo}&PageSize=${pageSize}`);
    return response?.data;
}

// updates if mobile no/ email does not exist
export async function updateMobileOrEmail(newCredentials) {
    const response = await tokenAxios.put(`user/change-email-mobile`, newCredentials);
    return response?.data;
}

// validates if mobile no/ email already exists
export async function validateMobileNoOrEmail(credentialConfig) {
    const response = await tokenAxios.put(`user/validate-email-mobile-change`, credentialConfig);
    return response?.data;
}

// sends the otp to the email or mobile
export async function submitEmailOrMobileOtp(credentialConfig) {
    const response = await tokenAxios.put('user/change-email-mobile', credentialConfig);
    return response?.data
}

export async function getUserProfile() {
    const response = await logisticAxios.get(`User/profile`);
    return response?.data;
}