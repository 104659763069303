import './App.css';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import AppContainer from './AppContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomNavigator from './common/CustomNavigator';
import { useNavigate } from 'react-router-dom';

// sets the navigation history object 
const CustomNavigatorSetter = () => {

  CustomNavigator.navigateHistory = useNavigate();
  return null;
};


function App() {
  return (
    <React.Fragment>
      <CustomNavigatorSetter />
      <ToastContainer />
      <AppContainer />
    </React.Fragment>
  );
}

export default App;
