import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppEnum } from "../../../common/AppEnum";
import { getLocalStorage, scrollToTop } from "../../../common/CommonMethods";
import Training from "./Training";
import * as examService from '../../../service/document/ExamService';

// States
const states = (function states() {
    return {
        trainingList: [],
        trainingContent: {},
        labelList: [],
        selectedQuestionUId: 0,
        selectedQuestionIndex: 0
    }
})();

// returns the categoryUId according to the vechicle type
export const getCategoryUId = () => {
    let trainingCategoryUId = getLocalStorage('trainingCategoryUId');
    let categoryUId = (trainingCategoryUId === AppEnum.TrainingCategoryUId.BIKE_BICYCLE)
        ? AppEnum.TrainingCategoryUId.BIKE_BICYCLE : AppEnum.TrainingCategoryUId.CAR_VAN;

    return categoryUId;
}

const TrainingContainer = () => {

    const navigate = useNavigate();
    const [state, setState] = useState(states);

    useEffect(() => {
        let result = JSON.parse(getLocalStorage('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: result } });
        getTrainingList();
        scrollToTop();
    }, [])

    // It fetches the course list according to the vehicle type and displays it
    const getTrainingList = () => {
        let language = getLocalStorage('language');
        let categoryUId = getCategoryUId();

        examService.getTrainingList(categoryUId, language)
            .then((response) => {

                if (response) {
                    response = response.sort((item1, item2) => item1.displayOrder - item2.displayOrder);
                    let index = state.selectedQuestionIndex;
                    getTrainingContent(response[index]?.uId, index);
                    setState((prevState) => { return { ...prevState, trainingList: response } });
                }
            })
    }

    // when partner clicks on any course then it fetches its course content and displays it
    const getTrainingContent = (uId, index) => {

        examService.getTrainingContent(uId)
            .then(response => {
                if (response)
                    setState((prevState) => {
                        return {
                            ...prevState, trainingContent: response,
                            selectedQuestionUId: uId, selectedQuestionIndex: index
                        }
                    });
            })
    }

    // It fetches the next course content i.e. it plays the next video
    const playNextVideo = () => {
        let index = state.selectedQuestionIndex + 1;
        if (index < state.trainingList.length) {
            let training = state.trainingList[index];
            getTrainingContent(training.uId, index);
        }
    }

    // It fetches the previous course content i.e. it plays the previous video
    const playPreviousVideo = () => {
        if (state.selectedQuestionIndex > 0) {
            let index = state.selectedQuestionIndex - 1;
            let training = state.trainingList[index];
            getTrainingContent(training.uId, index);
        }
    }

    // when the partner finishes the course he can start the exam 
    const startExam = () => {
        navigate('/driver/exam');
    }

    // It leads to the first video of course list
    const goToStart = () => {
        setState((prevState) => { return { ...prevState, selectedQuestionUId: 0, selectedQuestionIndex: 0 } });
        getTrainingList();
    }

    return (
        <Fragment>
            <Training
                state={state}
                getTrainingList={getTrainingList}
                goToStart={goToStart}
                getTrainingContent={getTrainingContent}
                playPreviousVideo={playPreviousVideo}
                playNextVideo={playNextVideo}
                startExam={startExam}
            />
        </Fragment>
    )
}

export default TrainingContainer;