
import jwt_decode from "jwt-decode";
import { getLocalStorage } from "./CommonMethods";

// It decodes the token
export function decodeJWTToken() {
    const token = getLocalStorage('token');
    if (token) {
        const decoded = jwt_decode(token);
        return decoded;
    }
    else
        return '';
}