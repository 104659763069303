import * as Yup from 'yup';

const vehicleType = () => {
    return {
        vehicleTypeUId: '',
        vehicleTypeId: '',
        vehicleTypeCode: ''
    }
}

// Document form validation schema
export const formValidationSchema = (res) => Yup.object().shape({
    vehicleTypeUId: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.VehicleTypeIsRequired')[0]?.value).nullable(),
});

// States
const vehicleState = (function states() {
    return {
        vehicleType: vehicleType(),
        formValidationSchema: formValidationSchema(),
        labelList: [],
        vehicleTypeList: [],
        remark: ''
    }
})()

export default vehicleState;