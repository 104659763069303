import * as Yup from 'yup';

const userDetail = () => {
    return {
        userName: '',
    }
}

const regexEmail = /^(?!\.)(?!.*\.$)(?!.*?\.\.)(?!.*?\.@)([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

const resetPassDetail = () => {
    return {
        userName: '',
        password: '',
        confirmPassword: '',
        otp: ''
    }
}

// Confirm password form validation schema
export const resetPassFormValidationSchema = (res) => {
    return Yup.object().shape({
        password: Yup.string()
            .required(res?.filter(i => i.key === 'Validation.NewPasswordRequired')[0]?.value)
            .min(6, res?.filter(i => i.key === 'Validation.PasswordMinimumLength')[0]?.value)
            .matches('^[^\\s]+$', { message: res?.filter(i => i.key === 'Validation.PasswordInvalidError')[0]?.value, excludeEmptyString: false })
            .max(15),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], res?.filter(i => i.key === 'Validation.PasswordMismatchError')[0]?.value)
            .required(res?.filter(i => i.key === 'Validation.ConfirmPasswordIsRequired')[0]?.value),
        otp: Yup.string()
            .min(6, res?.filter(i => i.key === 'Validation.OtpRequiredErrorLabel')[0]?.value)
            .required(res?.filter(i => i.key === 'Validation.OtpRequiredErrorLabel')[0]?.value),
    });
}

// Forget password form validation schema
export const formValidationSchema = (res) => {
    return Yup.object().shape({
        userName: Yup.string()
            .matches(regexEmail, { message: res?.filter(i => i.key === 'Validation.EmailIsInvalid')[0]?.value, excludeEmptyString: false })
            .required(res?.filter(i => i.key === 'Validation.EmailIsRequired')[0]?.value),
    });
}

// States
const forgotPassState = (function states() {
    return {
        userDetail: userDetail(),
        resetPassDetail: resetPassDetail(),
        formValidationSchema: formValidationSchema(),
        resetPassFormValidationSchema: resetPassFormValidationSchema(),
        isOtpResent: false,
        labelList: [],
        isPasswordStrength: false,
        passwordStrength: {},
    }
})()
export default forgotPassState;