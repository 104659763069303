// States
const ExamHomeState = (function states() {
    return {
        labelList: [],
    }
})()

export default ExamHomeState;


