import React from "react";
import { Formik, Field, Form } from 'formik';
import './DriverDocuments.css';
import UploadIcon from '../../assets/logos/UploadIcon.png'
import * as commonMethods from '../../common/CommonMethods';
import Modal from 'react-bootstrap/Modal';
import FormikInput from "../../shared-component/formik/FormikInput";
import { AppEnum } from "../../common/AppEnum";

export const UploadDocumentJsx = (chosenDocumentConfig, isChosenDocumentUploaded, desc1, desc2, desc3, listTitle1,
    listTitle2, listTitle3, listTitle4, props, labelList) => {
    return <div>
        <div className='ine-div'>
            <h2 className='tos-title'>{chosenDocumentConfig.title}</h2>
            {desc1 && <label>{desc1}</label>}
            {desc2 && <label>{desc2}</label>}
            {desc3 && <label>{desc3}</label>}
            <div  >
                {listTitle1 && <li>{listTitle1}</li>}
                {listTitle2 && <li>{listTitle2}</li>}
                {listTitle3 && <li>{listTitle3}</li>}
                {listTitle4 && <li>{listTitle4}</li>}
            </div>
            <div className={(isChosenDocumentUploaded || chosenDocumentConfig.apiUrl) ? 'document-image-div' :
                ((chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.VehicleImage && !chosenDocumentConfig.attachmentUrl) ? 'vehicle-image' : 'document-image')}>
                <img src={chosenDocumentConfig.apiUrl ? (chosenDocumentConfig.apiUrl + chosenDocumentConfig.attachmentUrl) :
                    chosenDocumentConfig.logoUrl} id={chosenDocumentConfig.code + 'image'} alt={chosenDocumentConfig.code} />
            </div>
            {
                (chosenDocumentConfig.identityStatusCD === AppEnum.IdentityStatusCD.Rejected || chosenDocumentConfig.remark) &&
                <label className='rejected-remark-doc'>{labelList.filter(i => i.key === 'UserDetail.Remark')[0]?.value} : {chosenDocumentConfig.remark}</label>
            }
            {
                (chosenDocumentConfig.remark || !chosenDocumentConfig.attachmentUrl || (chosenDocumentConfig?.expiryDateTime && (commonMethods.remainingExpiryDays(new Date(chosenDocumentConfig?.expiryDateTime), new Date()) < 14))) ?
                    <div>
                        <div className='rating-attachment-div'>
                            <label htmlFor={chosenDocumentConfig.code} className="upload-rating-label" >
                                {labelList.filter(i => i.key === 'Phase2.Upload')[0]?.value}
                                <img src={UploadIcon} alt='Upload' /></label>
                            <input type="file" id={chosenDocumentConfig.code} accept="image/png, image/jpg, image/jpeg, capture=camera"
                                onChange={(event) => props.uploadChosenDocument(event)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                            />
                        </div>
                        <div className={(isChosenDocumentUploaded) ? 'submit-btn-div submit-btn-doc' : 'submit-btn-div disable-btn submit-btn-doc'} >
                            <button type='submit' >{labelList.filter(i => i.key === 'VehicleInfo.Confirm')[0]?.value}</button>
                        </div>
                    </div> : ''
            }
            <div className='return-btn'>
                <button type='button' onClick={props.goToBackPage}>{labelList.filter(i => i.key === 'VehicleInfo.Return')[0]?.value}</button>
            </div>
        </div>
    </div>
}

const DriverDocuments = (props) => {

    const { labelList, formValidationSchemaSsn, curpDetail, chosenDocumentConfig, isChosenDocumentUploaded,
        vehicleDocumentList, personalDocumentList, isDocumentChosen, examStatus, vehicleType } = props.state;

    return (
        <div className='vehicle-main-div'>
            <Formik
                initialValues={chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber ?
                    curpDetail : ''}
                enableReinitialize='true'
                validationSchema={chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber ?
                    formValidationSchemaSsn : ''}
                onSubmit={fields => { props.saveChosenDocument(fields) }}
            >
                {(formik) => (
                    <React.Fragment >
                        <div className={
                            (isDocumentChosen ? ((chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber)
                                ? 'vehicle-container' : 'document-container-upload') : 'document-container')}>
                            <Form noValidate>
                                {
                                    isDocumentChosen ?
                                        <div>
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.SsnNumber &&
                                                <div className='curp-div'>
                                                    <h2 className='tos-title'>{chosenDocumentConfig.title}</h2>
                                                    <label>{labelList.filter(i => i.key === 'VehicleInfo.CurpDesc')[0]?.value}</label>
                                                    <a href='https://www.gob.mx/curp/' target='_blank'>{labelList.filter(i => i.key === 'VehicleInfo.CheckHere')[0]?.value}</a>
                                                    <div className="form-group">
                                                        <div className="curp-input-div">
                                                            <Field name="curp" id="curp" type="text" maxLength='18' disabled={(chosenDocumentConfig.identityStatusCD === AppEnum.IdentityStatusCD.Rejected || !chosenDocumentConfig.titleValue) ? false : true}
                                                                placeholder={labelList.filter(i => i.key === 'PersonalDocument.EnterSSNNumber')[0]?.value} component={FormikInput} />
                                                        </div>
                                                    </div>
                                                    {
                                                        (chosenDocumentConfig.identityStatusCD === AppEnum.IdentityStatusCD.Rejected && chosenDocumentConfig.remark) &&
                                                        <label className='rejected-remark-doc'>{labelList.filter(i => i.key === 'UserDetail.Remark')[0]?.value} : {chosenDocumentConfig.remark}</label>
                                                    }
                                                    {
                                                        (chosenDocumentConfig.identityStatusCD === AppEnum.IdentityStatusCD.Rejected || !chosenDocumentConfig.titleValue) &&
                                                        <div className={(formik.isValid && formik.dirty) ? 'submit-btn-div submit-btn-doc' : 'submit-btn-div disable-btn submit-btn-doc'} >
                                                            <button type='submit' >{labelList.filter(i => i.key === 'VehicleInfo.Confirm')[0]?.value}</button>
                                                        </div>
                                                    }
                                                    <div className='return-btn'>
                                                        <button type='button' onClick={props.goToBackPage}>{labelList.filter(i => i.key === 'VehicleInfo.Return')[0]?.value}</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.IdentityDocument &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.IdentificationDesc')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.DocumentMustBeOriginal')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ImageLegible')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ImageWeigh')[0]?.value,
                                                    false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.ProfilePicture &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProfilePictureDesc')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProfilePicture1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProfilePicture2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProfilePicture3')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProfilePicture4')[0]?.value,
                                                    props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.TaxDocument &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProofOfTaxDesc')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax3')[0]?.value,
                                                    false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.Toxilogical &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.Analysis')[0]?.value,
                                                    labelList.filter(i => i.key === 'Phase2.ToxilogicalLabelYouMust')[0]?.value,
                                                    labelList.filter(i => i.key === 'Phase2.ToxilogicalLabelTestMust')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint3')[0]?.value,
                                                    false, false, false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.Icet &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'Phase2.UploadIcet')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint3')[0]?.value,
                                                    false, false, false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.MobilityInstitute &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'Phase2.MobilitySubLabel')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint3')[0]?.value,
                                                    false, false, false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.ProofOfAddress &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.AddressProofDescription')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.AddressProof1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.AddressProof2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.AddressProof3')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.AddressProof4')[0]?.value,
                                                    props, labelList
                                                )
                                            }
                                            {
                                                (chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.DL || chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.DlLite) &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.DriverLicenseDesc')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.CirculationCard1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.DrivingLicensePoint2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.AddressProof4')[0]?.value,
                                                    false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.BirthCertificate &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.BirthCertificateDescription')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.BirthCertificatePoint3')[0]?.value,
                                                    false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.VehicleImage &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.CarVehicleImageDescription')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.VehicleImagePoint1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.VehicleImagePoint2')[0]?.value,
                                                    false, false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.VehicleLicense &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.LicensePlateDesc')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.LicensePlate1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.LicensePlate2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.LicensePlate3')[0]?.value,
                                                    false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.VehicleInsurance &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.VehicleInsuranceDesc')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.VehicleInsurancePoint1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.VehicleInsurancePoint2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.VehicleInsurancePoint3')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.VehicleInsurance4')[0]?.value,
                                                    props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.VehicleDocumentCode.CirculationCard &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.CirculationCardDesc')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.CirculationCard1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.CirculationCard2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.CirculationCard3')[0]?.value,
                                                    false, props, labelList
                                                )
                                            }
                                            {
                                                chosenDocumentConfig.code === AppEnum.PersonalDocumentCode.TaxInformation &&
                                                UploadDocumentJsx(chosenDocumentConfig, isChosenDocumentUploaded,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProofOfTaxDesc')[0]?.value,
                                                    false, false,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax1')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax2')[0]?.value,
                                                    labelList.filter(i => i.key === 'VehicleInfo.ProofOfTax3')[0]?.value,
                                                    false, props, labelList
                                                )
                                            }
                                        </div>
                                        :
                                        <div>
                                            <div className='main-status-div'>
                                                <div className='status-div'>
                                                    <div className="status-icon-div">
                                                        <div className="pending-icon"></div>
                                                        <label className='status-label' >{labelList.filter(i => i.key === 'VehicleInfo.Pending')[0]?.value}</label>
                                                    </div>
                                                    <div className="status-icon-div">
                                                        <div className="waiting-icon"></div>
                                                        <label className='status-label' >{labelList.filter(i => i.key === 'VehicleInfo.ApprovalPending')[0]?.value}</label>
                                                    </div>
                                                    <div className="status-icon-div">
                                                        <div className="rejected-icon"></div>
                                                        <label className='status-label' >{labelList.filter(i => i.key === 'VehicleInfo.Expired')[0]?.value}</label>
                                                    </div>
                                                </div>
                                                <div className='status-div'>
                                                    <div className="status-icon-div">
                                                        <div className="approved-icon"></div>
                                                        <label className='status-label' >{labelList.filter(i => i.key === 'VehicleInfo.Approved')[0]?.value}</label>
                                                    </div>
                                                    <div className="status-icon-div">
                                                        <div className="rejected-icon"></div>
                                                        <label className='status-label' >{labelList.filter(i => i.key === 'VehicleInfo.Rejected')[0]?.value}</label>
                                                    </div>
                                                    <div className="status-icon-div">
                                                        <div className="expiring-soon"></div>
                                                        <label className='status-label' >{labelList.filter(i => i.key === 'VehicleInfo.ExpiringSoon')[0]?.value}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='hr-horizontal' />
                                            <div className="doc-tab-div">
                                                <h2 className='personal-title'>{labelList.filter(i => i.key === 'VehicleInfo.PersonalDocumentation')[0]?.value}</h2>
                                                {
                                                    personalDocumentList?.map((document, index) => {

                                                        let isCurpNumber = document.code === AppEnum.PersonalDocumentCode.SsnNumber ? true : false;
                                                        let remainingExpiryDays = commonMethods.remainingExpiryDays(new Date(document.expiryDateTime));

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className={`${((document.attachmentUId && !document.isApproved && document.remark) || (isCurpNumber
                                                                    && (document.identityStatusCD === AppEnum.IdentityStatusCD.Rejected ? true : false)) ||
                                                                    (document.isApproved && (!isCurpNumber && (remainingExpiryDays < 0)))) ? 'rejected'

                                                                    : (document.isApproved && (!isCurpNumber && (remainingExpiryDays < 14))) ? 'expiring-soon-tab'

                                                                        : document.isApproved ? 'approved' :

                                                                            ((document.attachmentUId && !document.isApproved) || (isCurpNumber && document.titleValue && !document.isApproved))
                                                                                ? 'waiting' : ''}  ${'tax-info-section'}`}
                                                                    onClick={() => props.openPersonalDocument(document, index)}
                                                                >
                                                                    <label>{document?.title}
                                                                        <i className="arrow-icon-right fas fa-chevron-right chevron-right"></i></label>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }

                                                {
                                                    !vehicleType.isTwoWheelerSelected &&
                                                    <div className={`${examStatus === AppEnum.SubmissionStatusCD.Failed ? 'rejected'
                                                        : examStatus === AppEnum.SubmissionStatusCD.Passed ? 'approved' : ''} ${'tax-info-section'}`}
                                                        onClick={() => examStatus !== AppEnum.SubmissionStatusCD.Passed ? props.goToExamScreen() : {}}
                                                    >
                                                        <label>{labelList.filter(i => i.key === 'Phase3.Exam')[0]?.value}<i className="arrow-icon-right fas fa-chevron-right chevron-right"></i></label>
                                                        
                                                    </div>
                                                }

                                            <h2 className='vehicle-title'>{labelList.filter(i => i.key === 'VehicleInfo.VehicleDocumentation')[0]?.value}</h2>
                                            {
                                                vehicleDocumentList?.map((document, index) => {

                                                    let remainingExpiryDays = commonMethods.remainingExpiryDays(new Date(document.expiryDateTime));
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className={`${((document.attachmentUId && !document.isApproved && document.remark) ||
                                                                (document.isApproved && (remainingExpiryDays < 0))) ? 'rejected'

                                                                : (document.isApproved && (remainingExpiryDays < 14)) ? 'expiring-soon-tab'

                                                                    : document.isApproved ? 'approved'
                                                                        : (document.attachmentUId && !document.isApproved) ? 'waiting' : ''} ${'tax-info-section'}`}

                                                                onClick={() => props.openPersonalDocument(document, index)}
                                                            >
                                                                <label>{document.title}<i className="arrow-icon-right fas fa-chevron-right chevron-right"></i></label>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                            <div className='logout-btn-div'>
                                                <div className='logout-button-div-doc'  >
                                                    <button type='button' onClick={() => props.logout()}>{labelList.filter(i => i.key === 'Phase3.LogoutButton')[0]?.value}</button>
                                                </div>
                                                <div className={(props.isFinishButtonActive) ? 'next-btn-div' : 'next-btn-div disable-btn'} >
                                                    <button type='button' disabled={!props.isFinishButtonActive}
                                                        onClick={() => props.onGoingNext()}>{labelList.filter(i => i.key === 'VehicleInfo.Next')[0]?.value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                }
                        {
                            <Modal className="modal-info" show={props.state.sureToLogout} onHide={props.preventLogout}>
                                <Modal.Body>
                                    <label className="logout-label">{labelList.filter(i => i.key === 'VehicleInfo.LogoutModal1')[0]?.value} </label>
                                    <label className="logout-content-label">{labelList.filter(i => i.key === 'VehicleInfo.LogoutModal2')[0]?.value}</label>
                                    <div className='logout-bttn-div'>
                                        <button type='button' className='no-btn' onClick={props.preventLogout} >
                                            {labelList.filter(i => i.key === 'VehicleInfo.No')[0]?.value}</button>
                                        <button type='button' className='yes-btn' onClick={props.confirmLogout} >
                                            {labelList.filter(i => i.key === 'VehicleInfo.Yes')[0]?.value}</button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        }
                    </Form>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default DriverDocuments;