import React from 'react'

// Returns a checkbox element
export const CheckBox = props => {
  return (
    <div className="form-group pet_checkbox_width_div">
      <input key={props.id} id={props.id} onChange={props.handleCheckElement} type="checkbox"
        checked={props.isChecked} value={props.value} disabled={props.disabled} /> {props.label}
    </div>
  )
}

export default CheckBox