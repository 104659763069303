import React, { useEffect, useRef, useState } from "react";
import vehicleState, { formValidationSchema } from './VehicleTypesState';
import * as documentService from '../../service/document/Document.Service';
import { useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../../common/CommonMethods";
import { AppEnum } from "../../common/AppEnum";
import VehicleTypes from "./VehicleTypes";
import { saveVehicleInfo } from "../vehicle-specification/VehicleSpecificationContainer";

const VehicleTypesContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const [state, setState] = useState(vehicleState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        let validationSchema = formValidationSchema(masterApiData);

        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData, formValidationSchema: validationSchema, } });
            getVehicleType(masterApiData);
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // It fetches the vehicle type list 
    const getVehicleType = (masterApiData) => {

        let typeOfService = JSON.parse(getLocalStorage('typeOfService'));
        let vehicleTypeList = [];

        if (typeOfService?.includes(AppEnum.ServiceTypeId.Ride)) {

            documentService.getVehicleTypeByServiceTypeId(AppEnum.ServiceTypeId.Ride)
                .then(res => {
                    if (res) {
                        vehicleTypeList = [...res];
                        setVehicleTypeList(vehicleTypeList, masterApiData);
                    }
                })
        }
        else {
            documentService.getVehicleType().then(res => {
                if (res) {
                    vehicleTypeList = [...res];
                    setVehicleTypeList(vehicleTypeList, masterApiData);
                }
            })
        }
    }

    // it sets the vehicle type list
    const setVehicleTypeList = (vehicleTypeList, masterApiData) => {

        setState((prevState) => {
            return {
                ...prevState,
                vehicleTypeList: [{ value: '', label: masterApiData.filter(i => i.key === 'VehicleInfo.Vehicletype')[0]?.value }, ...vehicleTypeList]
            }
        });
    }

    // handles on change vehicle type 
    const onChangeVehicleTypeHandler = (vehicleTypeUId) => {
        if (vehicleTypeUId) {
            let selectedVehicleType = [];
            selectedVehicleType = state.vehicleTypeList?.filter(i => i.uId === vehicleTypeUId);

            if (selectedVehicleType) {
                let vehicleTypeState = state.vehicleType;
                vehicleTypeState.vehicleTypeId = selectedVehicleType[0]?.id;
                vehicleTypeState.vehicleTypeUId = vehicleTypeUId;
                vehicleTypeState.vehicleTypeCode = selectedVehicleType[0]?.code;

                setState((prevState) => { return { ...prevState, vehicleType: vehicleTypeState } });
            }
        }
    }

    // sets the vehicle specification data and routes to the vehicle data page
    const onGoingNext = () => {
        setLocalStorage('vehicleTypeData', JSON.stringify(state.vehicleType));

        if (state.vehicleType.vehicleTypeCode === AppEnum.VehicleTypes.Bicycle) 
            saveVehicleInfo(state.vehicleType, state.labelList, navigate, '/driver/documents')
        else 
            navigate('/driver/vehicle-specification');
    }

    return (
        <React.Fragment>
            <VehicleTypes
                state={state}
                onGoingNext={onGoingNext}
                onChangeVehicleTypeHandler={onChangeVehicleTypeHandler}
            />
        </React.Fragment>
    )
}

export default VehicleTypesContainer;