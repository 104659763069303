import { supportAxios } from "../../core/SupportAPIInterceptor";

// fetches training list
export async function getTrainingList(categoryUId, language) {
    const response = await supportAxios.get(`training/categoryUId/${categoryUId}/language/${language}`);
    return response?.data;
}

export async function getTrainingContent(uId) {
    const response = await supportAxios.get(`lessonContent/lesson/${uId}`);
    return response?.data;
}

export async function getExamQuestions(categoryUId) {
    const response = await supportAxios.get(`exam/category/${categoryUId}`);
    return response?.data;
}

export async function submitAnswer(answer) {
    const response = await supportAxios.post(`exam/submission`, answer);
    return response?.data;
}

export async function submitExam(examUId, sessionId) {
    const response = await supportAxios.put(`exam/submission/submit/${examUId}/${sessionId}`);
    return response?.data;
}