import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import tosState from './ServiceTypeState';
import * as documentService from '../../service/document/Document.Service';
import ServiceType from "./ServiceType";
import { getLocalStorage, setLocalStorage } from "../../common/CommonMethods";
import { toast } from "react-toastify";

const ServiceTypeContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef();
    const [state, setState] = useState(tosState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
            getServiceType();
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // It fetches type of services
    const getServiceType = () => {
        documentService.getServiceType().then(res => {
            if (res) {
                res.map(type => {
                    type.value = type.serviceTypeId;
                    type.label = type.serviceTypeName;
                })
                let serviceTypes = JSON.parse(getLocalStorage('typeOfService'));
                if (serviceTypes) {
                    formikRef.current.dirty = true;
                    setState((prevState) => {
                        return { ...prevState, serviceTypeList: [...serviceTypes] }
                    });
                }
                setState((prevState) => { return { ...prevState, tosList: [...res] } });
            }
        })
    }

    // proceeds to the selected service type page
    const onGoingNext = () => {
        if (state.serviceTypeList.length > 0) {
            setLocalStorage('typeOfService', JSON.stringify(state.serviceTypeList));
            navigate('/driver/vehicle-types')
        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Validation.ChooseAnOption')[0]?.value);
    }

    // sets all the selected values
    const selectOptions = (option) => {
        let options = [];

        option.forEach(element => {
            options.push(element.value);
        });
        setState((prevState) => {
            return { ...prevState, serviceTypeList: [...options] }
        });
    }

    return (
        <React.Fragment>
            <ServiceType
                state={state}
                onGoingNext={onGoingNext}
                selectOptions={selectOptions}
                formikRef={formikRef}
            />
        </React.Fragment>
    )

}

export default ServiceTypeContainer;