import { logisticAxios } from "../../core/LogisticApiInterceptor";

// gets the type of service
export async function getServiceType() {
    const response = await logisticAxios.get(`UserService/services`);
    return response?.data;
}

// fetches vehicle types
export async function getVehicleType() {
    return await logisticAxios.get(`VehicleType/ddo/`)
        .then((response) => {
            response?.data.forEach((item) => {
                item.value = item.uId;
                item.label = item.name;
            });
            return response?.data
        })
}

// it fetches vehicle type list according to the serviceTypeId
export async function getVehicleTypeByServiceTypeId(serviceTypeId) {

    return await logisticAxios.get(`VehicleCategory/vehicles-by-serviceType/${serviceTypeId}`)
        .then((response) => {

            response?.data.forEach((item) => {
                item.value = item.uId;
                item.label = item.name;
            })
            return response?.data
        })
}

// Retrieves the Tax identity details
export async function getTaxInfoDetails() {
    return await logisticAxios.get(`TaxIdentity`)
        .then((response) => {
            return response?.data
        })
}

// Saves tax info documents one bye one
export async function uploadTaxDocument(taxDocument) {
    const response = await logisticAxios.post(`TaxIdentity`, taxDocument);
    return response?.data;
}

//uploads rating attachment
export async function uploadVehicleAttachment(formData) {
    const response = await logisticAxios.post(`UserVehicle/vehicle-document`, formData);
    return response?.data;
}

// gets all the documents of driver
export async function getAllDocuments() {
    const response = await logisticAxios.get(`User/get/profile/documents`);
    return response?.data;
}

// saves personal documents
export async function savePersonalDocument(document) {
    const response = await logisticAxios.post(`UserIdentity/save-file`, document);
    return response?.data;
}

export async function saveTaxAndMobilityDocuments(formData) {
    const response = await logisticAxios.post(`RegistrationPhase/upload-file`, formData);
    return response?.data;
}

export async function getUserVehicleList(userUid) {
    const response = await logisticAxios.get(`UserVehicle/list/` + userUid);
    return response?.data;
}

export async function getAllDocumentJsx() {
    const response = await logisticAxios.get(`User/get/vehicle/validate`);
    return response?.data;
}

export async function getVehicleMaker(vehicleTypeId) {
    return await logisticAxios.get(`VehicleBrand/getDDO?vehicleTypeId=${vehicleTypeId}`)
        .then((response) => {
            response.data?.forEach((item) => {
                item.value = item.uId;
                item.label = item.name
            })
            return response?.data
        })
}