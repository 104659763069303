import React from "react";
import { Formik, Field, Form } from 'formik';
import Select from "react-select";
import FormikInput from "../../shared-component/formik/FormikInput";
import FormikSelect from "../../shared-component/formik/FormikSelect";

const VehicleTypes = (props) => {

    const { labelList, formValidationSchema, vehicleType, vehicleTypeList } = props.state;

    return (
        <div className='vehicle-main-div'>
            <Formik
                initialValues={vehicleType}
                enableReinitialize='true'
                validationSchema={formValidationSchema}
                onSubmit={fields => {
                    props.onGoingNext(fields)
                }}
            >
                {(formik) => (
                    <React.Fragment >
                        <div className='vehicle-container'>
                            <div className='tooltip-div'>
                                <h2 className='tos-title'>{labelList.filter(i => i.key === 'VehicleInfo.VehicleSpecifications')[0]?.value}</h2>
                            </div>
                            {
                                props.state.remark &&
                                <label className='rejected-remark-specD'>{labelList.filter(i => i.key === 'UserDetail.Remark')[0]?.value} : {props.state.remark}</label>
                            }
                            <Form noValidate>
                                <div className='form-group'>
                                    <div className={`${'type-of-vehicle-div'} ${formik.values.vehicleTypeUId && 'value-available'}`}>
                                        <Field onChange={props.onChangeVehicleTypeHandler} option={vehicleTypeList} placeholder={labelList.filter(i => i.key === 'VehicleInfo.Vehicletype')[0]?.value}
                                            disabled={props.state.remark ? true : false} name='vehicleTypeUId' id='vehicleTypeUId' type='select' component={FormikSelect} />
                                    </div>
                                </div>
                                <div className={(formik.values.vehicleTypeUId) ?
                                    'login-button-div submit-delivery-btn' : 'login-button-div disable-btn submit-delivery-btn'}
                                >
                                    <button type='submit' >{labelList.filter(i => i.key === 'VehicleInfo.Next')[0]?.value}</button>
                                </div>
                            </Form>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default VehicleTypes;