import * as Yup from 'yup';

// Document form validation schema
export const formValidationSchemaSsn = (res) => Yup.object().shape({
    curp: Yup.string()
        .required(res?.filter(i => i.key === 'PersonalDocument.SSNInvalidError')[0]?.value)
        .min(18, res?.filter(i => i.key === 'PersonalDocument.SSNInvalidError')[0]?.value)
        .max(18, res?.filter(i => i.key === 'PersonalDocument.SSNInvalidError')[0]?.value),
});

const curpDetail = () => {
    return {
        curp: '',
    }
}

// States
const documentState = (function states(props) {
    return {
        curpDetail: curpDetail(),
        formValidationSchemaSsn: formValidationSchemaSsn(),
        labelList: [],
        isMobilitySelected: false,
        isTaxInfoSelected: false,
        isVehicleDocSelected: false,
        isPersonalDocSelected: false,
        personalDocumentList: [],
        vehicleDocumentList: [],
        mobilityConfigurations: [],
        taxInfo: [],
        taxRegimeList: [],
        csdCertPassword: '',
        fielCertPassword: '',
        prevSelectedRegime: "",
        isCsdPasswordAvailable: false,
        isFielPasswordAvailable: false,
        isTaxRegimeAvailable: false,
        isCsdUploaded: false,
        isCsdKeyUploaded: false,
        isFielUploaded: false,
        isFielKeyUploaded: false,
        showCsdPassword: false,
        showFielPassword: false,
        allVehicleDocuments: [],

        isDocumentChosen: false,
        chosenDocumentConfig: {},
        documentFormData: null,
        isChosenDocumentUploaded: false,
        isVehicleDocumentChosen: false,
        isMobilityDocumentChosen: false,
        vehicleType: {},
    }
})()

export default documentState;