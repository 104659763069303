import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as commonMethods from '../../common/CommonMethods';
import DriverHome from "./DriverHome";

const DriverHomeContainer = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({ labelList: [] });

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: masterApiData } });
        window.scrollTo(0, 0);
    }, [])

    // when all the documents are uploaded then it routes to the home page
    const onGoingNext = () => {
        navigate('/login')
        commonMethods.removeAsyncStorage();
        commonMethods.removeToken();
    }

    return (
        <React.Fragment>
            <DriverHome
                state={state}
                onGoingNext={onGoingNext}
            />
        </React.Fragment>
    )
}

export default DriverHomeContainer;