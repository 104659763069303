import React, { Fragment } from "react";
import { AppEnum } from "../../../common/AppEnum";
import Countdown from 'react-countdown';
import CheckBox from '../../../shared-component/checkBox/CheckBoxComponent';

const Exam = (props) => {

    const { labelList, examData, questionDisplayOrder } = props.state;
    const { question, totalExamDuration, mcqAnswersUId, answeredIds, date } = props;

    return (
        <Fragment>
            <div>
                <div className="main-body-div">
                    <div className="exam-title-countdown-div">
                        <label>{examData.title}</label>
                        {
                            totalExamDuration !== 0 &&
                            <div className="countdown-div">
                                <Countdown date={date + totalExamDuration}
                                    renderer={props.countdownRenderer}
                                />
                                <p>{labelList.filter(i => i.key === "Phase3.RemainingTimeLabel")[0]?.value}</p>
                            </div>
                        }
                    </div>
                    <div className="questions-div">
                        <div className="questions-header">
                            <label>{labelList.filter(i => i.key === "Phase3.QuestionsLabel")[0]?.value} &nbsp;
                                {questionDisplayOrder}&nbsp;{labelList.filter(i => i.key === "Phase3.Of")[0]?.value}&nbsp;
                                {examData.questions && examData.questions.length}
                            </label>
                        </div>
                        <div className="question-label-div">
                            {
                                question.answers && <label>{questionDisplayOrder}.&nbsp;{question.description}</label>
                            }
                            <hr />
                            <div>
                                {
                                    question.answers &&
                                    question.answers.map((answer, index) => {
                                        return (
                                            <div className="answer-div" key={index}>
                                                <CheckBox id={answer.uId} isChecked={question.answerTypeCD === AppEnum.AnswerTypeCD.SINGLE_CHOICE ?
                                                    answeredIds.filter(i => i.answerUIds === answer.uId).length === 1 ? true : false
                                                    : (mcqAnswersUId.filter(i => i.questionUId === question.uId)[0] &&
                                                        mcqAnswersUId.filter(i => i.questionUId === question.uId)[0].answerUIds.filter(i => i === answer.uId).length === 1 ? true : false)
                                                }
                                                    handleCheckElement={() => props.handleAnswerCheck(answer.uId)} />
                                                <label>{answer.value}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={(questionDisplayOrder === 1 || questionDisplayOrder === examData.questions.length) ? "change-question-next-button" : "change-question-div"}>
                            {
                                examData.questions && questionDisplayOrder === 1 ? "" :
                                    <button className="previous-button" onClick={() => props.displayPreviousQuestion()}>
                                        {labelList.filter(i => i.key === "Phase3.PreviousButton")[0]?.value}
                                    </button>
                            }
                            {
                                examData.questions && questionDisplayOrder === examData.questions.length ? "" :
                                    < button onClick={() => props.displayNextQuestion(false)}>
                                        {labelList.filter(i => i.key === "Phase3.NextButton")[0]?.value}
                                    </button>
                            }
                        </div>
                    </div>
                    <button className="submit-answer-button" onClick={() => props.displayNextQuestion(true)}>
                        {labelList.filter(i => i.key === "Phase3.SubmitAnswerButton")[0]?.value}
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

export default Exam;