import React from 'react';
import './SpecialServicesModule.css';
import PetFriendlyLogo from '../../assets/logos/PetFriendlyLogo.png';
import WheelChairLogo from '../../assets/logos/WheelChairLogo.png';

const SpecialServices = (props) => {

    const { labelList, isSpecialAbility, isPetFriendly } = props.state;

    return (
        <div className='login-main-div'>
            <React.Fragment >
                <div className='login-container'>
                    <h2 className='login-title'>{labelList.filter(i => i.key === 'VehicleInfo.SpecialServices')[0]?.value}</h2>
                    <div>
                        <label className='service-label'>{labelList.filter(i => i.key === 'VehicleInfo.SpeciaCapabilities')[0]?.value}</label>
                        <div>
                            <div className='service-button-div'>
                                <div className='toggle-button-div'>

                                    <button type='button' className={isSpecialAbility ? 'button-opted' : ''} onClick={() => props.toggleDisablityOption(1)} >
                                        {labelList.filter(i => i.key === 'VehicleInfo.Yes')[0]?.value}</button>
                                    <button type='button' className={(!isSpecialAbility && isSpecialAbility !== null) ? 'button-opted' : ''} onClick={() => props.toggleDisablityOption(0)} >
                                        {labelList.filter(i => i.key === 'VehicleInfo.No')[0]?.value}</button>
                                </div>
                                <img src={WheelChairLogo} alt='WheelLogo' />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className='service-label'>{labelList.filter(i => i.key === 'VehicleInfo.PetFriendlyLabel')[0]?.value}</label>
                        <div>
                            <div className='service-button-div'>
                                <div className='toggle-button-div'>

                                    <button type='button' className={isPetFriendly ? 'button-opted' : ''} onClick={() => props.togglePetFriendlyOption(1)} >
                                        {labelList.filter(i => i.key === 'VehicleInfo.Yes')[0]?.value}</button>
                                    <button type='button' className={(!isPetFriendly && isPetFriendly !== null) ? 'button-opted' : ''}
                                        onClick={() => props.togglePetFriendlyOption(0)} >{labelList.filter(i => i.key === 'VehicleInfo.No')[0]?.value}</button>
                                </div>
                                <img src={PetFriendlyLogo} alt='Pet' />
                            </div>
                        </div>
                    </div>
                    <div className={(isSpecialAbility !== null && isPetFriendly !== null) ? 'login-button-div m-b' : 'login-button-div disable-btn m-b'} >
                        <button type='submit' onClick={() => props.submitVehicleInfo()}>{labelList.filter(i => i.key === 'VehicleInfo.Next')[0]?.value}</button>
                    </div>
                </div>
            </React.Fragment >
        </div >
    )
}

export default SpecialServices;