import React, { useEffect, useRef, useState } from "react";
import servicesState from './SpecialServicesState';
import { toast } from "react-toastify";
import { getLocalStorage } from "../../common/CommonMethods";
import SpecialServices from "./SpecialServices";
import { saveVehicleInfo } from "../vehicle-specification/VehicleSpecificationContainer";
import { useNavigate } from "react-router-dom";

const SpecialServicesContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const [state, setState] = useState(servicesState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));
        if (isComponentMounted.current)
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });

        let preVehicleSpecification = JSON.parse(getLocalStorage('vehicleSpecification'));

        if (preVehicleSpecification?.uId)
            setPreviousSpecialData(preVehicleSpecification);

        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // it sets the previous chosen special services 
    const setPreviousSpecialData = (specificationData) => {
        setState((prevState) => {
            return {
                ...prevState, isSpecialAbility: specificationData.isSpecialAbility,
                isPetFriendly: specificationData.isPetFriendly
            }
        });
    }

    // enables or disables the differently abled person option
    const toggleDisablityOption = (optedValue) => {
        if (optedValue === 0)
            setState((prevState) => { return { ...prevState, isSpecialAbility: false } });
        if (optedValue === 1)
            setState((prevState) => { return { ...prevState, isSpecialAbility: true } });
    }

    // enables or disables the pet friendly option
    const togglePetFriendlyOption = (optedValue) => {
        if (optedValue === 0)
            setState((prevState) => { return { ...prevState, isPetFriendly: false } });
        if (optedValue === 1)
            setState((prevState) => { return { ...prevState, isPetFriendly: true } });
    }

    // it saves the final vehicle specifications
    const submitVehicleInfo = () => {
        if (state.isPetFriendly !== null && state.isSpecialAbility !== null) {
            let vehicleSpecification = JSON.parse(getLocalStorage('vehicleSpecification'));
            vehicleSpecification.isSpecialAbility = state.isSpecialAbility;
            vehicleSpecification.isPetFriendly = state.isPetFriendly;

            saveVehicleInfo(vehicleSpecification, state.labelList, navigate, '/driver/documents');
        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Validation.ChooseAnOption')[0]?.value);
    }

    return (
        <React.Fragment>
            <SpecialServices
                state={state}
                toggleDisablityOption={toggleDisablityOption}
                togglePetFriendlyOption={togglePetFriendlyOption}
                submitVehicleInfo={submitVehicleInfo}
            />
        </React.Fragment>
    )
}

export default SpecialServicesContainer;