import React from "react";
import { Formik, Field, Form } from 'formik';
import './VehicleSpecification.css';
import FormikSelect from "../../shared-component/formik/FormikSelect";
import Select from "react-select";
import FormikInput from "../../shared-component/formik/FormikInput";

const dropdownStyle = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: "0 5px 10px #666",
        border: "1px solid #c7c7c7 !important",
        borderRadius: "0px !important",
        height: "44px !important",
    }),
    menu: base => ({
        ...base,
        marginTop: 0,
        paddingTop: 0,
        borderRadius: 0,
        boxShadow: "0 5px 10px #666",
    }),
}

const VehicleSpecification = (props) => {

    const { labelList, formValidationSchema, vehicleColorList, vehicleSpecification2Wheeler, isDeliverySelected,
        formValidationSchema2Wheeler, vehicleSpecification, isFourWheelerSelected, modelYearList, isOpen,
        vehicleBrandList, remark } = props.state;

    return (
        <div className='vehicle-main-div'>
            <Formik
                initialValues={isFourWheelerSelected ? vehicleSpecification : vehicleSpecification2Wheeler}
                validationSchema={isFourWheelerSelected ? formValidationSchema : formValidationSchema2Wheeler}
                enableReinitialize={true}
                onSubmit={fields => {
                    props.onGoingNext(fields)
                }}
            >
                {(formik) => (
                    <React.Fragment >
                        <div className='vehicle-container'>
                            <div className='spec-title-div'>
                                <h2 className='tos-title'>{labelList.filter(i => i.key === 'VehicleInfo.VehicleSpecifications')[0]?.value}</h2>
                                <label>{labelList.filter(i => i.key === 'VehicleInfo.VehicleSpecSubLabel')[0]?.value}</label>
                                {
                                    remark && <label className='specification-remark'>{labelList.filter(i => i.key === 'UserDetail.Remark')[0]?.value}
                                        : {remark}</label>
                                }
                            </div>
                            <Form noValidate>
                                <div>
                                    <div className='form-group'>
                                        <div className='brand-div'>
                                            {
                                                <div className={isOpen ? 'vehicle-brand-div open-brand' : 'vehicle-brand-div'} onClick={props.openSearchBrandMenu}>
                                                    <label className={props.state.choosenBrandLabel ? 'vehicle-brand-label' : 'vehicle-brand-label value-not-available'}>
                                                        {props.state.choosenBrandLabel ? `${props.state.choosenBrandLabel?.label}`
                                                            : labelList.filter(i => i.key === 'VehicleInfo.VehicleMaker')[0]?.value}</label>
                                                    <i className="fas fa-chevron-down"></i>
                                                </div>
                                            }
                                            {
                                                isOpen &&
                                                <Select
                                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                    onBlur={props.openSearchBrandMenu}
                                                    autoFocus
                                                    backspaceRemovesValue={false}
                                                    menuIsOpen
                                                    onChange={props.onSelectVehicleBrandHandler}
                                                    options={vehicleBrandList}
                                                    placeholder={labelList.filter(i => i.key === 'VehicleInfo.Search')[0]?.value}
                                                    tabSelectsValue={false}
                                                    styles={dropdownStyle}
                                                />
                                            }
                                            {isOpen && <i className="fas fa-search search-icon-brand"></i>}
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className='model-div'>
                                            <Field name='vehicleSubBrand' id='vehicleSubBrand' type='text' component={FormikInput} maxLength="100"
                                                placeholder={labelList.filter(i => i.key === 'VehicleInfo.VehicleModel')[0]?.value} />
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className={`${'model-year-div'} ${formik.values.model && 'value-available'}`}>
                                            <Field onChange={() => { }} option={modelYearList}
                                                name='model' id='model' type='select' component={FormikSelect} />
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className='model-div'>
                                            <Field name='plateNumber' id='plateNumber' type='text' component={FormikInput} maxLength='20'
                                                placeholder={labelList.filter(i => i.key === 'VehicleInfo.PlateNumber')[0]?.value} />
                                        </div>
                                    </div>
                                    {
                                        isFourWheelerSelected &&
                                        <div className='form-group'>
                                            <div className={`${'color-div'} ${formik.values.vehicleColorCD && 'value-available'}`}>
                                                <Field onChange={() => { }} option={vehicleColorList}
                                                    name='vehicleColorCD' id='vehicleColorCD' type='select' component={FormikSelect} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={((formik.isValid)) ? 'submit-btn-div' : 'submit-btn-div disable-btn'} >
                                    <button type='submit' >{labelList.filter(i => i.key === 'VehicleInfo.Next')[0]?.value}</button>
                                </div>
                            </Form>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default VehicleSpecification;