import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './VooySpinner.css';
import { Environment } from './Environment';
import { showApiErrorMessage } from './ApiErrorStatusCode';
import { getLocalStorage } from '../common/CommonMethods';

const url = Environment.getOtpAPIUrl();

const axiosInstance = axios.create({
    baseURL: url,
    timeout: 180000,
    headers: {
        'Accept': 'text/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

axiosInstance.defaults.headers.common['Authorization'] = '';

axiosInstance.interceptors.request.use(function (config) {

    document.body.classList.add('loading-indicator');
    const accessToken = getLocalStorage('token');
    if (accessToken)
        config.headers.Authorization = accessToken ? `bearer ${accessToken}` : '';

    return config;
},
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    document.body.classList.remove('loading-indicator');
    return response;
},
    async function (error) {

        if (error)
            return showApiErrorMessage(error, axiosInstance);

        document.body.classList.remove('loading-indicator');
        return Promise.reject(error);
    });

export { axiosInstance as otpAxios };
