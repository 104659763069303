import * as Yup from 'yup';

// Login with mobile form validation schema
const formValidationSchema = Yup.object().shape({
    typeOfService: Yup.array()
        .required(''),
});

const tosDetail = () => {
    return {
        typeOfService: [],
    }
}

// States
const tosState = (function states() {
    return {
        tosDetail: tosDetail(),
        formValidationSchema: formValidationSchema,
        tosList: [],
        labelList: [],
        serviceTypeList: []
    }
})()

export default tosState;