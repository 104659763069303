import React, { useEffect, useRef, useState } from "react";
import vehicleState, { formValidationSchema, formValidationSchema2Wheeler } from './VehicleSpecificationState';
import * as  commonService from '../../service/CommonService';
import * as documentService from '../../service/document/Document.Service';
import * as signUpService from '../../service/document/SignUpService';
import VehicleSpecification from "./VehicleSpecification";
import { getLocalStorage, removeAsyncStorage, setLocalStorage } from "../../common/CommonMethods";
import { useLocation, useNavigate } from "react-router-dom";
import { AppEnum } from "../../common/AppEnum";
import { toast } from 'react-toastify';
import { PrivateLayout } from "../../common/PrivateLayout";

// it saves the vehicle information
export const saveVehicleInfo = (vehicleSpecification, labelList, navigate, routeToNavigate) => {

    let vehicleTypeData = JSON.parse(getLocalStorage('vehicleTypeData'));
    let userServices = JSON.parse(getLocalStorage('typeOfService'));

    if (vehicleSpecification?.uId) {
        delete vehicleSpecification.remark;
        delete vehicleSpecification.vehicleBrandName;
    }
    let vehicleSpecificationModal = { ...vehicleSpecification, ...vehicleTypeData };

    let userVehicleDTO = {
        userVehicle: { ...vehicleSpecificationModal },
        userServices: userServices,
    };
    signUpService.saveVehicleInfo(userVehicleDTO)
        .then((response) => {
            if (response) {
                let toaster = labelList.filter(i => i.key === 'Validation.VehicleInfoIsSaved')[0].value;
                toast.success(toaster);
                removeAsyncStorage();
                vehicleSpecification?.uId ? PrivateLayout() : navigate(routeToNavigate);
            }
        })
}

const VehicleSpecificationContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState(vehicleState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(getLocalStorage('languageApiLabels'));

        if (isComponentMounted.current) {
            let vehicleTypeData = JSON.parse(getLocalStorage('vehicleTypeData'));
            let isDeliverySelected = (JSON.parse(getLocalStorage('typeOfService')))?.includes(AppEnum.ServiceTypeId.Ride) ? false : true;
            let validationSchema = formValidationSchema(masterApiData);
            let validationSchemaMotorcycle = formValidationSchema2Wheeler(masterApiData);

            setState((prevState) => {
                return {
                    ...prevState, labelList: masterApiData, isDeliverySelected: isDeliverySelected,
                    isFourWheelerSelected: vehicleTypeData?.vehicleTypeCode === AppEnum.VehicleTypes.Motorcycle ? false : true,
                    formValidationSchema: validationSchema, formValidationSchema2Wheeler: validationSchemaMotorcycle
                }
            });
            getVehicleColorsList();
            getModelYearList(masterApiData);
            getVehicleBrandList(vehicleTypeData?.vehicleTypeId);

            if (location.state?.isUpdateFlow)
                setPreviousSpecificationData(vehicleTypeData);
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // it sets all the vehicle specification data to the state
    const setPreviousSpecificationData = (vehicleTypeData) => {
        let preVehicleSpecification = JSON.parse(getLocalStorage('vehicleSpecification'));
        let specifications = state.vehicleSpecification;
        specifications = { ...preVehicleSpecification };

        vehicleTypeData.vehicleTypeCode === AppEnum.VehicleTypes.Motorcycle ?
            setState((prevState) => { return { ...prevState, vehicleSpecification2Wheeler: specifications } })
            : setState((prevState) => { return { ...prevState, vehicleSpecification: specifications } });

        let brandName = {};
        brandName.label = preVehicleSpecification.vehicleBrandName;
        brandName.uId = preVehicleSpecification.vehicleBrandUId;
        brandName.value = preVehicleSpecification.vehicleBrandUId;
        setState((prevState) => { return { ...prevState, choosenBrandLabel: brandName, remark: preVehicleSpecification.remark } });
    }

    // It fetches the list of vehicle colors
    const getVehicleColorsList = () => {
        let languageCode = getLocalStorage('languageCode');
        commonService.getConfigByGroupId(AppEnum.ConfigCDGroupId.VEHICLE_COLOR, languageCode).then((response) => {
            if (response)
                setState((prevState) => {
                    return {
                        ...prevState,
                        vehicleColorList: [{ value: '', label: prevState.labelList.filter(i => i.key === 'VehicleInfo.VehicleColor')[0]?.value }, ...response]
                    }
                });
        })
    }

    // It fetches the model year list
    const getModelYearList = (masterApiData) => {
        var currentYear = new Date().getFullYear();
        var choosenTypeOfService = JSON.parse(localStorage.getItem('typeOfService'));
        let year;

        if (choosenTypeOfService?.includes(AppEnum.ServiceTypeId.Ride))
            year = currentYear - Number(masterApiData.filter(i => i.key === 'VehicleInfo.RideServiceYear')[0]?.value);
        else
            year = currentYear - Number(masterApiData.filter(i => i.key === 'VehicleInfo.DeliveryServiceYear')[0]?.value);

        var arr = [];
        for (year; year <= currentYear; year++) {
            var dynamicObject = {
                value: year,
                label: year.toString()
            }
            arr.push(dynamicObject);
        }
        setState((prevState) => {
            return {
                ...prevState,
                modelYearList: [{ value: '', label: prevState.labelList.filter(i => i.key === 'VehicleInfo.ModelYear')[0]?.value }, ...arr]
            }
        });
    }

    // It fetches the vehicle brand list 
    const getVehicleBrandList = (vehicleTypeId) => {
        documentService.getVehicleMaker(vehicleTypeId).then(res => {
            if (res)
                setState((prevState) => { return { ...prevState, vehicleBrandList: [...res] } });
        })
    }

    // selects the choosen vehicle brand and sets to the state
    const onSelectVehicleBrandHandler = (choosenBrandLabel) => {
        openSearchBrandMenu();
        setState((prevState) => { return { ...prevState, choosenBrandLabel: choosenBrandLabel } });
    };

    // it opens the brand menu
    const openSearchBrandMenu = () => {
        setState((prevState) => { return { ...prevState, isOpen: !state.isOpen } });
    }

    // saves the filled vehicle information
    const onGoingNext = (vehicleSpecification) => {
        vehicleSpecification.vehicleBrandUId = state.choosenBrandLabel.uId;

        let checkVehicleData = {
            numberPlate: vehicleSpecification.plateNumber,
            userVehicleUId: vehicleSpecification?.uId ? vehicleSpecification?.uId : ''
        }
        commonService.validateVehicleData(checkVehicleData).then((res) => {
            if (res) {
                if (state.isDeliverySelected) {
                    saveVehicleInfo(vehicleSpecification, state.labelList, navigate, '/driver/documents');
                }
                else {
                    setLocalStorage('vehicleSpecification', JSON.stringify(vehicleSpecification));
                    navigate('/driver/special-services');
                }
            }
        })
    }

    return (
        <React.Fragment>
            <VehicleSpecification
                state={state}
                onGoingNext={onGoingNext}
                onSelectVehicleBrandHandler={onSelectVehicleBrandHandler}
                openSearchBrandMenu={openSearchBrandMenu}
            />
        </React.Fragment>
    )
}

export default VehicleSpecificationContainer;