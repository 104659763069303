import React, { useEffect, useRef, useState } from "react";
import LoginWithEmail from "./LoginWithEmail";
import loginState, { formValidationSchema } from './LoginState';
import * as  commonService from '../../service/CommonService';
import { AppEnum } from "../../common/AppEnum";
import * as  loginService from '../../service/Login.Service';
import * as commonMethods from '../../common/CommonMethods';
import { useNavigate } from "react-router-dom";
import { PrivateLayout } from "../../common/PrivateLayout";

const LoginWithEmailContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const [state, setState] = useState(loginState);
    const formikRef = useRef();

    // // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        commonMethods.removeAsyncStorage();
        let validationSchema = formValidationSchema(masterApiData);
        setState((prevState) => { return { ...prevState, formValidationSchema: validationSchema } });

        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
            getLanguageList();
        }

        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // // retrives the language list
    const getLanguageList = () => {
        commonService.getlanguageDDO()
            .then(res => {
                if (res) {
                    let languageCode = localStorage.getItem('languageCode')
                    if (languageCode === AppEnum.LanguageCodeCD.mexican)
                        res.reverse();

                    setState((prevState) => { return { ...prevState, languageList: res } })
                }
            })
    }

    // This method is used to change apps language via api
    const onChangeLanguageListHandler = (languageCode) => {
        let language = '';
        commonMethods.setLocalStorage("languageCode", languageCode);

        if (languageCode === AppEnum.LanguageCodeCD.mexican) {
            language = AppEnum.LanguageListCD.Spanish;
            commonMethods.setLocalStorage("language", language);
        }
        else if (languageCode === AppEnum.LanguageCodeCD.us) {
            language = AppEnum.LanguageListCD.English;
            commonMethods.setLocalStorage("language", language);
        }

        commonService.getLanguageLabels(language, AppEnum.LanguageApiUserInterfaceCD.Partner_Registration_Web)
            .then(res => {
                if (res) {
                    commonMethods.setLocalStorage("languageApiLabels", JSON.stringify(res));

                    setState((prevState) => { return { ...prevState, labelList: res } });

                    if (formikRef.current) {
                        formikRef.current.resetForm();

                        let validationSchema = formValidationSchema(res);
                        setState((prevState) => { return { ...prevState, formValidationSchema: validationSchema } })
                    }
                }
            })
    }

    // // Routes to the signup page
    const signUp = () => {
        navigate('/create-account');
    }

    // // Routes to login with email page 
    const onChangeLoginOptionHandler = () => {
        navigate('/login')
    }

    // // Retrives the otpUId and routes to the otp page
    const onLogin = (loginDetails) => {
        loginDetails.languageCode = localStorage.getItem('languageCode');

        loginService.validateLoginWithEmail(loginDetails.userName, AppEnum.PartnerTypeCD.driver, loginDetails.languageCode)
            .then((response) => {
                if (response) {

                    let formData = new FormData();
                    formData.append('client_secret', AppEnum.FormDataEmailCD.client_secret);
                    formData.append('client_id', AppEnum.FormDataEmailCD.client_id);
                    formData.append('grant_type', AppEnum.FormDataEmailCD.grant_type);
                    formData.append('username', loginDetails.userName);
                    formData.append('password', loginDetails.password);
                    formData.append('languagecode', loginDetails.languageCode);

                    loginService.login(formData)
                        .then(res => {
                            if (res.access_token) {
                                commonMethods.setLocalStorage('refresh_token', res.refresh_token);
                                commonMethods.setLocalStorage('token', res.access_token);
                                PrivateLayout();
                            }
                        });
                }
            })
    }

    return (
        <React.Fragment>
            <LoginWithEmail
                state={state}
                onChangeLanguageListHandler={onChangeLanguageListHandler}
                onChangeLoginOptionHandler={onChangeLoginOptionHandler}
                signUp={signUp}
                onLogin={onLogin}
                formikRef={formikRef}
            />
        </React.Fragment>
    )
}

export default LoginWithEmailContainer;