import React from 'react';
import { Formik, Field, Form } from 'formik';
import './ServiceType.css';
import TypeOfServiceBanner from '../../assets/banners/TypeOfServiceBanner.png'
import FormikMultiSelect from '../../shared-component/formik/FormikMultiSelect';

// tos stands for type of service
const ServiceType = (props) => {

    const { labelList, tosDetail, formValidationSchema, tosList, serviceTypeList } = props.state;

    return (
        <div className='login-main-div'>
            <Formik
                initialValues={tosDetail}
                enableReinitialize='true'
                innerRef={props.formikRef}
                validationSchema={formValidationSchema}
                onSubmit={fields => {
                    props.onGoingNext(fields)
                }}
            >
                {(formik) => (
                    <React.Fragment >
                        <div className='login-container input-focus'>
                            <h2 className='tos-title'>{labelList.filter(i => i.key === 'UserDetail.TypeOfService')[0]?.value}</h2>
                            <Form noValidate>
                                <div className='form-group'>
                                    <div className='tos-div'>
                                        <label className='tos-label' htmlFor='typeOfService'>{labelList.filter(i => i.key === 'UserDetail.ServiceToPerform')[0]?.value}</label>

                                        <Field options={tosList} onChange={props.selectOptions} placeholder={labelList.filter(i => i.key === 'UserDetail.ToSelectLabel')[0]?.value}
                                            name='typeOfService' id='typeOfService' type='select' component={FormikMultiSelect}
                                            value={tosList.filter(option => {
                                                return option.value === serviceTypeList.filter(i => i === option.serviceTypeId)[0]
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='tos-banner-div'>
                                    <img src={TypeOfServiceBanner} className='tos-image-width' alt='Type Of Service' />
                                </div>
                                <div className={(formik.isValid && serviceTypeList.length) ? 'tos-button-div' : 'tos-button-div disable-btn'} >
                                    <button type='submit' >{labelList.filter(i => i.key === 'UserDetail.Next')[0]?.value}</button>
                                </div>
                            </Form>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )

}

export default ServiceType;