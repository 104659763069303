import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import DocumentContainer from '../component/active-account-flow/document-component/DocumentContainer';
import MyRidesContainer from '../component/active-account-flow/my-rides/MyRidesContainer';
import MyShipmentsContainer from '../component/active-account-flow/my-shipments/MyShipmentsContainer';
import ProfileContainer from '../component/active-account-flow/profile/ProfileContainer';
import PersonalInfoContainer from '../component/create-account/PersonalInfoContainer';
import VehicleDocumentContainer from '../component/driver-documents/DriverDocumentsContainer';
import ForgotPassContainer from '../component/forgot-password/ForgotPassContainer';
import ResetPassContainer from '../component/forgot-password/ResetPassContainer';
import ActiveAccountDashboard from '../component/layout-outlet/ActiveAccountDashboard';
import AuthLayoutComponent from '../component/layout-outlet/AuthLayoutComponent';
import LayoutComponent from '../component/layout-outlet/LayoutComponent';
import LoginWithEmailContainer from '../component/login/LoginWithEmailContainer';
import LoginWithMobileContainer from '../component/login/LoginWithMobileContainer';
import ServiceTypeContainer from '../component/service-type/ServiceTypeContainer';
import SpecialServicesContainer from '../component/special-services/SpecialServicesContainer';
import ExamContainer from '../component/training-and-exam/exam/ExamContainer';
import ExamHome from '../component/training-and-exam/home/ExamHome';
import TrainingContainer from '../component/training-and-exam/training/TrainingContainer';
import TwoWheelerHome from '../component/training-and-exam/two-wheeler-home/TwoWheelerHome';
import VehicleSpecificationContainer from '../component/vehicle-specification/VehicleSpecificationContainer';
import VerifyOtpContainer from '../component/verify-otp/VerifyOtpContainer';
import VehicleTypesContainer from '../component/vehicle-types/VehicleTypesContainer';
import DriverHomeContainer from '../component/driver-home/DriverHomeContainer';
import ThankYouForExam from '../component/training-and-exam/exam/ThankYouForExam';

const AppRouting = () => {

    let routes = (
        <Routes>

            {/* unauthorized routes */}
            <Route path="/" element={<LayoutComponent />}>
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="/login-with-email" element={<LoginWithEmailContainer />} />
                <Route path="/login" element={<LoginWithMobileContainer />} />
                <Route path="/forgot-password" element={<ForgotPassContainer />} />
                <Route path="/reset-password" element={<ResetPassContainer />} />
                <Route path="/verify-otp" element={<VerifyOtpContainer />} />
                <Route path='/create-account' element={<PersonalInfoContainer />} />
            </Route>

            {/* authorized routes */}
            <Route path="/driver" element={<AuthLayoutComponent />}>
                <Route path='/driver/service-type' element={<ServiceTypeContainer />} />
                <Route path='/driver/vehicle-specification' element={<VehicleSpecificationContainer />} />
                <Route path='/driver/vehicle-types' element={<VehicleTypesContainer />} />
                <Route path='/driver/special-services' element={<SpecialServicesContainer />} />
                <Route path='/driver/documents' element={<VehicleDocumentContainer />} />
                <Route path='/driver/training' element={<TrainingContainer />} />
                <Route path='/driver/exam' element={<ExamContainer />} />
                <Route path='/driver/home' element={<ExamHome />} />
                <Route path='/driver/exam/home' element={<TwoWheelerHome />} />
                <Route path='/driver/thank-you' element={<ThankYouForExam />} />
                <Route path='/driver/thank-you-for-signing-up' element={<DriverHomeContainer />} />
            </Route>

            {/* active account routes */}
            <Route path="/active-driver" element={<ActiveAccountDashboard />}>
                <Route path='/active-driver/menu/profile' element={<ProfileContainer />} />
                <Route path='/active-driver/menu/document' element={<DocumentContainer />} />
                <Route path='/active-driver/menu/my-rides' element={<MyRidesContainer />} />
                <Route path='/active-driver/menu/my-shipments' element={<MyShipmentsContainer />} />
            </Route>

            <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
    )

    return (
        <React.Fragment>
            {routes}
        </React.Fragment>
    );
}

export default AppRouting;