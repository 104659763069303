import { logisticAxios } from "../core/LogisticApiInterceptor";
import { tokenAxios } from "../core/TokenAPIInterceptor";

export async function login(loginDetail) {
    const response = await tokenAxios.post('connect/token', loginDetail);
    return response?.data;
}

// forget password
export async function forgotPassword(forgotPasswordDetails) {
    const response = await tokenAxios.post('user/forgot-password', forgotPasswordDetails);
    return response?.data;
}

// resetPassword
export async function changePassword(newPasswordDetails) {
    const response = await tokenAxios.post('user/reset-password', newPasswordDetails);
    return response?.data;
}

// sends otp for during forgot password
export async function resendOTP(forgotPasswordDetails) {
    const response = await tokenAxios.post('user/forgot-password', forgotPasswordDetails);
    return response?.data;
}

// logouts the partner  
export async function logout(logoutConfig) {
    const response = await logisticAxios.post(`Logout`, logoutConfig);
    return response?.data;
}

export async function validateLoginWithEmail(email, role, languageCode) {
    const response = await tokenAxios.get(`user/validate-email-login/${email}/${role}/${languageCode}`);
    return response?.data;
}