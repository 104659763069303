import React, { Fragment } from 'react';
import './DriverMenu.css';

const DriverMenu = (props) => {

    const { labelList, isDocumentSectionSelected, isMyRidesSectionSelected,
        isShipmentSectionSelected, isProfileSectionSelected } = props.state;

    return (
        <Fragment>
            <div className='menu-label-div'>
                <label onClick={props.onClickProfileHandler} className={isProfileSectionSelected ? 'section-selected' : ''}>
                    {labelList.filter(i => i.key === 'Menu.Profile')[0]?.value}
                </label>
                <label onClick={props.onClickDocumentHandler} className={isDocumentSectionSelected ? 'section-selected' : ''}>
                    {labelList.filter(i => i.key === 'Menu.Documents')[0]?.value}
                </label>
                <label onClick={props.onClickMyRidesHandler} className={isMyRidesSectionSelected ? 'section-selected' : ''}>
                    {labelList.filter(i => i.key === 'Menu.MyRides')[0]?.value}
                </label>
                <label onClick={props.onClickShipmentHandler} className={isShipmentSectionSelected ? 'section-selected' : ''}>
                    {labelList.filter(i => i.key === 'Menu.MyShipments')[0]?.value}
                </label>
            </div>
        </Fragment>
    )
}

export default DriverMenu;