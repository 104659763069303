
const answer = () => {
    return {
        userTypeCD: "",
        sessionId: "",
        examUId: "",
        appCD: "",
        examTitle: "",
        defaultLangageCD: "",
        startTime: "",
        endTime: "",
        examSubmissionDetail: {
            uId: "",
            questionUId: "",
            answerUIds: [],
            isAttempted: true
        }
    }
}

// States
const states = (function states() {
    return {
        labelList: [],
        examData: {},
        questionDisplayOrder: 1,
        totalExamDuration: 0,
        questions: [],
        answerUId: null,
        answerUIds: [],
        answer: answer()
    }
})()

export default states;