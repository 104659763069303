import * as Yup from 'yup';
// import { translateViaApi } from '../../../../common/translator';

// Document form validation schema
export const formValidationSchemaSsn = (res) => Yup.object().shape({
    curp: Yup.string()
        .required(res?.filter(i => i.key === 'PersonalDocument.SSNInvalidError')[0]?.value)
        .min(18, res?.filter(i => i.key === 'PersonalDocument.SSNInvalidError')[0]?.value)
        .max(18, res?.filter(i => i.key === 'PersonalDocument.SSNInvalidError')[0]?.value),
});

const curpDetail = () => {
    return {
        curp: '',
    }
}

// States
const documentState = (function states() {
    return {
        curpDetail: curpDetail(),
        formValidationSchemaSsn: formValidationSchemaSsn(),
        labelList: [],
        personalDocumentList: [],
        vehicleDocumentList: [],
        isDocumentChosen: false,
        chosenDocumentConfig: {},
        documentFormData: null,
        isChosenDocumentUploaded: false,
        sureToLogout: false,
        isExamCompleted: false,
        examStatus: '',
        isVehicleDocUploaded: true,
        isPersonalDocUploaded: true,
        vehicleType: {},
        isRideService: false,
        attemptLeft: 0
    }
})()

export default documentState;