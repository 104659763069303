// States
const myRidesState = (function states(props) {
    return {
        labelList: [],
        rideList: [],
        startPageNo: 1,
        pageSize: null,
    }
})()

export default myRidesState;