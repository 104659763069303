import * as Yup from 'yup';

// Regular expressions for validation
const alpha = /^[A-Za-z0-9]+$/;
const carmodel = /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/;

// Document form validation schema
export const formValidationSchema = (res) => Yup.object().shape({
    vehicleSubBrand: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.VehicleModelIsRequired')[0]?.value).nullable()
        .matches(carmodel, { message: res?.filter(i => i.key === 'Validation.VehicleModelIsInvalid')[0]?.value, excludeEmptyString: true }),
    model: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.ModelYearIsRequired')[0]?.value).nullable(),
    plateNumber: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.PlateNumberIsRequired')[0]?.value).nullable()
        .matches(alpha, { message: res?.filter(i => i.key === 'Validation.PlateNumberIsInvalid')[0]?.value, excludeEmptyString: true }),
    vehicleColorCD: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.VehicleColorIsRequired')[0]?.value).nullable(),
});

// Document form validation schema
export const formValidationSchema2Wheeler = (res) => Yup.object().shape({
    vehicleSubBrand: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.VehicleModelIsRequired')[0]?.value).nullable()
        .matches(carmodel, { message: res?.filter(i => i.key === 'Validation.VehicleModelIsInvalid')[0]?.value, excludeEmptyString: true }),
    model: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.ModelYearIsRequired')[0]?.value).nullable(),
    plateNumber: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.PlateNumberIsRequired')[0]?.value).nullable()
        .matches(alpha, { message: res?.filter(i => i.key === 'Validation.PlateNumberIsInvalid')[0]?.value, excludeEmptyString: true }),
});

const vehicleSpecification = () => {
    return {
        vehicleBrandUId: '',
        // model 
        vehicleSubBrand: '',
        // model year
        model: '',
        vehicleColorCD: '',
        plateNumber: ''
    }
}

const vehicleSpecification2Wheeler = () => {
    return {
        vehicleBrandUId: '',
        vehicleSubBrand: '',
        model: '',
        plateNumber: ''
    }
}

// States
const vehicleState = (function states() {
    return {
        vehicleSpecification2Wheeler: vehicleSpecification2Wheeler(),
        vehicleSpecification: vehicleSpecification(),
        formValidationSchema: formValidationSchema(),
        formValidationSchema2Wheeler: formValidationSchema2Wheeler(),
        labelList: [],
        vehicleColorList: [],
        vehicleTypeList: [],
        vehicleBrandList: [],
        modelYearList: [],
        choosenBrandLabel: '',
        isDeliverySelected: false,
        isFourWheelerSelected: null,
        isOpen: false,
        remark: ''
    }
})()

export default vehicleState;