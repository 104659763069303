import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import * as  commonService from './service/CommonService';
import { createHashHistory } from 'history';
import { AppEnum } from './common/AppEnum';
import AppRouting from './routing/AppRouting';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from './common/CommonMethods';

const AppContainer = () => {

    const [isLanguageDataAvailable, setIsLanguageDataAvailable] = useState(false);

    useEffect(() => {
        let language = getLocalStorage('language');
        if (!language)
            fetchLabelData(AppEnum.LanguageListCD.Spanish);
        else
            fetchLabelData(language);
    }, [])

    // it fetches the labels from master api 
    const fetchLabelData = (language) => {
        commonService.getLanguageLabels(language, AppEnum.LanguageApiUserInterfaceCD.Partner_Registration_Web)
            .then(res => {

                if (res) {
                    setLocalStorage("language", language);

                    if (language === AppEnum.LanguageListCD.Spanish)
                        setLocalStorage("languageCode", AppEnum.LanguageCodeCD.mexican);
                    else if (language === AppEnum.LanguageListCD.English)
                        setLocalStorage("languageCode", AppEnum.LanguageCodeCD.us);

                    setLocalStorage("languageApiLabels", JSON.stringify(res));

                    setIsLanguageDataAvailable(true);
                    let token = getLocalStorage('token');
                    removeLocalStorage('isTokenRefreshed');
                    const history = createHashHistory();
                    // if (token)
                    //     history.push({ pathname: '/userdetail/layout/' })
                }
            })
    }

    return (
        <div>
            {isLanguageDataAvailable && <AppRouting />}
        </div>
    )
}

export default AppContainer;