// import { translateViaApi } from "../../../../../common/translator";
import * as Yup from 'yup';

const alpha = /^[a-zA-Zñáéíóúü][a-zA-Zñáéíóúü\s]*$/;
const regexEmail = /^(?!\.)(?!.*\.$)(?!.*?\.\.)(?!.*?\.@)([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
const regexMobileNumber = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const regexForAcceptAllExceptWhitespace = /^[^\s].*/;

// User detail form validation schema
export const formValidationSchema = (res, minMobileLength, maxMobileLength) => {
    return Yup.object().shape({
        mobileNo: Yup.string()
            .matches(regexMobileNumber, { message: res?.filter(i => i.key === 'Validation.MobileNumberInvalid')[0]?.value, excludeEmptyString: false })
            .required(res?.filter(i => i.key === 'Validation.MobileNumberIsRequired')[0]?.value)
            .min(minMobileLength, res?.filter(i => i.key === 'Validation.MobileNumberInvalid')[0]?.value)
            .max(maxMobileLength, res?.filter(i => i.key === 'Validation.MobileNumberInvalid')[0]?.value),
        firstName: Yup.string()
            .matches(alpha, { message: res?.filter(i => i.key === 'Validation.NameIsInvalid')[0]?.value, excludeEmptyString: true })
            .required(res?.filter(i => i.key === 'Validation.NameIsRequired')[0]?.value)
            .max(50),
        motherLastName: Yup.string()
            .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.MotherLastNameIsInvalid')[0]?.value, excludeEmptyString: true })
            .required(res?.filter(i => i.key === 'Validation.MotherLastNameIsRequired')[0]?.value)
            .max(50),
        fatherLastName: Yup.string()
            .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.FatherLastNameIsInvalid')[0]?.value, excludeEmptyString: true })
            .required(res?.filter(i => i.key === 'Validation.FatherLastNameIsRequired')[0]?.value)
            .max(50),
        email: Yup.string()
            .matches(regexEmail, { message: res?.filter(i => i.key === 'Validation.EmailIsInvalid')[0]?.value, excludeEmptyString: false })
            .required(res?.filter(i => i.key === 'Validation.EmailIsRequired')[0]?.value),
    });
}

const profileInfo = () => {
    return {
        firstName: '',
        fatherLastName: '',
        motherLastName: '',
        email: '',
        mobileNo: '',
        genderCD: '',
        dc: ''
    }
}

// States
const profileState = (function states(props) {
    return {
        labelList: [],
        profileInfo: profileInfo(),
        genderList: [],
        currentProfileImageURL: null,
        currentProfileInfo: {},
        formValidationSchema: formValidationSchema(),
        partnerProfileResponse: {},
        isEditEmail: false,
        isEditMobile: false,
        otpUId: '',
        otp: '',
        dialCode: '',
        mobileNoMaxLength: null,
        partnerUId: null
    }
})()

export default profileState;