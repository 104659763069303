import './ExamHome.css';
import React, { useEffect, useState } from 'react';
import BodyVooyBanner from '../../../assets/exam-images/BodyVooyBanner.png';
import MobileBanner from '../../../assets/exam-images/MobileBanner.png';
import VooyRides from '../../../assets/exam-images/VooyRides.png';
import VooyDelivery from '../../../assets/exam-images/VooyDelivery.png';
import ExamHomeState from './ExamHomeState';
import { getLocalStorage } from '../../../common/CommonMethods';
import { useNavigate } from 'react-router-dom';

const ExamHome = () => {

    const [state, setState] = useState(ExamHomeState);
    const navigate = useNavigate();

    useEffect(() => {
        let result = JSON.parse(getLocalStorage('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: result } });
        window.scrollTo(0, 0);
    }, [])

    // It routes to the course/training page
    const startCourse = () => {
        navigate('/driver/training');
    }

    const { labelList } = state;

    return (
        <React.Fragment>
            <div className="sub-header-div">
                <label>{labelList.filter(i => i.key === "Phase3.SubHeaderLabel")[0]?.value}</label>
            </div>
            <div className="mission-main-div">
                <div className="about-us">
                    <div>
                        <label>{labelList.filter(i => i.key === "Phase3.AboutUsLable")[0]?.value}</label>
                        <p>{labelList.filter(i => i.key === "Phase3.AboutUsPart1")[0]?.value}</p>
                        <p>{labelList.filter(i => i.key === "Phase3.AboutUsPart2")[0]?.value}
                            {labelList.filter(i => i.key === "Phase3.AboutUsPart3")[0]?.value}
                        </p>
                    </div>
                    <div className="margin-auto">
                        <img src={BodyVooyBanner} alt="BodyVooyBanner" />
                    </div>
                </div>
                <div className="mission-vision-objective-div">
                    <div className="about-mission-div">
                        <label>{labelList.filter(i => i.key === "Phase3.MissionTitle")[0]?.value}</label>
                        <p>{labelList.filter(i => i.key === "Phase3.MissionInfo")[0]?.value}</p>
                    </div>
                    <div className="about-mission-div">
                        <label>{labelList.filter(i => i.key === "Phase3.VisionTitle")[0]?.value}</label>
                        <p>{labelList.filter(i => i.key === "Phase3.VisionInfo")[0]?.value}</p>
                    </div>
                    <div className="about-mission-div">
                        <label>{labelList.filter(i => i.key === "Phase3.ValueTitle")[0]?.value}</label>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ValueInfo1")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ValueInfo2")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ValueInfo3")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ValueInfo4")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ValueInfo5")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ValueInfo6")[0]?.value}</p>
                        <p><i className="fas fa-circle"></i>{labelList.filter(i => i.key === "Phase3.ValueInfo7")[0]?.value}</p>
                    </div>
                </div>
            </div>
            <div className="services-div">
                <label>{labelList.filter(i => i.key === "Phase3.ServicesLabel")[0]?.value}</label>
            </div>
            <div className="ride-delivery-div">
                <div className="rides-div">
                    <img src={VooyRides} alt="VooyRides" />
                    <div className="i-p-div"><i className="fas fa-circle"></i>
                        <p>{labelList.filter(i => i.key === "Phase3.RideInfo1")[0]?.value}</p>
                    </div>
                    <div className="i-p-div"><i className="fas fa-circle"></i>
                        <p>{labelList.filter(i => i.key === "Phase3.RideInfo2")[0]?.value}</p>
                    </div>
                    <div className="i-p-div"><i className="fas fa-circle"></i>
                        <p>{labelList.filter(i => i.key === "Phase3.RideInfo3")[0]?.value}</p>
                    </div>
                </div>
                <div className="mobile-banner">
                    <img src={MobileBanner} alt="VooyMobileImage" />
                </div>
                <div className="delivery-div">
                    <img src={VooyDelivery} alt="VooyDelivery" />
                    <div className="i-p-div"> <i className="fas fa-circle"></i>
                        <p>{labelList.filter(i => i.key === "Phase3.DeliveryInfo1")[0]?.value}</p>
                    </div>
                    <div className="i-p-div"> <i className="fas fa-circle"></i><p>
                        {labelList.filter(i => i.key === "Phase3.DeliveryInfo2")[0]?.value}</p>
                    </div>
                    <div className="i-p-div">  <i className="fas fa-circle"></i><p>
                        {labelList.filter(i => i.key === "Phase3.DeliveryInfo3")[0]?.value}</p>
                    </div>
                </div>
            </div>
            <div className="start-button-div">
                <button onClick={() => startCourse()}>{labelList.filter(i => i.key === "Phase3.StartCourseButton")[0]?.value}</button>
            </div>
            <div className="footer-line-div">
                <hr />
            </div>
        </React.Fragment>
    )
}

export default ExamHome;