import React from "react";
import { Formik, Field, Form } from 'formik';
import './PersonalInfoModule.css';
import * as commonMethods from '../../common/CommonMethods';
import Red from '../../assets/images/Red.png'
import Yellow from '../../assets/images/Yellow.png'
import Green from '../../assets/images/Green.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { AppEnum } from "../../common/AppEnum";
import FormikSelect from "../../shared-component/formik/FormikSelect";
import FormikCheckbox from "../../shared-component/formik/FormikCheckbox";
import FormikInput from "../../shared-component/formik/FormikInput";
import Tooltip from 'react-bootstrap/Tooltip';
import { Button } from "react-bootstrap";

const PersonalInfo = (props) => {

    const { labelList, genderList, personalInfo, countryList, formValidationSchema, mobileNoMaxLength,
        isAgreementAccepted, isPasswordStrength, passwordStrength, remark, stateList, cityList } = props.state;
    let languageCode = commonMethods.getLocalStorage('languageCode');

    return (
        <div className='personal-main-div'>
            <Formik
                initialValues={personalInfo}
                enableReinitialize={true}
                validationSchema={formValidationSchema}
                onSubmit={fields => {
                    props.onGoingNext(fields);
                }}
            >
                {(formik) => (
                    <React.Fragment >
                        <div className='personal-container'>
                            <div>
                                <h2 className='login-title'>{labelList.filter(i => i.key === 'Login.CreateAccount')[0]?.value}</h2>
                                {
                                    remark &&
                                    <label className='rejected-remark'>{labelList.filter(i => i.key === 'UserDetail.Remark')[0]?.value} : {remark}</label>
                                }
                                <Form noValidate autoComplete="off">
                                    <div className={`${'mobile-no-div'} ${props.state.countryCode && 'value-available'}`}>
                                        <Field value={props.state.countryCode} option={countryList}
                                            disabled={true} name='countryUId' id='countryUId' type='select' component={FormikSelect} />

                                        <span className='mobile-no-span'>
                                            <Field maxLength={mobileNoMaxLength} name='mobileNo' placeholder={labelList.filter(i => i.key === 'VehicleInfo.MobileNumberLabel')[0]?.value}
                                                disabled={(remark || props.state.isNewRegister) ? true : false} id='mobileNo' type='tel' onKeyDown={commonMethods.updateNumber} component={FormikInput} />
                                        </span>
                                    </div>
                                    <div className="input-sub-div">
                                        <Field name="firstName" placeholder={labelList.filter(i => i.key === 'UserDetail.Name')[0]?.value}
                                            maxLength='50' id="firstName" type="text" component={FormikInput} />
                                    </div>
                                    <div className='last-name-div'>
                                        <div className="input-sub-div mother-last-name">
                                            <Field name="fatherLastName" placeholder={labelList.filter(i => i.key === 'UserDetail.PaternalSurnamePlaceHolder')[0]?.value}
                                                maxLength='50' id="fatherLastName" type="text" component={FormikInput} />
                                        </div>

                                        <div className="input-sub-div father-last-name ">
                                            <Field name="motherLastName" placeholder={labelList.filter(i => i.key === 'UserDetail.MaternalSurnamePlaceHolder')[0]?.value}
                                                maxLength='50' id="motherLastName" type="text" component={FormikInput} />
                                        </div>
                                    </div>
                                    <div className={`${"input-sub-div"} ${formik.values.genderCD && 'value-available'}`}>
                                        <Field name="genderCD" placeholder={labelList.filter(i => i.key === 'UserDetail.Gender')[0]?.value}
                                            disabled={remark ? true : false} id="genderCD" type="select" onChange={() => { }} option={genderList} component={FormikSelect} />
                                    </div>

                                    <div className="input-sub-div">
                                        <Field name="email" placeholder={labelList.filter(i => i.key === 'UserDetail.EmailLabel')[0]?.value}
                                            disabled={remark ? true : false} id="email" type="text" component={FormikInput} />
                                    </div>
                                    <div className='form-group'>
                                        <div className={`${'state-div'} ${formik.values.stateUId && 'value-available'}`}>
                                            <Field name="stateUId" id="stateUId" type="select" onChange={props.onChangeStateHandler} option={stateList}
                                                component={FormikSelect} />
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className={`${'city-div'} ${formik.values.cityUId && 'value-available'}`}>
                                            <Field name="cityUId" id="cityUId" type="select" option={cityList} onChange={() => { }}
                                                component={FormikSelect} />
                                        </div>
                                    </div>
                                    {
                                        !remark &&
                                        < div className="input-sub-div">
                                            <Field name="referredBy" placeholder={labelList.filter(i => i.key === 'UserDetail.ReferralCode')[0]?.value}
                                             autoComplete="off"   maxLength='10' id="referredBy" type="text" component={FormikInput} />
                                        </div>
                                    }
                                    {
                                        !remark &&
                                        <div>
                                            <div className={isPasswordStrength ? "pass-m-div pass-div" : "pass-div"}>
                                                <Field name="password" id="password" placeholder={labelList.filter(i => i.key === 'UserDetail.PasswordLabel')[0]?.value} maxLength={15}
                                                    disabled={remark ? true : false} type="password" onChange={(event) => props.onchangePasswordHandler(event)} component={FormikInput} />
                                                <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'password')}></i>
                                            </div>
                                            {
                                                isPasswordStrength &&
                                                <span className={passwordStrength.className}>
                                                    {
                                                        passwordStrength.strength === "Excellent" ?
                                                            <div className='pass-strength-bar'>
                                                                <div className='pass-tooltip-div'>
                                                                    {labelList.filter(i => i.key === 'Validation.PasswordStrengthExcellent')[0].value}

                                                                    <OverlayTrigger
                                                                        key='excellentPassword'
                                                                        id='excellentPassword'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip className="strong-password">
                                                                                {labelList.filter(i => i.key === 'Validation.PasswordStrengthExcellentToolTip')[0].value}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Button variant="light" type="button" onClick={() => { }}>
                                                                            <i className="fas fa-info-circle pass-tooltip-div i-strong" data-tip data-for='excellentPassword' ></i>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <img src={Green} alt="Excellent Password" width="100%" />
                                                            </div>
                                                            :
                                                            passwordStrength.strength === "Medium" ?
                                                                <div className='pass-strength-bar'>
                                                                    <div className='pass-tooltip-div'>
                                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthMedium')[0].value}

                                                                        <OverlayTrigger
                                                                            key='mediumPassword'
                                                                            id='mediumPassword'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip className="medium-password">
                                                                                    {labelList.filter(i => i.key === 'Validation.PasswordStrengthMediumToolTip')[0].value}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button variant="light" type="button">
                                                                                <i className="fas fa-info-circle pass-tooltip-div i-medium" data-tip data-for='mediumPassword' ></i>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <img src={Yellow} alt="Medium Password" width="100%" />
                                                                </div>
                                                                :
                                                                <div className='pass-strength-bar'>
                                                                    <div className='pass-tooltip-div'>
                                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthLow')[0].value}

                                                                        <OverlayTrigger
                                                                            key='lowPassword'
                                                                            id='lowPassword'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip className="low-password">
                                                                                    {labelList.filter(i => i.key === 'Validation.PasswordStrengthLowToolTip')[0].value}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button variant="light" type="button" >
                                                                                <i className="fas fa-info-circle pass-tooltip-div i-low" data-tip data-for='lowPassword' ></i>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <img src={Red} alt="Low Password" width="100%" />
                                                                </div>
                                                    }
                                                </span>
                                            }
                                            <div className="confirm-pass-div">
                                                <Field name="confirmPassword" placeholder={labelList.filter(i => i.key === 'UserDetail.ConfirmPasswordLabel')[0]?.value}
                                                    disabled={remark ? true : false} id="confirmPassword" type="password" component={FormikInput} />
                                                <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'confirmPassword')}></i>
                                            </div>
                                        </div>
                                    }
                                    <div className='t-and-c-div'>
                                        <Field name="isActive" id="acceptAgreement" type="checkbox" onChange={props.onChangeAgreementHandler} value={isAgreementAccepted} component={FormikCheckbox} />
                                        <label>{labelList.filter(i => i.key === "UserDetail.PassAccept")[0]?.value}
                                            <a href={languageCode === AppEnum.LanguageCodeCD.us ? `${AppEnum.AnchorLinks.TAndCEnglish}` :

                                                `${AppEnum.AnchorLinks.TAndCSpanish}`} target="_blank">{labelList.filter(i => i.key === "UserDetail.TermsAndCondtions")[0]?.value}</a>
                                            {labelList.filter(i => i.key === "UserDetail.TheLabel")[0]?.value}

                                            <a href={languageCode === AppEnum.LanguageCodeCD.us ? `${AppEnum.AnchorLinks.PolicyEnglish}` :
                                                `${AppEnum.AnchorLinks.PolicySpanish}`} target="_blank">{labelList.filter(i => i.key === "UserDetail.PrivacyPolicy")[0]?.value}</a>

                                            {labelList.filter(i => i.key === "UserDetail.Treatment")[0]?.value}
                                        </label>
                                    </div>
                                    <div className={(remark ? (formik.isValid && isAgreementAccepted) :
                                        (formik.isValid && formik.dirty && isAgreementAccepted && props.state.countryCode)) ? 'submit-btn-div' : 'submit-btn-div disable-btn'} >
                                        <button type='submit'> {props.isUpdateFlow ? labelList.filter(i => i.key === 'UserDetail.Update')[0]?.value
                                            : labelList.filter(i => i.key === 'UserDetail.SignUpButton')[0]?.value}</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default PersonalInfo;