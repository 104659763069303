import React, { useEffect, useState } from 'react';
import myShipmentsState from './MyShipmentsState';
import * as activeAccountService from '../../../service/ActiveAccountService';
import MyShipments from './MyShipments';
import { AppEnum } from '../../../common/AppEnum';

const MyShipmentsContainer = () => {

    const [state, setState] = useState(myShipmentsState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        let pageSize = masterApiData.filter(i => i.key === 'Validation.RidePageSize')[0]?.value
        setState((prevState) => { return { ...prevState, labelList: masterApiData, pageSize: Number(pageSize) } })
        getPrevShipmentList(state.startPageNo, pageSize);
        return () => {
            setState({});
        };
    }, [])

    // it fetches the paginated shipment list 
    const getPrevShipmentList = (startPageNo, pageSize) => {
        activeAccountService.getShipmentList(AppEnum.SortOrder.NewToOld, startPageNo, pageSize)
            .then((response) => {
                if (response) {
                    setState((prevState) => {
                        return {
                            ...prevState, shipmentList: [...state.shipmentList, ...response.data],
                            startPageNo: Number(startPageNo)
                        }
                    });
                }
            })
    }

    // it fetches the further shipments when partner reaches to the bottom of the page
    const fetchNextShipmentList = () => {
        let currentPageNo = state.startPageNo;
        currentPageNo = currentPageNo + 1;
        getPrevShipmentList(currentPageNo, state.pageSize);
    }

    return (
        <React.Fragment>
            <MyShipments
                state={state}
                fetchNextShipmentList={fetchNextShipmentList}
            />
        </React.Fragment>
    )
}

export default MyShipmentsContainer;