import React, { Fragment, useEffect, useState } from "react";
import { getLocalStorage, removeAsyncStorage, setLocalStorage } from "../../../common/CommonMethods";
import { getCategoryUId } from "../training/TrainingContainer";
import Exam from "./Exam";
import states from './ExamState';
import moment from "moment";
import * as examService from '../../../service/document/ExamService';
import { AppEnum } from "../../../common/AppEnum";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

let totalExamDuration = 0;
let startTime = "";
let endTime = "";
let date = "";
let answerUId = "";
let answeredIds = [{ questionUId: "", answerUIds: "" }];
let mcqAnswersUId = [{ questionUId: "", answerUIds: [] }];
let isAnyAnswerSubmitted = false;
let questionIndex = 0;
let question = {};

const ExamContainer = () => {

    const navigate = useNavigate();
    const [state, setState] = useState(states);

    useEffect(() => {
        let result = JSON.parse(getLocalStorage('languageApiLabels'));
        resetVariablesToInitialValue();
        setState((prevState) => { return { ...prevState, labelList: result } });
        let sessionId = Math.round((Math.pow(36, 10 + 1) - Math.random() * Math.pow(36, 10))).toString(36).slice(1);
        setLocalStorage('sessionId', sessionId);
        getExamQuestions();
        date = Date.now();
    }, [])

    // It fetches all exam questions along with its answers
    const getExamQuestions = () => {
        let categoryUId = getCategoryUId();

        examService.getExamQuestions(categoryUId)
            .then((response) => {
                if (response) {
                    var startingTime = new Date();
                    response.totalDuration = response.totalDuration * 60;
                    totalExamDuration = response.totalDuration * 1000;
                    startTime = moment.utc().format('HH:mm');
                    endTime = moment().add(response.totalDuration, 'seconds').format('HH:mm');
                    endTime = moment(endTime, "HH:mm").utc().format('HH:mm');

                    if (response.submissioStatusCD === AppEnum.SubmissionStatusCD.Incomplete && response.sessionId !== "") {
                        let examsubmittedDate = moment(response.submittedDate).format('DD/MM/YYYY');
                        endTime = response.endTime;
                        startTime = response.startTime;

                        var today = new Date();
                        var dd = String(today.getDate()).padStart(2, '0');
                        var mm = String(today.getMonth() + 1).padStart(2, '0');
                        var yyyy = today.getFullYear();

                        let dateOfToday = dd + '/' + mm + '/' + yyyy;

                        let currentTime = startingTime.getHours() + ":" + startingTime.getMinutes();
                        currentTime = moment(currentTime, "hh:mm").utc().format('HH:mm');
                        let sTime = moment(currentTime, 'HH:mm');
                        let eTime = moment(endTime, 'HH:mm');
                        totalExamDuration = eTime.diff(sTime, 'seconds');

                        if (examsubmittedDate === dateOfToday && totalExamDuration >= 0) {
                            response.questions.forEach(question => {
                                if (question.answerTypeCD === AppEnum.AnswerTypeCD.SINGLE_CHOICE) {

                                    if (question.submittedAnswer !== null) {
                                        if (question.answers.filter(i => i.value === question.submittedAnswer.answerText)[0] !== undefined) {
                                            let uId = question.answers.filter(i => i.value === question.submittedAnswer.answerText)[0].uId;
                                            answeredIds.push({
                                                questionUId: question.uId,
                                                answerUIds: uId
                                            });
                                        }
                                    }
                                }
                                if (question.answerTypeCD === AppEnum.AnswerTypeCD.MULTI_CHOICE) {
                                    if (question.submittedAnswer !== null) {
                                        let jsonAnswers = JSON.parse(question.submittedAnswer.answers);
                                        let answerUIds = [];
                                        jsonAnswers.English.forEach((answer) => {
                                            if (answer.Checked === true) {
                                                answerUIds.push(answer.AnswerUId);
                                            }
                                        })
                                        mcqAnswersUId.push({
                                            questionUId: question.uId,
                                            answerUIds: answerUIds
                                        });
                                    }
                                }
                            });
                            totalExamDuration = totalExamDuration * 1000;
                            localStorage.setItem('sessionId', response.sessionId);
                            isAnyAnswerSubmitted = true;
                        }
                        else
                            totalExamDuration = response.totalDuration * 1000;
                    }
                    let questions = response.questions.sort((item1, item2) => item1.displayOrder - item2.displayOrder)
                    question = response.questions[questionIndex]
                    setState((prevState) => { return { ...prevState, examData: response, questions: questions } });
                }
            })
    }

    // It returns the exam time countdown to countdown renderer
    const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            isAnyAnswerSubmitted = true;
            displayNextQuestion(true);
            navigate('/driver/thank-you', { state: { isAutoSubmitted: true } });
            return null;
        }
        else
            return <span>{hours}:{minutes}:{seconds}</span>;
    };

    // It displays the next question and if any answer is choosen then it will submit the answer
    const displayNextQuestion = async (isSubmit) => {
        let selectedQuestionUId = "";
        selectedQuestionUId = state.questions[questionIndex].uId;
        let displayOrder = state.questionDisplayOrder + 1;

        if (state.answerUId || state.answerUIds.length) {
            let answer = state.answer;
            answer.userTypeCD = AppEnum.UserTypeCD.Partner;
            answer.appCD = AppEnum.AppCD.appCD;
            answer.startTime = startTime;
            answer.endTime = endTime;
            answer.defaultLangageCD = (getLocalStorage("language"))?.toUpperCase();
            answer.examTitle = state.examData.title;
            answer.examUId = state.examData.uId;
            answer.sessionId = getLocalStorage("sessionId");

            if (question.answerTypeCD === AppEnum.AnswerTypeCD.SINGLE_CHOICE)
                answer.examSubmissionDetail.answerUIds.push(state.answerUId);
            if (question.answerTypeCD === AppEnum.AnswerTypeCD.MULTI_CHOICE)
                answer.examSubmissionDetail.answerUIds.push(...state.answerUIds);

            answer.examSubmissionDetail.questionUId = selectedQuestionUId !== "" ? selectedQuestionUId : question.uId;

            examService.submitAnswer(answer)
                .then((response) => {
                    if (response) {
                        answer.examSubmissionDetail.answerUIds = [];
                        isAnyAnswerSubmitted = true;
                        setState((prevState) => { return { ...prevState, answerUId: null, answerUIds: [] } });

                        if (isSubmit)
                            submitExam();
                    }
                })
        }
        if (displayOrder <= state.examData.questions.length) {
            setState((prevState) => { return { ...prevState, questionDisplayOrder: displayOrder } });
            questionIndex = questionIndex + 1;
            question = state.questions[questionIndex];
        }
    }

    // It displays the previous question
    const displayPreviousQuestion = () => {
        if (state.questionDisplayOrder > 1) {
            let displayOrder = state.questionDisplayOrder - 1;
            setState((prevState) => { return { ...prevState, questionDisplayOrder: displayOrder } });
            questionIndex = questionIndex - 1;
            question = state.questions[questionIndex];

            if (answeredIds.filter(i => i.questionUId === question.uId)[0] !== undefined)
                answerUId = answeredIds.filter(i => i.questionUId === question.uId)[0].answerUIds
        }
    }

    // It submits the answer according to the uId for the particular question
    const handleAnswerCheck = (uId) => {
        if (question.answerTypeCD === AppEnum.AnswerTypeCD.SINGLE_CHOICE) {
            let answerIndexToBeDeleted = answeredIds.findIndex(i => i.questionUId === question.uId);
            if (answerIndexToBeDeleted >= 0)
                answeredIds.splice(answerIndexToBeDeleted, 1);

            answeredIds.push({
                questionUId: question.uId,
                answerUIds: uId
            });

            if (state.answerUId !== null) {
                if (document.getElementById(state.answerUId))
                    document.getElementById(state.answerUId).checked = false;
            }
            setState((prevState) => { return { ...prevState, answerUId: uId } });

            if (state.questionDisplayOrder === state.examData.questions.length)
                isAnyAnswerSubmitted = true;
        }
        if (question.answerTypeCD === AppEnum.AnswerTypeCD.MULTI_CHOICE) {

            let answerUIds = state.answerUIds;
            let answerIndexToBeDeleted = answerUIds.findIndex(i => i === uId);
            if (answerIndexToBeDeleted >= 0) {
                answerUIds.splice(answerIndexToBeDeleted, 1);
                setState((prevState) => { return { ...prevState, answerUId: answerUIds } });
            }
            else
                setState((prevState) => { return { ...prevState, answerUId: answerUIds.push(uId) } });

            let mcqAnswerIndexToBeDeleted = mcqAnswersUId.findIndex(i => i.questionUId === question.uId);
            if (mcqAnswerIndexToBeDeleted < 0) {
                mcqAnswersUId.push({
                    questionUId: question.uId,
                    answerUIds: [uId]
                });
            }
            else {
                mcqAnswersUId.splice(mcqAnswerIndexToBeDeleted, 1);
                mcqAnswersUId.push({
                    questionUId: question.uId,
                    answerUIds: [...state.answerUIds]
                });
            }
            if (state.questionDisplayOrder === state.examData.questions.length)
                isAnyAnswerSubmitted = true;
        }
    }

    // It submits the final answer and then submits the exam
    const submitExam = async () => {
        if (isAnyAnswerSubmitted) {
            let examUId = state.examData.uId;
            let sessionId = localStorage.getItem("sessionId");
            // await displayNextQuestion();

            examService.submitExam(examUId, sessionId)
                .then((response) => {
                    if (response) {
                        resetVariablesToInitialValue();
                        removeAsyncStorage();
                        navigate('/driver/thank-you');
                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        removeAsyncStorage();
                        navigate('/driver/thank-you');
                    }
                })
        }
        else
            toast.error(state.labelList.filter(i => i.key === "Phase3.PleaseSelectAnswer")[0]?.value);
    }

    // It resets the all variables to its initial values
    const resetVariablesToInitialValue = () => {
        totalExamDuration = 0;
        startTime = "";
        question = {};
        endTime = "";
        answerUId = "";
        answeredIds = [{ questionUId: "", answerUIds: "" }];
        mcqAnswersUId = [{ questionUId: "", answerUIds: [] }];
        isAnyAnswerSubmitted = false;
        questionIndex = 0;
        setState((prevState) => { return { ...prevState, answeredIds: [] } });

    }

    return (
        <Fragment>
            <Exam
                state={state}
                countdownRenderer={countdownRenderer}
                handleAnswerCheck={handleAnswerCheck}
                displayPreviousQuestion={displayPreviousQuestion}
                displayNextQuestion={displayNextQuestion}
                // submitExam={submitExam}
                question={question}
                totalExamDuration={totalExamDuration}
                mcqAnswersUId={mcqAnswersUId}
                answeredIds={answeredIds}
                date={date}
            />
        </Fragment>
    )
}

export default ExamContainer;