import React from "react";
import { Formik, Field, Form } from 'formik';
import './ForgotPasswordModule.css';
import FormikInput from "../../shared-component/formik/FormikInput";

const ForgotPassword = (props) => {

    const { userDetail, formValidationSchema, labelList } = props.state;

    return (
        <div className='login-main-div'>
            <Formik
                initialValues={userDetail}
                validationSchema={formValidationSchema}
                onSubmit={props.onResetPassword} >
                {(formik) => (
                    <React.Fragment>
                        <div className="login-container">
                            <h2 className='pass-title'>{labelList.filter(i => i.key === 'Login.VooyPartnerTitle')[0]?.value}</h2>
                            <Form noValidate>
                                <div>
                                    <div className="form-group">
                                        <Field
                                            name="userName"
                                            id="userName"
                                            type="text"
                                            placeholder={labelList.filter(i => i.key === 'Login.EmailLabel')[0]?.value}
                                            component={FormikInput} />
                                    </div>
                                    <div >
                                        <div className={(formik.isValid && formik.dirty) ? 'login-button-div margin-b-btn' : 'login-button-div disable-btn margin-b-btn'}>
                                            <button type="submit">{labelList.filter(i => i.key === 'ForgotPassword.SendOtpButton')[0]?.value}
                                            </button>
                                        </div>
                                        <div className="login-link-div">
                                            {labelList.filter(i => i.key === 'ForgotPassword.AlreadyHaveAccount')[0]?.value}<span>&nbsp;
                                                <b onClick={props.onLogin}>
                                                    {labelList.filter(i => i.key === 'Login.LoginButton')[0]?.value} </b></span>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>)}
            </Formik>
        </div>
    );
}

export default ForgotPassword;