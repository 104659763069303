import React, { useEffect, useRef, useState } from "react";
import { AppEnum } from "../../common/AppEnum";
import personalState, { formValidationSchema } from './PersonalInfoState';
import * as  commonService from '../../service/CommonService';
import * as commonMethods from "../../common/CommonMethods";
import { useNavigate, useLocation } from "react-router-dom";
import PersonalInfo from "./PersonalInfo";
import Geocode from "react-geocode";
import * as signupService from '../../service/document/SignUpService';
import { PrivateLayout } from "../../common/PrivateLayout";
import { toast } from "react-toastify";

let countryCode = '';

const PersonalInfoContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState(personalState);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(commonMethods.getLocalStorage('languageApiLabels'));

        let token = commonMethods.getLocalStorage('token');
        if (!token)
            commonMethods.removeAsyncStorage();
        if (isComponentMounted.current) {
            getGenderList();
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });
            getActiveCountryList();
        }
        if (location.state?.personalData)
            setPreviousPersonalData(location.state.personalData);

        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // it sets the personal data to the state 
    const setPreviousPersonalData = (personalData) => {
        let personalInfo = state.personalInfo;

        personalInfo.userPhaseStatusCDUId = personalData.userPhaseStatusCDUId;
        personalInfo.userStatusCDUId = personalData.userStatusCDUId;
        personalInfo.userClassCDUId = personalData.userClassCDUId;
        personalInfo.userTypeCDUId = personalData.userTypeCDUId;

        personalInfo.country = personalData.countryUId;
        personalInfo.mobileNo = personalData.mobileNo;
        personalInfo.firstName = personalData.firstName;
        personalInfo.fatherLastName = personalData.fatherLastName;
        personalInfo.motherLastName = personalData.motherLastName;
        personalInfo.genderCD = personalData.genderCD;
        personalInfo.email = personalData.email;
        personalInfo.referredBy = personalData.referredBy;
        personalInfo.stateUId = personalData.stateUId;
        personalInfo.cityUId = personalData.cityUId;
        personalInfo.password = 123456;
        personalInfo.confirmPassword = 123456;

        getCityList(personalData.stateUId);

        if (location.state?.remark === true) {
            setState((prevState) => { return { ...prevState, isNewRegister: true } });
            personalInfo.password = '';
            personalInfo.confirmPassword = '';
            personalInfo.email = '';
        }
        setState((prevState) => {
            return {
                ...prevState, countryCode: personalData.dialCode, remark: personalData.rejectionReason,
                personalInfo: personalInfo
            }
        });
    }

    // Retrieves the country list and calls the method to get the current position
    const getActiveCountryList = () => {
        //It calls the method which extracts the current country name according to the location
        //Passes the lat long as the parameter to the method
        navigator.geolocation.getCurrentPosition(getCountryByLatLng,
            (error) => {
                // new country changes
                getActiveCountryCode("MX");
            })
    }

    // It calls the method which extracts the current country name according to the location
    // and sets the country code in state for the respective country 
    const getCountryByLatLng = (position) => {
        Geocode.setApiKey(AppEnum.GoogleApiKey.googleApiKey);
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
                response.results.every((element) => {
                    let address = element?.address_components?.filter(item => {
                        if (item?.types?.includes("country")) {
                            return item;
                        }
                    });
                    if (address?.length) {
                        countryCode = address[0]?.short_name;
                        return false;
                    }
                    else
                        return true
                });
                // new country changes
                countryCode = !countryCode ? 'MX' : countryCode;
                getActiveCountryCode(countryCode);
            },
            (error) => {
                // new country changes
                getActiveCountryCode("MX");
            }
        );
    }

    // sets the retrieved active country code
    const getActiveCountryCode = async (countryCode) => {
        let country = await commonMethods.getActiveCountryList(countryCode);

        let masterApiData = JSON.parse(commonMethods.getLocalStorage('languageApiLabels'));
        getStateList(countryCode, masterApiData);

        let mobileValidation = commonMethods.getMinMaxMobileLength(masterApiData, country[0]?.label);
        let validationSchema = formValidationSchema(masterApiData, mobileValidation.minMobileLength, mobileValidation.maxMobileLength);

        setState((prevState) => {
            return {
                ...prevState, countryList: [...country], countryCode: country[0]?.label, countryUId: country[0]?.value,
                mobileNoMaxLength: mobileValidation.maxMobileLength, formValidationSchema: validationSchema
            }
        });
    }

    // retrieves the gender list from API
    const getGenderList = () => {
        let languageCode = commonMethods.getLocalStorage('languageCode');
        commonService.getConfigByGroupId(AppEnum.ConfigCDGroupId.GENDER, languageCode)
            .then(res => {
                if (res) {
                    setState((prevState) => {
                        return {
                            ...prevState, genderList: [
                                { value: null, label: prevState.labelList.filter(i => i.key === 'UserDetail.Gender')[0]?.value },
                                ...res
                            ]
                        }
                    })
                }
            })
    }

    // It checks the strength of the password and displays it
    const onchangePasswordHandler = (password) => {
        if (password.length >= 6) {
            let passwordStrength = commonMethods.checkPasswordStrength(password);
            setState((prevState) => {
                return {
                    ...prevState, passwordStrength: passwordStrength,
                    isPasswordStrength: true
                }
            })
        }
        else
            setState((prevState) => { return { ...prevState, passwordStrength: {}, isPasswordStrength: false } })
    }
    // it checks if email exists or not then renders the password fields 
    const onGoingNext = (personalInfo) => {
        let languageCode = localStorage.getItem('languageCode');

        if (location.state?.personalData) {
            updatePersonalInfo(personalInfo, languageCode)
        }
        else {
            let checkPartnerFormData = new FormData();
            createPartnerValidateFD(personalInfo, languageCode, checkPartnerFormData);

            commonService.validatePartner(checkPartnerFormData)
                .then(res => {
                    if (res) {
                        let otpDetail = {};
                        otpDetail.linkTableCD = AppEnum.LinkTableCD.driver;
                        otpDetail.linkTableUId = '';
                        otpDetail.secureActionTypeCD = AppEnum.SecureActionTypeCD.driver;
                        otpDetail.languageCode = languageCode;
                        otpDetail.sendToMobile = true;
                        otpDetail.sendToEmail = false;
                        otpDetail.email = "";
                        otpDetail.mobileNo = state.countryCode + personalInfo.mobileNo;
                        personalInfo.countryUId = state.countryUId;
                        personalInfo.dialCode = state.countryCode;
                        personalInfo.timeZone = new Date().getTimezoneOffset();

                        commonService.sendOTP(otpDetail)
                            .then(res => {
                                if (res) {
                                    personalInfo.otpuId = res.uId;
                                    navigate('/verify-otp', { state: { personalInfo: personalInfo, otpDetail: otpDetail, } })
                                }
                            })
                    }
                })
        }
    }

    // it updates personal info
    const updatePersonalInfo = (personalInfo, languageCode) => {
        if (!state.isNewRegister) {
            delete personalInfo.password;
            delete personalInfo.confirmPassword;
            delete personalInfo.isActive;
        }
        personalInfo.timeZone = new Date().getTimezoneOffset();
        personalInfo.languageCode = languageCode;

        signupService.updatePersonalInfo(personalInfo).then((response) => {
            if (response) {
                toast.success(state.labelList.filter(i => i.key === 'Validation.ProfileInfoUpdated')[0]?.value)
                PrivateLayout();
            }
        })
    }

    // it creates form data to validate partner existence
    const createPartnerValidateFD = async (personalInfo, languageCode, checkPartnerFormData) => {
        checkPartnerFormData.append("mobileNo", state.countryCode + personalInfo.mobileNo);
        checkPartnerFormData.append("email", personalInfo.email);
        checkPartnerFormData.append("languageCode", languageCode);
        checkPartnerFormData.append("role", AppEnum.PartnerTypeCD.driver);
    }


    // checks if the partner has accepted the agreement or not
    const onChangeAgreementHandler = () => {
        setState((prevState) => { return { ...prevState, isAgreementAccepted: !prevState.isAgreementAccepted } })
    }

    // it fetches the state list then city list according to the state UId
    const onChangeStateHandler = (stateUId) => {
        let { personalInfo } = state;
        personalInfo.cityUId = '';
        setState((prevState) => { return { ...prevState, personalInfo: personalInfo } })
        getCityList(stateUId);
    }

    //It fetches the city list according to the country uId
    const getCityList = (uid) => {
        signupService.getCityDDO(uid).then(res => {
            if (res) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        cityList: [{ value: '', label: prevState.labelList.filter(i => i.key === 'UserDetail.City')[0]?.value }, ...res],
                    }
                })
            }
        })
    }

    // It fetches the state list according to the country uId
    const getStateList = (countryCode, masterApiData) => {
        signupService.getStateDDO(countryCode)
            .then(res => {
                if (res) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            stateList: [{ value: '', label: masterApiData.filter(i => i.key === 'UserDetail.State')[0]?.value }, ...res],
                        }
                    })
                }
            })
    }

    return (
        <React.Fragment>
            <PersonalInfo
                state={state}
                onGoingNext={onGoingNext}
                onChangeAgreementHandler={onChangeAgreementHandler}
                onchangePasswordHandler={onchangePasswordHandler}
                onChangeStateHandler={onChangeStateHandler}
                isUpdateFlow={location.state?.personalData ? true : false}
            />
        </React.Fragment>
    )
}

export default PersonalInfoContainer;