import { toast } from 'react-toastify';
import { getLocalStorage, getNewToken, removeLocalStorage } from '../common/CommonMethods';

// it displays the error message by toaster thrown by api 
export async function showApiErrorMessage(error, axiosInstance) {

    let result = JSON.parse(getLocalStorage('languageApiLabels'));

    if (!error.response || error.code === "ERR_NETWORK" || !navigator.onLine) {
        if (result)
            toast.error(result.filter(i => i.key === 'StatusCodeErrors.NoInternetOrBadGateway')[0]?.value);
    }
    else {
        // Internal server error
        if (error.response.status === 500) {
            if (error.response?.data) {
                if (error.response.data?.message)
                    toast.error(error.response.data.message);
                else
                    toast.error(error.response.data);
            }
            else {
                if (result)
                    toast.error(result.filter(i => i.key === 'StatusCodeErrors.SomeThingWentWrong')[0]?.value);
            }
        }
        else if (error.response.status === 405) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.MethodNotAllowed')[0]?.value);
        }
        else if (error.response.status === 408) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.RequestTimeout')[0]?.value);
        }
        else if (error.response.status === 415) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.UnsupportedMediaType')[0]?.value);
        }
        else if (error.response.status === 502) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.BadGateway')[0]?.value);
        }
        else if (error.response.status === 503) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.ServiceUnavailable')[0]?.value);
        }
        else if (error.response.status === 504) {
            if (result)
                toast.error(result.filter(i => i.key === 'StatusCodeErrors.GatewayTimeout')[0]?.value);
        }
        else {
            // bad request
            if (error.response.status === 400) {
                if (error.response.data.validationErrors !== undefined ? error.response.data.validationErrors.length !== 0 : false)
                    toast.error(error.response.data.validationErrors[0]?.errorMessage);

                else if (error.response.data?.smsSendingError)
                    toast.error(error.response.data.smsSendingError);

                else if (error.response.data?.errors) {
                    if (error.response.data.errors?.MobileNo)
                        toast.error(error.response.data.errors?.MobileNo[0]);
                    else
                        toast.error(error.response.data.errors?.title);
                }
                else if (error.response.data?.error_description)
                    toast.error(error.response.data.error_description);
            }
            else {
                // Not found
                if (error.response.status === 404) {
                    if (error.response.statusText)
                        toast.error(error.response.statusText);
                    else
                        if (result)
                            toast.error(result.filter(i => i.key === 'StatusCodeErrors.MethodNotFound')[0]?.value);
                }
                else {
                    // Not found
                    if (error.response.status === 401) {

                        let isTokenRefreshed = getLocalStorage('isTokenRefreshed');
                        if (!isTokenRefreshed) {

                            let newToken = null;
                            newToken = await getNewToken();

                            if (newToken) {
                                removeLocalStorage('isTokenRefreshed');
                                return axiosInstance(error.config);
                            }
                        }
                    }
                    else if (error?.response?.data)
                        toast.error(error.response.data?.error_description);
                    else
                        toast.error(error?.response?.statusText);
                }
            }
        }
    }

    document.body.classList.remove('loading-indicator');
}