import React, { Fragment } from "react";
import './Training.css';
import ReactPlayer from 'react-player/lazy';

const Training = (props) => {

    const { selectedQuestionUId, trainingList, trainingContent, selectedQuestionIndex, labelList } = props.state;

    return (
        <Fragment>
            <div>
                <div className="body-div">
                    <div className="course-content-list">
                        {
                            trainingList?.map((training, index) => {
                                return (
                                    <label id={index} key={index} className={selectedQuestionUId === training.uId ? "label-selected" : ""}
                                        onClick={() => props.getTrainingContent(training.uId, index)}>{training?.title}</label>
                                );
                            })
                        }
                    </div>
                    <div className='player-wrapper'>
                        <div className="video-title-div">
                            <label>{selectedQuestionUId !== 0 && trainingList?.filter(i => i.uId === selectedQuestionUId)[0]?.title}</label>
                            <hr />
                        </div>

                        <ReactPlayer
                            className='react-player'
                            url={trainingContent.url}
                            controls={true}
                        />
                        <div className="video-title-div">
                            <div dangerouslySetInnerHTML={{ __html: trainingContent.languageText }} />
                            {/* <p>{trainingContent.languageText}</p> */}
                        </div>

                        <div className={selectedQuestionIndex === 0 ? "next-button-div" : "pre-next-button-div"}>
                            {
                                selectedQuestionIndex !== 0 &&
                                <button className="previous-button" onClick={() => props.playPreviousVideo()}>
                                    {labelList.filter(i => i.key === "Phase3.PreviousButton")[0]?.value}
                                </button>
                            }
                            {
                                selectedQuestionIndex === (trainingList?.length - 1) ?
                                    <button onClick={() => props.startExam()}>
                                        {labelList.filter(i => i.key === "Phase3.StartExamButton")[0]?.value}
                                    </button>
                                    : <button onClick={() => props.playNextVideo()}>
                                        {labelList.filter(i => i.key === "Phase3.NextButton")[0]?.value}
                                    </button>
                            }
                        </div>
                    </div>
                    {
                        selectedQuestionIndex !== 0 &&
                        <div className="exit-button-div">
                            <button onClick={() => props.goToStart()}><i className="fas fa-arrow-left"></i>
                                {labelList.filter(i => i.key === "Phase3.ExitButton")[0]?.value}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default Training;