const LanguageCodeCD = () => {
    return {
        mexican: "es-MX",
        us: "en-US"
    }
}
const LanguageApiUserInterfaceCD = () => {
    return {
        Partner_Registration_Web: "Partner_Registration_Web"
    }
}
const LanguageListCD = () => {
    return {
        English: 'English',
        Spanish: 'Spanish',
    }
}
const LoginCD = () => {
    return {
        driver: "DELIVERY_PARTNER_LOGIN",
        EMAIL_MOBILE_CHANGE: 'EMAIL_MOBILE_CHANGE',
        createNewAccount: 'CREATE_NEW_ACCOUNT'
    }
}
const PartnerTypeCD = () => {
    return {
        driver: "driver",
        customer: "customer",
        seller: "seller",
        doctor: "doctor",
        nurse: "nurse",
        ambulanceDriver: "ambulanceDriver"
    }
}
const LinkTableCD = () => {
    return {
        driver: "DLP",
    }
}
const GoogleApiKey = () => {
    return {
        googleApiKey: "AIzaSyA6pyIkJXN3Bq-zxTZvd4sEFyEjj2bnCFI",
    }
}
const SecureActionTypeCD = () => {
    return {
        driver: "DELIVERY_PARTNER_REGISTRATION",
        forgotPassword: "FORGOT_PASSWORD"
    }
}
const AnchorLinks = () => {
    return {
        SatTaxLink: 'https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal.',
        SatRfcLink: 'https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp',
        TAndCEnglish: "https://vooyapp.com.mx/terms-and-conditions/",
        TAndCSpanish: "https://vooyapp.com.mx/terminos-y-condiciones/",
        PolicyEnglish: "https://vooyapp.com.mx/privacy-policies/",
        PolicySpanish: "https://vooyapp.com.mx/politicas-de-privacidad/",
        GenerationAgreement: 'https://vooyapp.com.mx/acuerdo-para-la-generacion-y-envio-de-comprobantes-fiscales-digitales-a-traves-de-internet/',
        SatGovt: "https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal",
    }
};
const ConfigCDGroupId = () => {
    return {
        USER_TYPE: 1,
        ATTACHMENT_TYPE: 2,
        VEHICLE_TYPE: 3,
        SHIPMENT_TYPE: 4,
        BANK_TYPE: 5,
        USER_STATUS: 6,
        SHIPMENT_STATUS: 7,
        USER_CLASS: 8,
        BUSINESS_TYPE: 9,
        VEHICLE_STATUS: 10,
        BANK_STATUS: 11,
        DELIVERY_MODE: 12,
        CHAT_TYPE: 13,
        GENDER: 18,
        BIKE_FUEL_TYPE: 19,
        VEHICLE_COLOR: 23,
        VEHICLE_USE: 30,
        // GenderCD: 'GenderCD',
        // VEHICLE_COLOR_CD: 'VEHICLE_COLOR'
    }
}
const PersonalDocumentCode = () => {
    return {
        SsnNumber: "SSN_NUMBER",
        IdentityDocument: "IDENTITY_DOCUMENT",
        ProofOfAddress: "PROOF_OF_ADDRESS",
        DL: "DRIVING_LICENSE",
        DlLite: "DRIVING_LICENSE_LITE",
        BirthCertificate: "BIRTH_CERTIFICATE",
        ProfilePicture: 'PROFILE_PIC',
        MobilityInstitute: 'MOBILITY_INSTITUTE_APPROVAL',
        Icet: 'ICET_KNOWLEDGE_TEST',
        TaxDocument: "TAX_DOCUMENT",
        Toxilogical: 'TOXICOLOGICAL_ANALYSIS',
        PsychometricTest: "PSYCHOMETRIC_TEST",
        TaxInformation: 'TAX_INFORMATION'
    }
}
const VehicleTypes = () => {
    return {
        Bicycle: "BICYCLE",
        Motorcycle: "MOTORCYCLE",
        Car: "CAR",
        PickUpVan: "PICKUPANDVAN"
    }
}
const ServiceTypeId = () => {
    return {
        Ride: 2,
        Delivery: 1,
        FoodDelivery: 4,
    }
}
const FormDataMobileCD = () => {
    return {
        client_secret: "secret",
        grant_type: "sms_otp",
        client_id: "driver_ui",
    }
}
const FormDataEmailCD = () => {
    return {
        client_secret: "secret",
        grant_type: "password",
        client_id: "driver_ui",
    }
}
const SortOrder = () => {
    return {
        LowToHighEarning: 1,
        HighToLowEarning: 2,
        LowToHighRating: 3,
        HighToLowRating: 4,
        NewToOld: 5,
        OldToNew: 6,
    }
}
const CurrencyCode = () => {
    return {
        Indian: "INR",
        Mexican: "MXN"
    }
}
const TrainingCategoryUId = () => {
    return {
        CAR_VAN: '8EFAC710D8',
        BIKE_BICYCLE: 'ADDF5AE5D8'
    }
};
const VehicleTypeId = () => {
    return {
        Bycycle: 1,
        Motorcycle: 2,
        Car: 3,
        PickUpVan: 4
    }
}
const SubmissionStatusCD = () => {
    return {
        Passed: "PASSED",
        Failed: "FAILED",
        Incomplete: "INCOMPLETE"
    }
}
const AnswerTypeCD = () => {
    return {
        MULTI_CHOICE: 'MULTI_CHOICE',
        SINGLE_CHOICE: 'SINGLE_CHOICE'
    }
};
const UserTypeCD = () => {
    return {
        Individual: 1,
        Businesses: 2,
        Partner: "DELIVERY_PARTNER"
    }
}
const IsNewRegister = () => {
    return {
        Yes: 'YES',
        No: 'NO',
    }
}
const VehicleDocumentCode = () => {
    return {
        VehicleImage: "VEHICLE_IMAGE",
        VehicleLicense: "VEHICLE_LICENSE",
        VehicleInsurance: "VEHICLE_INSURANCE",
        CirculationCard: "CIRCULATION_CARD",
    }
}
const IdentityStatusCD = () => {
    return {
        ReviewInProcess: 281,
        Approved: 282,
        Rejected: 283,
    }
}
const AttachmentTypeCD = () => {
    return {
        Personal: 21,
        Vehicle: 22,
        Shipment: 23,
        ProfilePic: 24,
        CompletedShipmentItem: 25,
        VehicleMobility: 26,
        AddressProof: 27,
        phase2Documents: 28
    }
}
const UserProfileStatusCD = () => {
    return {
        Registered: 101,
        ReviewInProcess: 102,
        Approved: 103,
        Rejected: 104,
        Blocked: 105,
        Unsubscribe: 106,
        Deleted: 107,
        DeletedPermanently: 108,
        RegistrationInProcess: 109,
        Expired: 110
    }
}
const AppCD = () => {
    return {
        appCD: "VOOY_RIDE_DELIVERY_PARTNER",
    }
}
const TaxInfoCode = () => {
    return {
        TaxRegime: "TAX_REGIME",
        taxDoc: "TAX_DOCUMENT",
        Fiel: "ADVANCE_ELECTRONIC_CERTIFICATE",
        FielKeyTitle: "ADVANCE_ELECTRONIC_KEY",
        FielPassword: "ADVANCE_ELECTRONIC_PASSWORD",
        CsdCertificate: "CSD_CERTIFICATE",
        CsdPassword: "CSD_PASSWORD",
        CsdKeyTitle: "CSD_KEY",
    }
};
const Phase2DocumentCode = () => {
    return {
        Toxilogical: "TOXICOLOGICAL_ANALYSIS",
        ICET: "ICET_KNOWLEDGE_TEST",
        MobilityInstitute: "MOBILITY_INSTITUTE_APPROVAL",
        BirthCertificate: "BIRTH_CERTIFICATE",
        TaxId: "TAX_ID_CARD",
        PsychometricTest: "PSYCHOMETRIC_TEST"
    }
}

const VehicleStatusCD = () => {
    return {
        ReviewInProcess: 181,
        Approved: 182,
        Rejected: 183,
        Blocked: 184,
        Enable: 185,
        Disable: 186,
        Deleted: 187,
        Expired: 188
    }
}

// it exports all the enums 
export const AppEnum = (function enums() {
    return {
        LanguageCodeCD: LanguageCodeCD(),
        LanguageApiUserInterfaceCD: LanguageApiUserInterfaceCD(),
        LanguageListCD: LanguageListCD(),
        LoginCD: LoginCD(),
        PartnerTypeCD: PartnerTypeCD(),
        LinkTableCD: LinkTableCD(),
        GoogleApiKey: GoogleApiKey(),
        SecureActionTypeCD: SecureActionTypeCD(),
        AnchorLinks: AnchorLinks(),
        ConfigCDGroupId: ConfigCDGroupId(),
        PersonalDocumentCode: PersonalDocumentCode(),
        VehicleTypes: VehicleTypes(),
        VehicleTypeId: VehicleTypeId(),
        ServiceTypeId: ServiceTypeId(),
        FormDataMobileCD: FormDataMobileCD(),
        FormDataEmailCD: FormDataEmailCD(),
        SortOrder: SortOrder(),
        CurrencyCode: CurrencyCode(),
        TrainingCategoryUId: TrainingCategoryUId(),
        SubmissionStatusCD: SubmissionStatusCD(),
        AnswerTypeCD: AnswerTypeCD(),
        UserTypeCD: UserTypeCD(),
        IsNewRegister: IsNewRegister(),
        VehicleDocumentCode: VehicleDocumentCode(),
        IdentityStatusCD: IdentityStatusCD(),
        AttachmentTypeCD: AttachmentTypeCD(),
        UserProfileStatusCD: UserProfileStatusCD(),
        AppCD: AppCD(),
        Phase2DocumentCode: Phase2DocumentCode(),
        TaxInfoCode: TaxInfoCode(),
        VehicleStatusCD:VehicleStatusCD()
    }
})()