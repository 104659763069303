// States
const myShipmentsState = (function states() {
    return {
        labelList: [],
        shipmentList: [],
        startPageNo: 1,
        pageSize: null,
    }
})()

export default myShipmentsState;